import React, { useEffect, useState } from 'react';
import '../styles/Clearance.scss';
import View from '../components/View';
import PageHeader from '../components/PageHeader';
import SearchBarFilter from '../components/SearchBarFilter';
import CatalogueItem from '../components/CatalogueItem';

import {
  didFetchingProductsFailed,
  getProducts,
  isFetchingProducts,
} from '../selectors/Product';
import { useSelector, useDispatch } from 'react-redux';
import { fetchProductTypes } from '../actions/ProductType';
import { fetchProducts, fetchProductsResponse } from '../actions/Product';
import { getProductTypes } from '../selectors/ProductType';
import { getCategoryBannerImage, getCustomerID, objectToQueryString, useQuery } from '../helpers/functions';
import _, { debounce } from 'lodash';
import DropDown from '../components/DropDown';
import { Product } from '../types/Product';
import history from '../helpers/history';
import c from '../helpers/constants';
import { getCustomer } from '../selectors/Customer';
import { config } from '../config/config';
import { GrDown, GrUp } from 'react-icons/gr';
import { fetchColourGroup } from '../actions/ColourGroups';
import { getAvailableColourGroups } from '../selectors/ColourGroup';
import { MdClose } from 'react-icons/md';
import Products from './Products';


const Clearance = () => {

  const [toggleFilters, setToggleFilter] = useState(false);
  const [toggleCategories, setToggleCategories] = useState(true);
  const [toggleColours, setToggleColours] = useState(true);
  const [toggleWidths, setToggleWidths] = useState(true);
  const [toggleProductNames, setToggleProductNames] = useState(true);
  const [toggleGroups, setToggleGroups] = useState(true);
  const [toggleClearance, setToggleClearance] = useState(true);

  const customer = useSelector(getCustomer);
  const fetchingProducts = useSelector(isFetchingProducts);
  const products = [...useSelector(getProducts)];
  const colourGroups = useSelector(getAvailableColourGroups);



  const productTypes = useSelector(getProductTypes);
  const [title, setTitle] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageIndex, setPageIndex] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showFirst, setShowFirst] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showPrevious, setShowPrevious] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showNext, setShowNext] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showLast, setShowLast] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageCount, setPageCount] = useState(0);
  const [pageRange, setPageRange] = useState<number[]>([]);
  const [productsPerPage, setProductsPerPage] = useState(9);
  const fetchingFailed = useSelector(didFetchingProductsFailed);
  const query = useQuery();
  const dispatch = useDispatch();

  const [productTypeCode, setProductTypeCode] = useState<any>(null);
  const [searchSelection, setSearchSelection] = useState<any>(null);

  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [selectedColours, setSelectedColours] = useState<string[]>([]);

  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [selectedWidths, setSelectedWidths] = useState<string[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const [selectedCS, setSelectedCS] = useState<string[]>([]);
  const [selectedPrices, setSelectedPrices] = useState<string[]>([]);

  const widthKeys = ['WIDTH1', 'WIDTH2', 'WIDTH3', 'WIDTH4'];

  const priceRanges = [
    {
      'text': '£0 - £2', 'range': [0, 2],
    },
    {
      'text': '£2 - £4', 'range': [2, 4],
    },
    {
      'text': '£4 - £6', 'range': [4, 6],
    },
    {
      'text': '£6 +', 'range': [6, 9999],
    }
  ];


  const filterProductSet = () => {
    let colours = [...selectedColours];
    let types = [...selectedTypes];
    let widths = [...selectedWidths];
    let qualities = [...selectedProducts];
    let groups = [...selectedGroups];
    let priceMin = 0;
    let priceMax = 0;

    if (selectedPrices.length > 0) {
      let pm: null | number = null;
      let pmax: null | number = null;
      priceRanges.forEach(x => {

        if( selectedPrices.indexOf( x.text) === -1){
          return;
        }

        if (pm == null || x.range[0] < pm) {
          pm = x.range[0];
        }
        if (pmax == null || x.range[1] > pmax) {
          pmax = x.range[1];
        }
      });

      priceMin = pm ? pm : 0;
      priceMax = pmax ? pmax : 0;
    }


    let p = products.filter((x: any) => {

      if (undefined == x) {
        return false;
      }


      //Price filtering 
      if (priceMin > 0 && x.price_from < priceMin) {
        return false;
      }

      if (priceMax > 0 && x.price_from > priceMax) {
        return false;
      }



      //Filter Widths 
      if (widths.length > 0) {
        let tmpWidths: any = [];
        //Build an array of the available widths
        for (let i = 0; i < widthKeys.length; i++) {
          if (x[widthKeys[i]] > 0) {
            tmpWidths.push(x[widthKeys[i]]);
          }
        }

        let matchedWidths = tmpWidths == 0 ? [] : widths.filter(w => tmpWidths.indexOf(w) > -1);

        if (null == matchedWidths || matchedWidths.length == 0) {
          return false;
        }
      }

      if (searchSelection && searchSelection != '') {
        if (x.colour_full_name.toLowerCase().indexOf(searchSelection) === -1 && x.quality_full_name.toLowerCase().indexOf(searchSelection) === -1) {
          return false;
        }
      }


      //Filter Qualities
      if (groups.length > 0 && groups.indexOf(x.product_group) === -1) {
        return false;
      }

      //Filter Qualities
      if (qualities.length > 0 && qualities.indexOf(x.quality_full_name) === -1) {
        return false;
      }

      //In that colour 
      if (colours.length > 0 && colours.indexOf(x.colour) === -1) {
        return false;
      }

      //Filter Types
      if (types.length > 0 && types.indexOf(x.product_type_code) === -1) {
        return false;
      }
      return true;
    });

    return p;
  };

  let filteredProducts = filterProductSet();


  const doPageSetup = () => {
    if (filteredProducts?.length > 0) {
      if (searchSelection) {
        setTitle(`We found ${filteredProducts?.length} products for: ${searchSelection}`);
      }
      let localPageCount = filteredProducts?.length / productsPerPage;
      if (localPageCount > 5) {
        localPageCount = 5;
      }
      setPageIndex(0);
      setPageCount(localPageCount);
      // @ts-ignore
      setPageRange(_.range(0, localPageCount));
    }
    else {
      setPageIndex(0);
      setPageCount(0);
      setPageRange(_.range(0, 0));
    }
  };

  useEffect(() => {
    if (null == productTypeCode) {
      setProductTypeCode(query.get('productTypeCode') || '');
    }
    if (null == searchSelection) {
      setSearchSelection(query.get('searchSelection') || '');
    }
    if (null == colourGroups) {
      dispatch(fetchColourGroup(getCustomerID() || ""));
    }

    doPageSetup();

  }, []);

  useEffect(() => {
    doPageSetup();
  }, [products.length]);

  useEffect(() => {
    setTitle('');
    dispatch(fetchProductTypes());
    setSelectedCS(['C']);
    setShowFirst(false);
    setShowPrevious(false);
  }, [dispatch, productTypeCode, searchSelection]);

  useEffect(() => {
    if (selectedCS.length == 0) {
      dispatch(fetchProductsResponse([]));
      return;
    }
    dispatch(fetchProducts(productTypeCode, searchSelection, undefined, selectedCS.join(',')));
    setShowFirst(false);
    setShowPrevious(false);
  }, [selectedCS])



  useEffect(() => {
    if (productTypes?.length > 0 && productTypeCode) {
      const found = productTypes?.find(p => p.product_type_code === productTypeCode);
      setTitle(_.startCase(_.toLower(found?.product_type_desc)));
    }
  }, [productTypes]);


  useEffect(() => {
    doPageSetup();
  }, [searchSelection, productTypeCode, selectedTypes, selectedColours, selectedProducts, selectedWidths, selectedGroups]);






  const applyPageRangeLogic = (currIdx: number) => {
    //Total pages
    let localPageCount = Math.ceil(filteredProducts?.length / productsPerPage);

    //Show 5 pages or less if there are fewer
    let pagesToShow: number = localPageCount < 5 ? localPageCount : 5;

    //If there are fewer resolve it now and show all
    let start = 0;
    let end = pagesToShow;
    if (localPageCount > 5) {
      //Get a sliding window of x items
      start = (currIdx - (Math.floor(pagesToShow / 2)));
      start = start < 0 ? 0 : start;
      end = start + pagesToShow;


      if (end > localPageCount) {
        start = start > 0 ? start - 1 : start;
        end = localPageCount;
      }
    }
    setPageRange(_.range(start, end));
  };

  const onClickIndex = (goToIndex: number) => {

    // const first = _.first(pageRange) || 0;
    let localPageCount = Math.ceil(filteredProducts?.length / productsPerPage);
    applyPageRangeLogic(goToIndex - 1);
    setPageIndex(goToIndex);
    setShowPrevious(goToIndex > 0);
    setShowFirst(goToIndex > 0);
    setShowNext(goToIndex + 1 < localPageCount);
    setShowLast(goToIndex + 1 < localPageCount);
  };

  const onNext = () => {
    if (!showNext) return;
    let currIdx = pageIndex + 1;
    let localPageCount = Math.ceil(filteredProducts?.length / productsPerPage);

    applyPageRangeLogic(currIdx);
    setShowPrevious(true);
    setShowFirst(true);
    setShowNext(currIdx + 1 < localPageCount);
    setShowLast(currIdx + 1 < localPageCount);
    setPageIndex(pageIndex + 1);
  };


  const onPrevious = async () => {
    if (!showPrevious) return;
    let currIdx = pageIndex - 1;

    let localPageCount = Math.ceil(filteredProducts?.length / productsPerPage);
    applyPageRangeLogic(currIdx);

    setShowFirst(pageIndex > 1);
    setShowPrevious(pageIndex > 1);
    setShowNext(pageIndex < (localPageCount - 1));
    setShowLast(pageIndex < (localPageCount - 1));
    setPageIndex(pageIndex - 1);
  };

  const onFirst = () => {
    if (!showFirst) return;
    applyPageRangeLogic(0);
    setPageIndex(0);
    setShowPrevious(false);
    setShowFirst(false);
    setShowNext(true);
    setShowLast(true);
  };

  const onLast = () => {
    if (!showLast) return;

    let localPageCount = Math.floor(filteredProducts?.length / productsPerPage);

    applyPageRangeLogic(localPageCount);
    setPageIndex(localPageCount);
    setShowPrevious(true);
    setShowFirst(true);
    setShowNext(false);
    setShowLast(false);
  };


  const sizes = [
    { label: '9 per page', value: 9 },
    { label: '18 per page', value: 18 },
    { label: '27 per page', value: 27 },
  ];

  const setCheckboxSelection = (code: string, inArray: any, callback: any) => {
    let theTypes = [...inArray];
    let idx = theTypes.indexOf(code);

    if (idx < 0) {
      theTypes.push(code);
    } else {
      theTypes.splice(idx, 1);
    }
    callback(theTypes);
  };



  const isChecked = (code: string, type: string) => {

    if (type == 'cs') {
      return selectedCS.indexOf(code) > -1;
    }
    if (type == 'colour') {
      return selectedColours.indexOf(code) > -1;
    }
    if (type == 'quality') {
      return selectedProducts.indexOf(code) > -1;
    }
    if (type == 'width') {
      return selectedWidths.indexOf(code) > -1;
    }
    if (type == 'groups') {
      return selectedGroups.indexOf(code) > -1;
    }
    return selectedTypes.indexOf(code) > -1;
  }


  const coretecCodes = config.coretecCodes;

  //Show the please call message 
  const showPleaseCall = (product: any) => {
    let show = false;

    if (!customer?.is_matting) {
      return false;
    }

    for (let i = 0; i < coretecCodes.length; i++) {
      if (product.product_sku.substring(0, coretecCodes[i].length) == coretecCodes[i]) {
        return true;
      }
    }
    return show;
  };

  const onChangePageSize = (size: any) => {
    setProductsPerPage(size.value);
    onClickIndex(0);
  };

  const onClickProduct = (product: Product) => {
    history.push({ pathname: c.APP_ROUTES.PRODUCT_CUT, search: `sku=${product.product_sku || product.IMAGE_code}&c=${product.clearance}` });
  };

  const onSearch = debounce((term: string) => {
    const obj = {
      searchSelection: term,
      productTypeCode,
    };
    setProductTypeCode(productTypeCode);
    setSearchSelection(term);
    if (term == '') {
      setSearchSelection("");
    }
  }, 50);

  const onChange = (term: string) => {
    const obj = {
      searchSelection: term,
      productTypeCode,
    };
    setProductTypeCode(productTypeCode);
    setSearchSelection(term);
    if (term == '') {
      setSearchSelection("");
    }
  };

  const clearPriceFilter = () => {
    setSelectedPrices([]);
    setShowPrevious(false);
    setShowFirst(false);
    setPageIndex(0);
  };

  const togglePriceFilter = (filter: any) => {
    let prices = [...selectedPrices];
    let idx = prices.indexOf(filter.text);
    if (idx > -1) {
      prices.splice(idx, 1);
    } else {
      prices.push(filter.text);
    }
    setSelectedPrices(prices);
    setShowPrevious(false);
    setShowFirst(false);
    setPageIndex(0);
  };


  const goTo = (product: Product) => {
    history.push({ pathname: c.APP_ROUTES.PRODUCT_CUT, search: `sku=${product.product_sku || product.IMAGE_code}` });
  };

  let availableTypes: any[] = [];
  let availableProducts: any[] = [];
  let availableWidths: any[] = [];
  let availableKeys: any = {};
  let availableColourKeys: any = {};
  let availableColours: any[] = [];
  let availableGroups: any[] = [];



  //Loop over the product set and only pull in catgories relevant to the search
  products.map((x: any) => {
    if (undefined == x) {
      return;
    }



    if (!availableKeys.hasOwnProperty(x.product_type_code)) {
      availableKeys[x.product_type_code] = true;
      let p = productTypes.find(y => y.product_type_code == x.product_type_code);
      if (p != undefined) {
        availableTypes.push(p);
      }
    }

    if (!availableColourKeys.hasOwnProperty(x.colour)) {
      availableColourKeys[x.colour] = true;
      if (colourGroups) {
        let p = colourGroups.find(y => y.code == x.colour);
        if (p?.name != '' && p != undefined) {
          availableColours.push(p);
        }
      }
    }
    //Add the available 
    if (availableProducts.indexOf(x.quality_full_name) === -1) {
      availableProducts.push(x.quality_full_name);
    }

    //Add the available groups
    if (availableGroups.indexOf(x.product_group) === -1) {
      availableGroups.push(x.product_group);
    }


    //Add the available widths 
    widthKeys.forEach((y: string) => {
      if (availableWidths.indexOf(x[y]) === -1 && x[y] > 0) {
        availableWidths.push(x[y]);
      }
    });

  });

  availableColours.sort((a, b) => {
    return a.name?.localeCompare(b?.name);
  });

  availableProducts.sort();
  availableGroups.sort();
  availableWidths.sort();

  availableTypes.sort((a, b) => {
    return a?.product_type_desc.localeCompare(b?.product_type_desc);
  });


  const changeCS = (inVal: string) => {

    let selectedItems = [...selectedCS];

    let idx = selectedItems.findIndex(x => x == inVal);

    if (idx > -1) {
      selectedItems.splice(idx, 1);
      setSelectedCS(selectedItems);
      return;
    }

    selectedItems.push(inVal);
    setSelectedCS(selectedItems);
  };


  const renderPaging = () => filteredProducts?.length > 0 && <div className="row gy-5 search-row pb-5">
    <div className="col-md-4">
      <div className="d-flex justify-content-start align-content-center flex-row align-items-center">
        <div>Results:&nbsp;&nbsp;&nbsp;</div>
        <DropDown items={sizes} value={productsPerPage} onSelect={onChangePageSize} />
      </div>
    </div>
    <div className="col-md-8">
      <div className="lw-pagination">
        <div className={`lw-pagination__control ${!showFirst ? 'disabled' : ''}`} onClick={onFirst}> First </div>
        <div className={`lw-pagination__control ${!showPrevious ? 'disabled' : ''}`} onClick={onPrevious}> Previous </div>
        {pageRange.map((index) => (
          <div
            onClick={() => onClickIndex(index)}
            className={`lw-pagination__pill ${pageIndex === index ? 'active-pill' : ''}`}
            key={index}>
            {index + 1}
          </div>
        ))}
        <div className={`lw-pagination__control ${!showNext ? 'disabled' : ''}`} onClick={onNext}> Next </div>
        <div className={`lw-pagination__control ${!showLast ? 'disabled' : ''}`} onClick={onLast}> Last </div>
      </div>
    </div>
  </div>;





  return (
    <View>

      <PageHeader text={'Clearance and Specials'} imageUrl={getCategoryBannerImage('SP')} />


      <div className="container pb-5 clearance-outer">

        <div className='row mt-4'>
          <div className='col-md-3 '>


            <div className="search page-search c-hide-desktop">

              <SearchBarFilter results={products} onNavigate={goTo} onChange={onChange} onSearch={onSearch} term={searchSelection} styleName="search-page" />

            </div>


            <div className='whole-filter-collapse'>
              <h4 className='mb-3' onClick={() => setToggleFilter(!toggleFilters)}>
                <span>Filters</span>
                <span className='filter-caret'>
                  {!toggleFilters && <GrDown className="product-card-ex__caret" />}
                  {toggleFilters && <GrUp className="product-card-ex__caret" />}
                </span>
              </h4>


              <div className='price-filters'>
                <div className='filter-collapse-heading'>
                  <span>Price Filter</span>
                </div>
                <div className='price-filters-content d-flex justify-content-start wrap'>

                  <span className={'price-filters__pill d-flex justify-content-between align-items-center all-prices' + (selectedPrices.length == 0 ? ' active' : '')} onClick={() => clearPriceFilter()}>
                    <span>All Prices</span>
                  </span>

                  {priceRanges && priceRanges.map((x) => (
                    <>
                      <span className={'price-filters__pill d-flex justify-content-between align-items-center' + (selectedPrices.indexOf(x.text) > -1 ? ' active' : '')} onClick={() => togglePriceFilter(x)}>
                        <span>{x.text}</span>
                        <span className='fa'>
                          <MdClose style={{ color: '#22643C', fontSize: '16px' }} className="price-filter__remove" />
                        </span>
                      </span>
                    </>
                  ))}
                </div>
              </div>

              <div className={'filter-collapse ' + (toggleFilters ? 'active-filters' : '')}>
                <div className='filter-collapse-heading'>
                  <div onClick={() => setToggleClearance(!toggleClearance)}>
                    <span>Clearance/Specials</span>
                    <span className='child-filter-caret'>
                      {!toggleCategories && <GrDown className="product-card-ex__caret" />}
                      {toggleCategories && <GrUp className="product-card-ex__caret" />}
                    </span>
                  </div>
                </div>
                <div className={'filter-collapse-body' + (toggleClearance ? ' toggle-active' : '')}>

                  <div className='filter-collapse-row'>
                    <span className='mr-2'><input type='checkbox'
                      onChange={() => {
                        changeCS('C');
                      }}
                      checked={isChecked('C', 'cs')}
                    /></span><span className='sentence-case'>Clearance</span>
                  </div>

                  <div className='filter-collapse-row'>
                    <span className='mr-2'><input type='checkbox'
                      onChange={() => {
                        changeCS('S');
                      }}
                      checked={isChecked('S', 'cs')}
                    /></span><span className='sentence-case'>Specials</span>
                  </div>

                </div>
              </div>

              <div className={'filter-collapse ' + (toggleFilters ? 'active-filters' : '')}>
                <div className='filter-collapse-heading'>
                  <div onClick={() => setToggleCategories(!toggleCategories)}>
                    <span>Categories</span>
                    <span className='child-filter-caret'>
                      {!toggleCategories && <GrDown className="product-card-ex__caret" />}
                      {toggleCategories && <GrUp className="product-card-ex__caret" />}
                    </span>
                  </div>
                </div>
                <div className={'filter-collapse-body' + (toggleCategories ? ' toggle-active' : '')}>
                  {availableTypes && availableTypes.map((type: any, i: any) => (
                    <div className='filter-collapse-row'>
                      <span className='mr-2'><input type='checkbox'
                        onChange={() => {
                          setCheckboxSelection(type.product_type_code, selectedTypes, setSelectedTypes);
                        }}
                        checked={isChecked(type.product_type_code, 'type')}
                      /></span><span className='sentence-case'>{type.product_type_desc.toLowerCase()}</span>
                    </div>
                  ))}
                </div>
              </div>


              <div className={'filter-collapse ' + (toggleFilters ? 'active-filters' : '')}>
                <div className='filter-collapse-heading'>
                  <div onClick={() => setToggleColours(!toggleColours)}>
                    <span>Colour</span>
                    <span className='child-filter-caret'>
                      {!toggleColours && <GrDown className="product-card-ex__caret" />}
                      {toggleColours && <GrUp className="product-card-ex__caret" />}
                    </span>
                  </div>
                </div>
                <div className={'filter-collapse-body' + (toggleColours ? ' toggle-active' : '')}>
                  {availableColours && availableColours.map((group: any, i: any) => (
                    <div className='filter-collapse-row' >
                      <span className='mr-2'><input type='checkbox'
                        onChange={() => {
                          setCheckboxSelection(group?.code, selectedColours, setSelectedColours);
                        }}
                        checked={isChecked(group?.code, 'colour')}
                      /></span><span>{group?.name}</span>
                    </div>
                  ))}
                </div>
              </div>

              <div className={'filter-collapse ' + (toggleFilters ? 'active-filters' : '')}>
                <div className='filter-collapse-heading'>
                  <div onClick={() => setToggleGroups(!toggleGroups)}>
                    <span>Product Group</span>
                    <span className='child-filter-caret'>
                      {!toggleGroups && <GrDown className="product-card-ex__caret" />}
                      {toggleGroups && <GrUp className="product-card-ex__caret" />}
                    </span>
                  </div>
                </div>
                <div className={'filter-collapse-body' + (toggleGroups ? ' toggle-active' : '')}>
                  {availableGroups && availableGroups.map((group: any, i: any) => (
                    <div className='filter-collapse-row' >
                      <span className='mr-2'><input type='checkbox'
                        onChange={() => {
                          setCheckboxSelection(group, selectedGroups, setSelectedGroups);
                        }}
                        checked={isChecked(group, 'groups')}
                      /></span><span className='sentence-case'>{group.toLowerCase()}</span>
                    </div>
                  ))}
                </div>
              </div>

              <div className={'filter-collapse ' + (toggleFilters ? 'active-filters' : '')}>
                <div className='filter-collapse-heading'>
                  <div onClick={() => setToggleProductNames(!toggleProductNames)}>
                    <span>Quality</span>
                    <span className='child-filter-caret'>
                      {!toggleProductNames && <GrDown className="product-card-ex__caret" />}
                      {toggleProductNames && <GrUp className="product-card-ex__caret" />}
                    </span>
                  </div>
                </div>
                <div className={'filter-collapse-body' + (toggleProductNames ? ' toggle-active' : '')}>
                  {availableProducts && availableProducts.map((group: any, i: any) => (
                    <div className='filter-collapse-row' >
                      <span className='mr-2'><input type='checkbox'
                        onChange={() => {
                          setCheckboxSelection(group, selectedProducts, setSelectedProducts);
                        }}
                        checked={isChecked(group, 'quality')}
                      /></span><span className='sentence-case'>{group.toLowerCase()}</span>
                    </div>
                  ))}
                </div>
              </div>


              <div className={'filter-collapse ' + (toggleFilters ? 'active-filters' : '')}>
                <div className='filter-collapse-heading'>
                  <div onClick={() => setToggleWidths(!toggleWidths)}>
                    <span>Width</span>
                    <span className='child-filter-caret'>
                      {!toggleWidths && <GrDown className="product-card-ex__caret" />}
                      {toggleWidths && <GrUp className="product-card-ex__caret" />}
                    </span>
                  </div>
                </div>
                <div className={'filter-collapse-body' + (toggleWidths ? ' toggle-active' : '')}>
                  {availableWidths && availableWidths.map((group: any, i: any) => (
                    <div className='filter-collapse-row' >
                      <span className='mr-2'><input type='checkbox'
                        onChange={() => {
                          setCheckboxSelection(group, selectedWidths, setSelectedWidths);
                        }}
                        checked={isChecked(group, 'width')}
                      /></span><span>{group}m</span>
                    </div>
                  ))}
                </div>
              </div>




            </div>
          </div>
          <div className='col-md-9'>


            <div className="search page-search hide-mobile">

              <SearchBarFilter results={products} onNavigate={goTo} onChange={onChange} onSearch={onSearch} term={searchSelection} styleName="search-page" autocomplete={false} />

            </div>
            <div className="container">
              {fetchingProducts && !fetchingFailed &&
                <div className="row">
                  <div className="col-12 mb-5 text-center">
                    <div className="spinner-border text-center text-dark" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>

                  </div>
                </div>
              }
              {renderPaging()}
              <div className="row gy-5 search-row pb-5">
                {_.slice(filteredProducts, pageIndex * productsPerPage, (pageIndex * productsPerPage) + productsPerPage)?.map((p, i) => (
                  <div key={`${p.quality_full_name}-${i}`} className="col-lg-4 col-md-6">
                    <CatalogueItem
                      onClick={() => onClickProduct(p)}
                      imageCode={p.IMAGE_code}
                      productName={p.quality_full_name}
                      productDetails={p.colour_full_name}
                      price={p.price_from}
                      skuCode={p.product_sku}
                      pleaseCall={showPleaseCall(p)}
                      phone={customer?.branch_phone || ''}
                      uom={p.selling_unitofmeasure}
                      template={p.product_template}
                    />
                  </div>
                ))}
                {!fetchingProducts && !fetchingFailed && filteredProducts?.length === 0 && <div className="text-center">No records found matching your search.</div>}
              </div>
              {renderPaging()}
            </div>
          </div>
        </div>
      </div>
    </View>
  );
};

export default Clearance;

export const FETCH_PRODUCTS_REQUEST = 'FETCH_PRODUCTS_REQUEST';
export const FETCH_PRODUCTS_RESPONSE = 'FETCH_PRODUCTS_RESPONSE';
export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE';

export const FETCH_PRODUCT_REQUEST = 'FETCH_PRODUCT_REQUEST';
export const FETCH_PRODUCT_RESPONSE = 'FETCH_PRODUCT_RESPONSE';
export const FETCH_PRODUCT_FAILURE = 'FETCH_PRODUCT_FAILURE';

export interface Product {
  product_group: string;
  product_type_code: string;
  product_sku: string;
  quality_full_name: string;
  colour_full_name: string;
  WIDTH2: number;
  WIDTH1: number;
  WIDTH3: number;
  WIDTH4: number;
  IMAGE_code: string;
  product_description: string;
  stock_unitofmeasure: string;
  selling_unitofmeasure: string;
  sqm_per_box: string;
  product_category: string;
  product_colour_group: string;
  product_type: string;
  product_brand: string;
  product_suitability: string;
  price_from: number;
  rolls_only: boolean;
  packs_only_qty: number;
  product_template: string;
  colour: string;
  clearance: string;
}

export interface ProductState {
  products: Product[],
  product: Product | null;
  isFetchingProducts: boolean;
  fetchingProductsFailed: boolean;
}

interface FetchProductsRequestAction {
  type: typeof FETCH_PRODUCTS_REQUEST;
  payload: null;
}

interface FetchProductsResponseAction {
  type: typeof FETCH_PRODUCTS_RESPONSE;
  payload: Product[];
}

interface FetchProductsFailureAction {
  type: typeof FETCH_PRODUCTS_FAILURE;
  payload: null;
}

interface FetchProductRequestAction {
  type: typeof FETCH_PRODUCT_REQUEST;
  payload: null;
}

interface FetchProductResponseAction {
  type: typeof FETCH_PRODUCT_RESPONSE;
  payload: Product | null | undefined;
}

interface FetchProductFailureAction {
  type: typeof FETCH_PRODUCT_FAILURE;
  payload: null;
}

export type ProductActionTypes =
    | FetchProductsRequestAction
    | FetchProductsResponseAction
    | FetchProductsFailureAction
    | FetchProductRequestAction
    | FetchProductResponseAction
    | FetchProductFailureAction;

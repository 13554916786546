import React, { useEffect, useState } from 'react';
import '../styles/Update.scss';
import View from '../components/View';
import MyAccountHeader from '../components/MyAccountHeader';
import { Link, useHistory } from 'react-router-dom';
import Button from '../components/Button';
import AccountNav from '../components/AccountNav';
import { config } from '../config/config';
import TextInput from '../components/TextInput';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerID } from '../helpers/functions';
import { emailUpdateAccount } from '../actions/Email';
import { fetchCustomer } from '../actions/Customer';
import { getCustomer } from '../selectors/Customer';
import c from '../helpers/constants';

const UpdateAccount = () => {

  const history = useHistory();

  const dispatch = useDispatch();
  const [emailSent, setEmailSent] = useState(false);
  const [cname, setCName] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [contactNo, setContactNo] = useState('');
  const [companyNo, setCompanyNo] = useState('');
  const [companyContactNo, setCompanyContactNo] = useState('');

  const [addressLineOne, setAddressLineOne] = useState('');
  const [addressLineTwo, setAddressLineTwo] = useState('');
  const [town, setTown] = useState('');
  const [postcode, setPostcode] = useState('');

  const [cAddressLineOne, setCAddressLineOne] = useState('');
  const [cAddressLineTwo, setCAddressLineTwo] = useState('');
  const [cTown, setCTown] = useState('');
  const [cPostcode, setCPostcode] = useState('');
  const customer = useSelector(getCustomer);

  const onEmailClick = () => {
    history.push(c.APP_ROUTES.CONTACT);
  };
  const onContactClick = (e: any) => {
    e.preventDefault();
    history.push(c.APP_ROUTES.CONTACT);
  };
  useEffect(() => {
    setCustomerId(getCustomerID() as string);
    dispatch(fetchCustomer());

    setAddressLineOne(customer?.address_1 || '');
    setAddressLineTwo(customer?.address_2 || '');
    setFirstName(customer?.NAME || '');
    setEmail(customer?.contact_email || '');
    setContactNo(customer?.contact_tel || '');
    setTown(customer?.town || '');

  }, [dispatch]);




  const onUpdateAccount = async () => {
    await dispatch(emailUpdateAccount(
      {
        companyName: cname,
        customerId: customerId,
        firstName: firstName,
        lastName: lastName,
        email: email,
        contactNo: contactNo,
        companyContactNo: companyContactNo,
        companyNo: companyNo,
        addressLineOne: addressLineOne,
        addressLineTwo: addressLineTwo,
        town: town,
        postcode: postcode,
        cAddressLineOne: cAddressLineOne,
        cAddressLineTwo: cAddressLineTwo,
        cTown: cTown,
        cPostcode: cPostcode,
        branchEmail: customer?.branch_email || ''
      },
    ));
    setCustomerId('');
    setCName('');
    setEmailSent(true);
  };


  return (
    <View>
      <div className="grey-container">
        <div className="pb-5">
          <MyAccountHeader title={'My Account'} />
          <div className='myaccount-container container'>
            <div className="row">
              <div className="col-lg-3">
                <AccountNav />
              </div>
              {!emailSent &&
                <div className="col-lg-9">
                  <p className="font-bold account-information-title">Account Information</p>
                  {
                    config.site != 'handv' &&
                    <div className="row">
                      <div className="myaccount__content__body__popup">
                        <div className="myaccount__content__body__popup__warning">
                          <img src={config.Warning} alt="warning-sign" />
                        </div>
                        <div className="myaccount__content__body__popup__text">
                          <p>
                            If you wish to cancel or amend an order please{' '}
                            <a onClick={onContactClick} className="text-orange">
                              contact customer services.
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  }
                  <div className="row pt-5 info-section">
                    <div className="col-lg-6">
                      <h4 className="font-bold pb-5">Business Details</h4>
                      <p className="font-bold pb-2">Account Number</p>
                      <div className='pb-2'>
                        <TextInput id="customerId" bgColor="white" name="customerId" disabled={true}
                          value={customerId} onBlur={(e) => setCustomerId(e)}
                          onChange={(e) => setCustomerId(e)}
                          placeholder="" />
                      </div>
                      <p className="font-bold pb-2">Business Name</p>
                      <div className='pb-2'>
                        <TextInput id="name" bgColor="white" name="name"
                          value={cname} onBlur={(e) => setCName(e)}
                          onChange={(e) => setCName(e)}
                          placeholder="" />
                      </div>
                      <p className="font-bold pb-2">Company No.</p>
                      <div className='pb-2'>
                        <TextInput id="name" bgColor="white" name="name"
                          value={companyNo} onBlur={(e) => setCompanyNo(e)}
                          onChange={(e) => setCompanyNo(e)}
                          placeholder="" />
                      </div>
                      <p className="font-bold pb-2">Contact No.</p>
                      <div className='pb-2'>
                        <TextInput id="name" bgColor="white" name="name"
                          value={companyContactNo} onBlur={(e) => setCompanyContactNo(e)}
                          onChange={(e) => setCompanyContactNo(e)}
                          placeholder="" />
                      </div>
                      <p className="font-bold pb-2">Registered Address</p>
                      <div className='pb-2'>
                        <TextInput id="addressLineOne" bgColor="white" name="addressLineOne"
                          value={cAddressLineOne} onBlur={(e) => setCAddressLineOne(e)}
                          onChange={(e) => setCAddressLineOne(e)}
                          placeholder="Address Line One" />
                      </div>
                      <div className='pb-2'>
                        <TextInput id="addressLineTwo" bgColor="white" name="addressLineTwo"
                          value={cAddressLineTwo} onBlur={(e) => setCAddressLineTwo(e)}
                          onChange={(e) => setCAddressLineTwo(e)}
                          placeholder="Address Line Two" />
                      </div>
                      <div className='pb-2'>
                        <TextInput id="town" bgColor="white" name="town"
                          value={cTown} onBlur={(e) => setCTown(e)}
                          onChange={(e) => setCTown(e)}
                          placeholder="Town/City" />
                      </div>
                      <div className='pb-2'>
                        <TextInput id="postcode" bgColor="white" name="postcode"
                          value={cPostcode} onBlur={(e) => setCPostcode(e)}
                          onChange={(e) => setCPostcode(e)}
                          placeholder="Postcode" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <h4 className="font-bold pb-5">Contact Information</h4>
                      <div className='pb-2'>
                        <div className="row">
                          <div className="col-6">
                            <p className="font-bold pb-2">First Name</p>
                            <TextInput id="name" bgColor="white" name="name"
                              value={cname} onBlur={(e) => setFirstName(e)}
                              onChange={(e) => setFirstName(e)}
                              placeholder="" />
                          </div>
                          <div className="col-6">
                            <p className="font-bold pb-2">Last Name</p>
                            <TextInput id="name" bgColor="white" name="name"
                              value={cname} onBlur={(e) => setLastName(e)}
                              onChange={(e) => setLastName(e)}
                              placeholder="" />
                          </div>
                        </div>
                      </div>
                      <p className="font-bold pb-2">Email</p>
                      <div className='pb-2'>
                        <TextInput id="name" bgColor="white" name="name"
                          value={email} onBlur={(e) => setEmail(e)}
                          onChange={(e) => setEmail(e)}
                          placeholder="" />
                      </div>
                      <p className="font-bold pb-2">Contact No.</p>
                      <div className='pb-2'>
                        <TextInput id="name" bgColor="white" name="name"
                          value={cname} onBlur={(e) => setContactNo(e)}
                          onChange={(e) => setContactNo(e)}
                          placeholder="" />
                      </div>
                      <p className="font-bold pb-2">Address</p>
                      <div className='pb-2'>
                        <TextInput id="addressLineOne" bgColor="white" name="addressLineOne"
                          value={addressLineOne} onBlur={(e) => setAddressLineOne(e)}
                          onChange={(e) => setAddressLineOne(e)}
                          placeholder="Address Line One" />
                      </div>
                      <div className='pb-2'>
                        <TextInput id="addressLineTwo" bgColor="white" name="addressLineTwo"
                          value={addressLineTwo} onBlur={(e) => setAddressLineTwo(e)}
                          onChange={(e) => setAddressLineTwo(e)}
                          placeholder="Address Line Two" />
                      </div>
                      <div className='pb-2'>
                        <TextInput id="town" bgColor="white" name="town"
                          value={town} onBlur={(e) => setTown(e)}
                          onChange={(e) => setTown(e)}
                          placeholder="Town/City" />
                      </div>
                      <div className='pb-2'>
                        <TextInput id="postcode" bgColor="white" name="postcode"
                          value={postcode} onBlur={(e) => setPostcode(e)}
                          onChange={(e) => setPostcode(e)}
                          placeholder="Postcode" />
                      </div>
                    </div>
                  </div>
                  <p className="text-grey-tiny pb-4">
                    To make changes or update your account information please
                    contact us
                  </p>
                  <Button text="Request Update" disabled={!customerId || !cname} onClick={onUpdateAccount} />
                </div>
              }
              {emailSent && <div className='col-md-9'><div className="alert alert-success text-center" role="alert">
                Thank you for your request, our team will be in touch soon.
              </div></div>}
            </div>
          </div>
        </div>
      </div>
    </View>
  );
};

export default UpdateAccount;

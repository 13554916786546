import {
  SEND_EMAIL_REQUEST,
  SEND_EMAIL_RESPONSE,
  SEND_EMAIL_FAILURE,
  EmailState,
  EmailActionTypes,
} from '../types/Email';

const initialState: EmailState = {
  isSendingEmail: false,
  sendingEmailFailed: false,
};

const emailReducer = (
  state = initialState,
  action: EmailActionTypes,
): EmailState => {
  switch (action.type) {
    case SEND_EMAIL_REQUEST:
      return {
        ...state,
        isSendingEmail: true,
        sendingEmailFailed: false,
      };
    case SEND_EMAIL_RESPONSE:
      return {
        ...state,
        isSendingEmail: false,
        sendingEmailFailed: false,
      };
    case SEND_EMAIL_FAILURE:
      return {
        ...state,
        isSendingEmail: false,
        sendingEmailFailed: true,
      };
    default:
      return state;
  }
};

export default emailReducer;

import React from 'react';
import './RotatingBanner.scss';
import { config } from '../../config/config';
import CONSTANTS from '../../helpers/constants';
import { useHistory } from 'react-router-dom';
import { Carousel } from 'react-bootstrap';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

type Props = {
  text: string;
  images: string[];
};

const RotatingBanner = (props: Props) => {

  const history = useHistory();

  const goBack = () => {
    if (document.referrer == '') {
      history.push(CONSTANTS.APP_ROUTES.DASHBOARD);
    } else {
      history.goBack();
    }
  };

  const {
    text = '',
    images = [],
  } = props;
  return (

    <div className="rotating-banner">

      <img src={config.back} className="back-icon" alt="back-icon" onClick={goBack} />

      <div className="rotating-banner__text">
        <h1>{text}</h1>
      </div>

      <Carousel interval={3000} pause={false} slide={true} wrap={true} touch={true}>{
        images && images.map(x =>
          <Carousel.Item>
            <div className="rotating-banner__img">
              <img src={x} />
            </div>
          </Carousel.Item>
        )}
      </Carousel>


    </div>
  );
};

export default RotatingBanner;

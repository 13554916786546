import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import c from '../../helpers/constants';
import { NavLink } from 'react-router-dom';
import CONSTANTS from '../../helpers/constants';
import LWModal from '../Modal/Modal';
import './AccountNav.scss';

import {
    getCustomerID, setCustomerID, setCustomerPostcode,
} from '../../helpers/functions';
import history from '../../helpers/history';
import ChangePassword from '../ChangePassword';
import { clearBasketRequest } from '../../actions/Order';
import { config } from '../../config/config';
import Button from '../Button';
import { getCustomer } from '../../selectors/Customer';
import { fetchCustomer } from '../../actions/Customer';

const AccountNav = () => {
    const dispatch = useDispatch();
    const [toggle, setToggle] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const customer = useSelector(getCustomer);



    const enablePricelists = config.hasOwnProperty('enablePricelists') && config.enablePricelists;

    const onLogout = () => {
        clearBasketRequest();
        setCustomerID('');
        setCustomerPostcode('');
        window.location.href = window.location.href; //
    };

    const managePasswordChangeOption = () => {
        if (config.popupPasswordReset == true) {
            setToggle(true);
            return;
        }
        history.push({ pathname: c.APP_ROUTES.PASSWORD_CHANGE });

    };


    return (
        <div className="account-nav-outer">
            <ul>
                <NavLink
                    exact={true}
                    to={CONSTANTS.APP_ROUTES.ACCOUNT}
                    className="menu-link"
                    activeClassName="active"
                >
                    <li className="pb-4">Account Information</li>
                </NavLink>
                <NavLink
                    exact={true}
                    to={CONSTANTS.APP_ROUTES.ORDERS}
                    className="menu-link"
                    activeClassName="active"
                >
                    <li className="pb-4">My Orders</li>
                </NavLink>


                {(enablePricelists &&  customer && !customer?.is_matting) &&
                    <NavLink
                        exact={true}
                        to={CONSTANTS.APP_ROUTES.PRICELIST}
                        className="menu-link"
                        activeClassName="active"
                    >
                        <li className="pb-4">My Pricelist</li>
                    </NavLink>
                }


                <NavLink
                    exact={true}
                    to={CONSTANTS.APP_ROUTES.ACCOUNT_SUMMARY}
                    className="menu-link"
                    activeClassName="active"
                >
                    <li className="pb-4">Account Summary</li>
                </NavLink>


                <NavLink
                    exact={true}
                    to={CONSTANTS.APP_ROUTES.UPDATE_ACCOUNT_INFO}
                    className="menu-link"
                    activeClassName="active"
                >
                    <li className="pb-4">Update Account Info</li>
                </NavLink>



                <a onClick={() => managePasswordChangeOption()} className={CONSTANTS.APP_ROUTES.PASSWORD_CHANGE == window.location.pathname ? 'active menu-link' : 'menu-link'}>

                    <li className="pb-4">Change Password</li>
                </a>
            </ul>
            <ul>
                <li>
                    <NavLink
                        exact={true}
                        to={CONSTANTS.APP_ROUTES.DELETE_ACCOUNT}
                        className="menu-link delete-account-link"
                        activeClassName="active">
                        Delete Account
                    </NavLink>
                </li>
                {
                    config.myAccount.showLogin &&
                    <li className='pt-2'>
                        <Button text="Logout" type="grey" onClick={onLogout} />
                    </li>
                }
            </ul>
            <LWModal title="Change Password" onClose={() => {
                setToggle(false);
                setEmailSent(false);
            }}
                show={toggle}>
                <ChangePassword></ChangePassword>
            </LWModal>
        </div>


    );
};

export default AccountNav;

import React, { useState } from 'react';
import './SearchBarFilter.scss';
import SearchBar from '../SearchBar';
import Button from '../Button';
import AutoSuggest from '../AutoSuggest/AutoSuggest';
import { result } from 'lodash';
import { Product } from '../../types/Product';

type Props = {
  onSearch?: (arg: string) => void;
  onChange?: (arg: string) => void;
  onNavigate?: (arg: Product) => void;
  term?: string;
  styleName?: string;
  results?: any[];
  autocomplete?: boolean;
};
const SearchBarFilter = (props: Props) => {
  const [value, setValue] = useState(props.term || '');
  const [showAutocomplete, setShowAutocomplete] = useState(false);
  const [disappearReference, setDisappearReference] = useState<any>(null);
  const disappearTimeout = 3000;

  const doAutocomplete = props.autocomplete == null || props.autocomplete == true;


  const onChange = (arg: string) => {
    setValue(arg);
    if (arg.length >= 3) {
      if (doAutocomplete) {
        setShowAutocomplete(true);
        clearDisappearTimer();
      }
    }
    if (arg.length == 0) {
      if (doAutocomplete) {
        setShowAutocomplete(true);
        clearDisappearTimer();
      }
    }
    props.onChange?.(arg);
  };

  const onSubmit = () => {
    if (value.length > 1) {
      props.onSearch?.(value);
    }
  };

  const clearDisappearTimer = () => {
    if (disappearReference) {
      clearTimeout(disappearReference);
      setDisappearReference(null);
    }
  };

  const goTo = (product: Product) => {
    props.onNavigate?.(product);
  };

  const setDisappearTimer = () => {
    let t = setTimeout(() => {
      setShowAutocomplete(false);
    }, disappearTimeout);
    setDisappearReference(t);

  };

  return (
    <div className={'d-table w-100 search-filter' + (' ' + props.styleName || '')}>
      <div className="search-filter__bar mr-2">
        <SearchBar term={props.term} onChange={onChange} onEnter={onSubmit} onMouseOver={clearDisappearTimer} onMouseOut={setDisappearTimer} />
        {
          (props.term && props.term.length > 0 && showAutocomplete) &&
          <AutoSuggest results={props.results ? props.results : []} doClick={(x) => goTo(x)} onMouseOut={setDisappearTimer} onMouseOver={clearDisappearTimer}></AutoSuggest>
        }
      </div>
      <div className="search-filter__control">
        <Button text="Search" onClick={onSubmit} />
      </div>
    </div>
  );
};

export default SearchBarFilter;

import axios from 'axios'

const SetupInterceptors = (navigate: any) => {
    axios.interceptors.response.use(
        function (response) {
            return response;
        },
        function (error) {
            // Do something with response error
            if (error.response) {
                if (error.response.status === 401 || error.response.status === 403) {
                    navigate.push('/login');
                }
            }
            return Promise.reject(error);
        }
    );
};

export default SetupInterceptors;
import React, { useEffect } from 'react';
import './PopupBasket.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getBasket } from '../../selectors/Order';
import { addToBasket, clearBasket } from '../../actions/Order';
import { fetchCustomer } from '../../actions/Customer';
import { getCustomer } from '../../selectors/Customer';
import { roundNumber } from '../../helpers/functions';
import CONSTANTS from '../../helpers/constants';
import { useHistory } from 'react-router-dom';
import CardPopupCheckout from '../CardPopupCheckout';
import { config } from '../../config/config';


type Props = {
  onClick?: () => void;
};

const PopupBasket = ( props : Props ) => {
  const orderLines = useSelector(getBasket);
  const dispatch = useDispatch();
  const customer = useSelector(getCustomer);
  const history = useHistory();

  const onDelete = (index: number) => {
    const localOrderLines = orderLines?.filter((o, i) => i !== index);
    dispatch(clearBasket());
    dispatch(addToBasket(localOrderLines, customer?.delivery_address_code, true, false));
  };


  const onCheckout = () => {
    history.push(CONSTANTS.APP_ROUTES.CART);
  };


  useEffect(() => {
    dispatch(fetchCustomer());
  }, [dispatch]);

  let totalWithoutVat = 0;


  orderLines?.forEach(o => totalWithoutVat += o.line_price);


  return (
      <div className='popup-basket-outer'>
      <div className='popup-basket'>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 orders-header">
              
              <a onClick={props.onClick} className='btn btn-back'>
                  <img src={config.back} alt="back-icon"/>       
              </a>
              <div className="orders-header__title">
                <h3 className='text-center'>Basket</h3>
                <p className='text-center'><span className='text-grey small'>There are items in your basket</span></p>
              </div>
              <div className="orders__search">
              
              {orderLines.map((o, i) => <CardPopupCheckout
                key={i}
                imageCode={o.product_sku}
                onDelete={() => onDelete(i)}
                quality={o.product_quality}
                category={o.product_type_code_name}
                categoryCode={o.product_type_code}
                linePrice={o.line_price}
                itemPrice={o.item_price}
                quantity={o.quantity}
                length={o.length || 0}
                width={o?.width || 0}
                reference={o.line_reference}
                color={o.colour_full_name}
                sqmPrice={o.price_per_sqm || 0}
              />)}
            </div>
          </div>
        </div>
        <div className='orders-footer'>
        <div className="row align-items-center">
            <div className="col-lg-6">

                  <div className="d-flex justify-content-between">
                    <h5 className='text-orange'>Order Total</h5>
                  </div>
                  <div className="d-flex justify-content-between pt-3 orders-footer__text">

                    <div>
                      <p className="text-dark-grey">Subtotal</p>
                    </div>
                    <div className="text-dark-grey">£{roundNumber(totalWithoutVat)}</div>
                  </div>
                  <div className="d-flex justify-content-between orders-footer__text">
                    <div className="">
                      <p className="text-dark-grey">VAT (20%)</p>
                    </div>
                    <div className="text-dark-grey">£{roundNumber(totalWithoutVat * 0.2)}</div>
                  </div>
                  <div className="d-flex justify-content-between pt-3">
                    <div className="">
                      <p className="text-orange total-text">
                        <b>Total</b>
                      </p>
                    </div>
                    <div className="">
                      <p className="text-orange total-text">
                        <b>£{roundNumber(totalWithoutVat * 1.2)}</b>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div>
                   <a onClick={props.onClick} className="lw-button lw-button-grey"><span>Continue Shopping</span></a>
                  </div>
                  <div className="mt-3">
                    <a onClick={onCheckout} className="lw-button lw-button-orange"><span>Proceed to Checkout</span></a>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupBasket;

import React from 'react';
import { formatDate } from '../../helpers/functions';
import './ExpectedStockDate.scss';


type Props = {
  stock?: string;
  showPipe?: boolean;
};
const ExpectedStockDate = (props: Props) => {

  const doShowPipe = props.showPipe == undefined || props.showPipe == null || props.showPipe == true; 

  
  if (null == props.stock) {
    return (<><span>{doShowPipe ? <span>|</span> : ''}Expected: T.B.A</span></>);
  }
  
  if (undefined === props.stock || null === props.stock) {
    return (<><span>{doShowPipe ? <span>|</span> : ''}Expected: T.B.A</span></>);
  }
  
  if (props.stock == '2049-12-25') {
    return (<><span>{doShowPipe ? <span>|</span> : ''}Expected: T.B.A</span></>);
  }


  var date = new Date();
  date.setDate(date.getDate());
  let dateString = date.toISOString().substr(0, 10);

  if (props.stock < dateString) {
    return (<span>{doShowPipe ? <span>|</span> : '' }<span className='expected-stock--date'>Currently awaiting stock - please contact us for more details</span></span>);
  }

  let dt = props.stock.split('-');
  let ndt = new Date( parseInt(dt[0]), parseInt(dt[1])-1, parseInt(dt[2]) );
  let outDate = formatDate( ndt );
  return (<><span>{doShowPipe ? <span>|</span> : '' }Expected: {outDate}</span></>);

};
export default ExpectedStockDate;

import React, { useEffect } from 'react';
import '../styles/MyAccount.scss';
import View from '../components/View';
import Button from '../components/Button';
import MobileMenuBottom from '../components/MobileBottomMenu';
import MyAccountHeader from '../components/MyAccountHeader';
// import CardSmall from '../components/CardSmall';
import AccountNav from '../components/AccountNav';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCustomer } from '../actions/Customer';
import {
    getCustomer,
} from '../selectors/Customer';
import c from '../helpers/constants';
import history from '../helpers/history';


const MyAccount = () => {
    const p = useSelector(getCustomer);

    const onEmailClick = () => {
        history.push(c.APP_ROUTES.CONTACT);
    };

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchCustomer());
    }, [dispatch]);
    return (
        <View>
            <div className="grey-container">
                <div className="myaccount">
                    <MyAccountHeader title={'My Account'} />
                    <div className='myaccount-container container'>
                        <div className="row ">
                            <div className="col-lg-3  pb-5 myaccount__content__nav">
                                <AccountNav />
                            </div>
                            <div className="col-lg-9  myaccount-summary">
                                <p className="pb-4 account-information-title" >Account Information</p>

                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 pb-4">
                                        <h3 className="pb-4">Business Details</h3>
                                        <div className="pb-3">
                                            <p className="font-bold pb-1">Business Name</p>
                                            <p>{p?.NAME}</p>
                                        </div>
                                        <div className="pb-3">
                                            <p className="font-bold pb-1">Email</p>
                                            <p>{p?.contact_email}</p>
                                        </div>
                                        <div className="">
                                            <p className="font-bold pb-1">Registered Address</p>
                                            <p>{p?.address_1}</p>
                                            <p>{p?.address_2}</p>
                                            <p>{p?.town}</p>
                                            <p>{p?.county}</p>
                                            <p>{p?.pcode}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <h3 className="pb-4">Contact Information</h3>
                                        <div className="row">
                                            <div className="col-6 pb-3">
                                                <p className="font-bold pb-1">Contact Name</p>
                                                <p>{p?.contact_name}</p>
                                            </div>
                                            <div className="col-6 pb-3">
                                                <p className="font-bold pb-1">Contact No.</p>
                                                <p>{p?.contact_tel}</p>
                                            </div>
                                            <div className="col-6 pb-3">
                                                <p className="font-bold pb-1">Contact Mob.</p>
                                                <p>{p?.contact_mob}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr className="my-5" />
                                {/*<div className="row">*/}
                                {/*    <div className="col-lg-6 col-md-6 pb-5">*/}
                                {/*        <h3 className="pb-3">Latest Orders</h3>*/}
                                {/*        <CardSmall*/}
                                {/*            orderID="#01345"*/}
                                {/*            status="In Transit"*/}
                                {/*            date="10/02/21"*/}
                                {/*            amount="£000.00"*/}
                                {/*            color="#EDA310"*/}
                                {/*        />*/}
                                {/*        <CardSmall*/}
                                {/*            orderID="#01345"*/}
                                {/*            status="In Transit"*/}
                                {/*            date="10/02/21"*/}
                                {/*            amount="£000.00"*/}
                                {/*            color="#EDA310"*/}
                                {/*        />*/}
                                {/*        <Button text="View Orders"/>*/}
                                {/*    </div>*/}
                                {/*    <div className="col-lg-6 col-md-6 pb-5">*/}
                                {/*        <h3 className="pb-3">Latest Payments</h3>*/}
                                {/*        <CardSmall*/}
                                {/*            orderID="#01345"*/}
                                {/*            status="In Transit"*/}
                                {/*            date="10/02/21"*/}
                                {/*            amount="£000.00"*/}
                                {/*            color="#EDA310"*/}
                                {/*        />*/}
                                {/*        <CardSmall*/}
                                {/*            orderID="#01345"*/}
                                {/*            status="In Transit"*/}
                                {/*            date="10/02/21"*/}
                                {/*            amount="£000.00"*/}
                                {/*            color="#EDA310"*/}
                                {/*        />*/}
                                {/*        <Button text="View Payments"/>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contact-bottom">
                <div className="contact-bottom__title">
                    <h3>Can&apos;t find what you&apos;re looking for?</h3>
                </div>
                <div className="contact-bottom__desc">
                    <p>
                        Our support team would be happy to help, simply call or email us.
                    </p>
                </div>
                <div className="contact-bottom__cta">
                    <div className="contact-bottom__cta__left">
                        <Button text="Call Us" onClick={onEmailClick} />
                    </div>
                    <div className="contact-bottom__cta__left">
                        <Button text="Email Us" onClick={onEmailClick} />
                    </div>
                </div>
            </div>
            <MobileMenuBottom />
        </View>
    );
};

export default MyAccount;

import React from 'react';
import './Card.scss';
import Button from '../Button';

type Props = {
  title: string;
  imageUrl: string;
  imageAlt?: string;
  onClick?: () => void;
};

const Card = (props: Props) => {
  const {
    title = '',
    imageUrl = '',
    imageAlt = '',
    onClick = () => {},
  } = props;
  return (
        <div className="l-card" onClick={onClick}>
            <img src={imageUrl} alt={imageAlt} className="l-card__image"/>
            <div className="l-card__details">
                <div className="l-card__details__title">{title}</div>
                <Button text="View Products"/>
            </div>
        </div>
  );
};

export default Card;

import React from 'react';
import './StatusLight.scss';

const StatusLight = (props: { status: string }) => {
  return (
    <>
    <div className="d-flex flex-row align-items-center status-light">
      <div>
        {props.status == 'Cancelled' && <div className="red-circle"/> }
        {props.status == 'Invoiced' && <div className="amber-circle"/> }
        {props.status == 'Processing' && <div className="amber-circle"/> }
        {props.status == 'Ready For Despatch' && <div className="amber-circle"/> }

        {props.status == 'Dispatched' && <div className="amber-circle"/> }
        {props.status == 'Out for Delivery' && <div className="amber-circle"/> }
        {props.status == 'Delivered' && <div className="green-circle"/> }
        {props.status == 'Cred Held' && <div className="red-circle"/> }
        {props.status == 'Date T.B.A' && <div className="red-circle"/> }
        {props.status == 'Date Passed' && <div className="red-circle"/> }
      </div>
      <div className="text-grey-smaller">
          {props.status}
      </div>
    </div>
    </>
  );  
};
export default StatusLight;

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import c from '../../helpers/constants';
import formatDate from 'date-fns/format';
import parseIso from 'date-fns/parseISO';

import './CardOrderFull.scss';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { config } from '../../config/config';

type Props = {
  orderNumber: string;
  date: string;
  deliveryDate: string;
  status: string;
  items: number;
  total: number;
  checkedAlt: string;
  orderLines: any;
};

const CardOrderFull = (props: Props) => {
  const {
    orderNumber = '',
    date = '',
    deliveryDate = '',
    status = '',
    items = 0,
    total = 0.00,
    orderLines = [],
  } = props;

  const [showLines, setShowLines] = useState(false);

  const history = useHistory();

  return (
    <div className="card-order-full">
      <div className="card-order-full-title">
        <h2>Order No: {orderNumber}</h2>
      </div>
      <div className="card-order-full-details">

        <div className="card-order-full-cell">
          <div className="card-order-full-cell-title">
            Date
          </div>
          <div className="card-order-full-cell-field">
            {date}
          </div>
        </div>

        <div className="card-order-full-cell">
          <div className="card-order-full-cell-title">
            Delivery
          </div>
          <div className="card-order-full-cell-field">
            {deliveryDate}
          </div>
        </div>

        <div className="card-order-full-cell">
          <div className="card-order-full-cell-title">
            Status
          </div>
          <div className="card-order-full-cell-field">
            {status}
          </div>
        </div>

        <div className="card-order-full-cell">
          <div className="card-order-full-cell-title">
            Items In Order
          </div>
          <div className="card-order-full-cell-field">
            {items}
          </div>
        </div>

        <div className="card-order-full-cell">
          <div className="card-order-full-cell-title">
            Total
          </div>
          <div className="card-order-full-cell-field">
            £{total}
          </div>
        </div>
      </div>
      <div className="card-order-full-actions">
        <div className="card-order-full-summary" onClick={() => history.push(c.APP_ROUTES.ORDERS)}>
          Show Order Summary
        </div>
        <div className="card-order-full-show-lines">
          {!showLines &&
            <IoIosArrowDown style={{ color: config.dashboard.activities.arrowColor }} onClick={() => { setShowLines(!showLines) }} />}
          {showLines &&
            <IoIosArrowUp style={{ color: config.dashboard.activities.arrowColor }} onClick={() => { setShowLines(!showLines) }} />}
        </div>
      </div>
      {showLines &&
        <div className="card-order-full-lines">
          <React.Fragment key={0}>
            <div className="card-order-full-cell">
              <div className="card-order-full-cell-title">
                Description
              </div>
            </div>

            <div className="card-order-full-cell">
              <div className="card-order-full-cell-title">
                Date
              </div>
            </div>

            <div className="card-order-full-cell">
              <div className="card-order-full-cell-title">
                Quantity
              </div>
            </div>

            <div className="card-order-full-cell">
              <div className="card-order-full-cell-title">
                Price
              </div>
            </div>

            <div className="card-order-full-cell">
              <div className="card-order-full-cell-title">
                Total
              </div>
            </div>
          </React.Fragment>

          {orderLines.map((line: any) => {
            console.log(line);
            return (
              <React.Fragment key={line.order_line}>
                <div className="card-order-full-cell">
                  <div className="card-order-full-cell-field">
                    {line.product_desc}
                  </div>
                </div>

                <div className="card-order-full-cell">
                  <div className="card-order-full-cell-field">
                    {formatDate(parseIso(line.date_ordered), 'do MMM yyy')}
                  </div>
                </div>

                <div className="card-order-full-cell">
                  <div className="card-order-full-cell-field">
                    {line.qty_ordered}
                  </div>
                </div>

                <div className="card-order-full-cell">
                  <div className="card-order-full-cell-field">
                    {line.line_price}
                  </div>
                </div>

                <div className="card-order-full-cell">
                  <div className="card-order-full-cell-field">
                    {line.line_value}
                  </div>
                </div>
              </React.Fragment>)
          })}
        </div>}
    </div>
  );
};

export default CardOrderFull;

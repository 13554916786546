import React, { useEffect, useState } from 'react';
import '../styles/MyOrders.scss';
import View from '../components/View';
import Button from '../components/Button';
import MobileMenuBottom from '../components/MobileBottomMenu';
import { Link } from 'react-router-dom';
import OrderSummary from '../components/OrderSummary';
import MyAccountHeader from '../components/MyAccountHeader';
import AccountNav from '../components/AccountNav';
import { useSelector, useDispatch } from 'react-redux';
import { fetchOrders } from '../actions/Order';
import {
    getOrders,
    didFetchingOrdersFailed,
    isFetchingOrders,
} from '../selectors/Order';
import c from '../helpers/constants';
import history from '../helpers/history';
import { config } from '../config/config';


const MyOrders = () => {
    const fetchingOrders = useSelector(isFetchingOrders);
    const orders = useSelector(getOrders);
    const [activeStatus, setActiveStatus] = useState(null);
    const fetchingFailed = useSelector(didFetchingOrdersFailed);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchOrders());
    }, [dispatch]);

    const onEmailClick = () => {
        history.push(c.APP_ROUTES.CONTACT);
    };
    const onContactClick = (e: any) => {
        e.preventDefault();
        history.push(c.APP_ROUTES.CONTACT);
    };
    // const statuses = [
    //   { label: 'ALL', value: null },
    //   { label: 'Processing', value:'Processing' },
    //   { label: 'Dispatched', value: 'Dispatched' },
    //   { label: 'Out for Delivery', value: 'Out for Delivery' },
    //   { label: 'Delivered', value: 'Delivered' },
    // ];


    const statusesRaw = {};
    orders?.map(o => {
        // @ts-ignore
        statusesRaw[o.order_status] = 1;
    });

    //Filter out the cancelled from the main statuses
    const filteredStatuses = Object.keys(statusesRaw).filter((x) => (x != 'Cancelled'));

    //Grab all statuses that aren't cancelled
    const statuses = [{ label: 'All Orders', value: null }, ...filteredStatuses.map(s => ({ label: s, value: s }))];

    //If we have a cancelled order then pin the cancelled status to the right
    const cancelledStatuses: Array<any> = filteredStatuses.length != Object.keys(statusesRaw).length ? [{ label: 'Cancelled', value: 'Cancelled' }] : [];


    const onFilter = (status: any) => {
        setActiveStatus(status.value);
    };

    const ordersOrgAll = orders?.filter(o => activeStatus ? o.order_status === activeStatus : true);

    const ordersOrg = {};
    ordersOrgAll.map(o => {
        // @ts-ignore
        if (!ordersOrg[o.order_number]) {
            // @ts-ignore
            ordersOrg[o.order_number] = [];
        }
        // @ts-ignore
        ordersOrg[o.order_number].push(o);
    });


    return (
        <View>
            <div className="grey-container">
                <div className="myaccount">
                    <MyAccountHeader title={'My Account'} />
                    <div className='myaccount-container container'>
                        <div className="row myaccount__content">
                            <div className="col-lg-3 myaccount__content__nav">
                                <AccountNav />
                            </div>
                            <div className="col-lg-9 myaccount__content__body">
                                <div className="row myaccount__content__body__top">
                                    {config.showSubTitlesOnAccount &&
                                    <h3 className='my-orders-title'>My Orders</h3>
                                    }
                                    <div className="myaccount__content__body__top__left">

                                        <div className="myaccount__content__body__top__left__status">
                                            <div className="myaccount__content__body__top__left__status__left">
                                                {statuses.map((s, i) =>
                                                    <div onClick={() => onFilter(s)} key={i} className={`cursor-pointer myaccount__content__body__top__left__status__active ${s.value === activeStatus && 'active'}`}>
                                                        <p> {i > 0 && ''} {s.label}</p>
                                                    </div>,
                                                )}
                                            </div>
                                            <div className="myaccount__content__body__top__left__status__right">
                                                {cancelledStatuses.map((s, i) =>
                                                    <div onClick={() => onFilter(s)} key={i} className={`cursor-pointer myaccount__content__body__top__left__status__active ${s.value === activeStatus && 'active'}`}>
                                                        <p> {i == 0 && <span>|</span>} {s.label}</p>
                                                    </div>,
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="myaccount__content__body__popup">
                                        <div className="myaccount__content__body__popup__warning">
                                            <img src={config.Warning} alt="warning-sign" />
                                        </div>
                                        <div className="myaccount__content__body__popup__text">
                                            <p>
                                                If you wish to cancel or amend an order please{' '}
                                                <a onClick={onContactClick} className="text-orange">
                                                    contact customer services.
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="myaccount__content__body__count">
                                        <div className="myaccount__content__body__count__left">
                                            <p>Total Orders: {ordersOrgAll?.length} {activeStatus !== null ? `(${activeStatus})` : ''}</p>
                                        </div>
                                        <div className="myaccount__content__body__count__right">
                                            {/*<p>Displaying: Active Orders (14)</p>*/}
                                        </div>
                                    </div>
                                    {Object.keys(ordersOrg)?.sort((a, b) => parseInt(b) - parseInt(a)).map((p) => (
                                        <OrderSummary key={p}
                                            // @ts-ignore
                                            orderLines={ordersOrg[p]}
                                            orderNumber={p}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contact-bottom">
                    <div className="contact-bottom__title">
                        <h3>Can&apos;t find what you&apos;re looking for?</h3>
                    </div>
                    <div className="contact-bottom__desc">
                        <p>
                            Our support team would be happy to help, simply call or email us.
                        </p>
                    </div>
                    <div className="contact-bottom__cta">
                        <div className="contact-bottom__cta__left">
                            <Button text="Call Us" onClick={onEmailClick} />
                        </div>
                        <div className="contact-bottom__cta__left">
                            <Button text="Email Us" onClick={onEmailClick} />
                        </div>
                    </div>
                </div>
            </div>
            <MobileMenuBottom />
        </View>
    );
};

export default MyOrders;

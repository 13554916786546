import {
  FETCH_CUSTOMER_REQUEST,
  FETCH_CUSTOMER_RESPONSE,
  FETCH_CUSTOMER_FAILURE,
  CustomerState,
  CustomerActionTypes,
} from '../types/Customer';

const initialState: CustomerState = {
  customer: null,
  isFetchingCustomer: false,
  fetchingCustomerFailed: false,
};

const customerReducer = (
  state = initialState,
  action: CustomerActionTypes,
): CustomerState => {
  switch (action.type) {
    case FETCH_CUSTOMER_REQUEST:
      return {
        ...state,
        customer: null,
        isFetchingCustomer: true,
        fetchingCustomerFailed: false,
      };
    case FETCH_CUSTOMER_RESPONSE:
      return {
        ...state,
        customer: action.payload || null,
        isFetchingCustomer: false,
        fetchingCustomerFailed: false,
      };
    case FETCH_CUSTOMER_FAILURE:
      return {
        ...state,
        customer: null,
        isFetchingCustomer: false,
        fetchingCustomerFailed: true,
      };
    default:
      return state;
  }
};

export default customerReducer;

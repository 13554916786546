import React, { useEffect } from 'react';
import '../styles/CurrentBalance.scss';
import View from '../components/View';
import Button from '../components/Button';
import MobileMenuBottom from '../components/MobileBottomMenu';
import MyAccountHeader from '../components/MyAccountHeader';
import AccountNav from '../components/AccountNav';
import { useSelector, useDispatch } from 'react-redux';
import { fetchStatements } from '../actions/Statement';
import { isFetchingStatements, getStatements, didFetchingStatementsFailed } from '../selectors/Statement';

const CurrentBalance = () => {
  const fetchingStatements = useSelector(isFetchingStatements);
  const statements = useSelector(getStatements);
  const fetchingFailed = useSelector(didFetchingStatementsFailed);
  const dispatch = useDispatch();
  useEffect(()=> {
    dispatch(fetchStatements());
  }, [dispatch]);
  return (
    <View>
      <div className="grey-container pb-5">
        <div className="container myaccount">
          <MyAccountHeader title={'My Account'} />
          <div className="row pb-5">
            <div className="col-lg-3 col-md-3 col-sm-12 pb-5 myaccount__content__nav">
            <AccountNav />
            </div>
            <div className="col-lg-9 col-md-9 col-sm-12 ">
              <p className="font-bold">Current Balances</p>
              <div className="row mt-5">
                <div className="col-lg-6 col-md-5 col-sm-5">
                  <p className="font-bold text-grey-tiny">Item</p>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3">
                  <p className="font-bold text-grey-tiny">Total</p>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-4">
                  <p className="font-bold text-grey-tiny">Action</p>
                </div>
              </div>
              <hr className="mb-3" />
              <div className="row my-4 balance-row">
                <div className="col-lg-6 col-md-5 col-sm-5">
                  <p className="font-bold text-grey-tiny">04_2021_Statement</p>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3">
                  <p className="font-bold text-grey-tiny">£457.89</p>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-4">
                  <Button text="Download" />
                </div>
              </div>
              <hr className="mb-" />
              <div className="row my-4 balance-row">
                <div className="col-lg-6 col-md-5 col-sm-5">
                  <p className="font-bold text-grey-tiny">04_2021_Statement</p>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3">
                  <p className="font-bold text-grey-tiny">£457.89</p>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-4">
                  <Button text="Download" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8"></div>
            <div className="col-lg-4">
              <p className="font-bold pb-3">Outstanding Balance</p>
              <div className="space-btw">
                <p>Balance</p>
                <p>£000.00</p>
              </div>
              <hr />
              <div className="space-btw pb-3">
                <p>Current</p>
                <p>£000.00</p>
              </div>
              <div className="space-btw pb-3">
                <p>30 Days</p>
                <p>£000.00</p>
              </div>
              <div className="space-btw pb-3">
                <p>60 Days</p>
                <p>£000.00</p>
              </div>
              <div className="space-btw pb-3">
                <p>90 Days</p>
                <p>£000.00</p>
              </div>
              <div className="space-btw pb-3">
                <p>120 Days</p>
                <p>£000.00</p>
              </div>
              <hr />
              <div className="space-btw">
                <p>Overdue</p>
                <p>£000.00</p>
              </div>
              <hr />
              <div className="space-btw">
                <p className="font-bold">Total Balance</p>
                <p className="font-bold">£000.00</p>
              </div>
              <p className="text-grey-tiny py-4">
                To make a contribution towards your current balance, payments
                are requested via BACS to the following account details:
              </p>
              <div className="space-btw">
                <p>Account No:</p>
                <p>000000</p>
              </div>
              <div className="space-btw">
                <p>Sort Code:</p>
                <p>00-00-00</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="contact-bottom">
        <div className="contact-bottom__title">
          <h3>Can&apos;t find what you&apos;re looking for?</h3>
        </div>
        <div className="contact-bottom__desc">
          <p>
            Our support team would be happy to help, simply call or email us.
          </p>
        </div>
        <div className="contact-bottom__cta">
          <div className="contact-bottom__cta__left">
            <Button text="Call Us"  />
          </div>
          <div className="contact-bottom__cta__left">
            <Button text="Email Us" />
          </div>
        </div>
      </div>
      <MobileMenuBottom />
    </View>
  );
};

export default CurrentBalance;

import {
  FETCH_PRODUCT_TYPES_REQUEST,
  FETCH_PRODUCT_TYPES_RESPONSE,
  FETCH_PRODUCT_TYPES_FAILURE,
  ProductTypeState,
  ProductTypeActionTypes,
} from '../types/ProductType';

const initialState: ProductTypeState = {
  productTypes: [],
  isFetchingProductTypes: false,
  fetchingProductTypesFailed: false,
};

const productTypeReducer = (
  state = initialState,
  action: ProductTypeActionTypes,
): ProductTypeState => {
  switch (action.type) {
    case FETCH_PRODUCT_TYPES_REQUEST:
      return {
        ...state,
        productTypes: [],
        isFetchingProductTypes: true,
        fetchingProductTypesFailed: false,
      };
    case FETCH_PRODUCT_TYPES_RESPONSE:
      return {
        ...state,
        productTypes: action.payload || [],
        isFetchingProductTypes: false,
        fetchingProductTypesFailed: false,
      };
    case FETCH_PRODUCT_TYPES_FAILURE:
      return {
        ...state,
        productTypes: [],
        isFetchingProductTypes: false,
        fetchingProductTypesFailed: true,
      };
    default:
      return state;
  }
};

export default productTypeReducer;

import React, { useEffect, useState } from 'react';
import '../styles/Search.scss';
import View from '../components/View';
import PageHeader from '../components/PageHeader';
import SearchBarFilter from '../components/SearchBarFilter';
import CatalogueItem from '../components/CatalogueItem';

import {
  didFetchingProductsFailed,
  getProducts,
  isFetchingProducts,
} from '../selectors/Product';
import { useSelector, useDispatch } from 'react-redux';
import { fetchProductTypes } from '../actions/ProductType';
import { clearProducts, fetchProducts } from '../actions/Product';
import { getProductTypes } from '../selectors/ProductType';
import { getCategoryBannerImage, objectToQueryString, useQuery } from '../helpers/functions';
import _, { debounce } from 'lodash';
import DropDown from '../components/DropDown';
import { Product } from '../types/Product';
import history from '../helpers/history';
import c from '../helpers/constants';
import { getCustomer } from '../selectors/Customer';
import RotatingBanner from '../components/RotatingBanner';
import { config } from '../config/config';
import chevronLeft from './../assets/svgs/chevron_left.svg';
import chevronRight from './../assets/svgs/chevron_right.svg';

const Search = () => {

  const customer = useSelector(getCustomer);
  const fetchingProducts = useSelector(isFetchingProducts);
  const products = [...useSelector(getProducts)];

  const productTypes = useSelector(getProductTypes);
  const [title, setTitle] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageIndex, setPageIndex] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showFirst, setShowFirst] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showPrevious, setShowPrevious] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showNext, setShowNext] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showLast, setShowLast] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageCount, setPageCount] = useState(0);
  const [pageRange, setPageRange] = useState<number[]>([]);
  const [productsPerPage, setProductsPerPage] = useState(config.pagination.even ? 8 : 9);
  const fetchingFailed = useSelector(didFetchingProductsFailed);
  const query = useQuery();
  const dispatch = useDispatch();

  const [productTypeCode, setProductTypeCode] = useState<any>(null);
  const [searchSelection, setSearchSelection] = useState<any>(null);

  if (null != products && config.orderAtoZ == true) {
    products.sort((a: Product, b: Product) => {
      if (a.quality_full_name > b.quality_full_name) {
        return 1;
      }
      if (a.quality_full_name < b.quality_full_name) {
        return -1;
      }
      return 0;
    });
  }

  useEffect(() => {
    if (null == productTypeCode) {
      setProductTypeCode(query.get('productTypeCode') || '');
    }
    if (null == searchSelection) {
      setSearchSelection(query.get('searchSelection') || '');
    }
    initPages();
  }, []);

  useEffect(() => {
    setTitle('');
    dispatch(fetchProductTypes());

    if (productTypeCode == '' && searchSelection == '') {
      dispatch(clearProducts());
    } else if (searchSelection != null && searchSelection.length >= 3) {
      dispatch(fetchProducts(productTypeCode, searchSelection));
    } else if ((searchSelection == null || searchSelection == '') && productTypeCode != null) {
      dispatch(fetchProducts(productTypeCode, searchSelection));
    }

    setShowFirst(false);
    setShowPrevious(false);

  }, [dispatch, productTypeCode, searchSelection]);



  useEffect(() => {
    if (productTypes?.length > 0 && productTypeCode) {
      const found = productTypes?.find(p => p.product_type_code === productTypeCode);
      setTitle(_.startCase(_.toLower(found?.product_type_desc)));
    }
  }, [productTypes]);


  useEffect(() => {
    initPages();
  }, [searchSelection, productTypeCode, products.length]);


  const initPages = () => {
    if (products?.length > 0) {
      if (searchSelection && !productTypeCode) {
        setTitle(`We found ${products?.length} products for: ${searchSelection}`);
      }
      let localPageCount = products?.length / productsPerPage;
      if (localPageCount > 5) {
        localPageCount = 5;
      }
      setPageIndex(0);
      setPageCount(localPageCount);
      // @ts-ignore
      setPageRange(_.range(0, localPageCount));
      return;
    }

    if (searchSelection && !productTypeCode) {
      setTitle(`We found 0 products for: ${searchSelection}`);
    }
    setPageIndex(0);
    setPageCount(0);
    // @ts-ignore
    setPageRange(_.range(0, 0));
  };


  const applyPageRangeLogic = (currIdx: number) => {
    //Total pages
    let localPageCount = Math.ceil(products?.length / productsPerPage);

    //Show 5 pages or less if there are fewer
    let pagesToShow: number = localPageCount < 5 ? localPageCount : 5;

    //If there are fewer resolve it now and show all
    let start = 0;
    let end = pagesToShow;
    if (localPageCount > 5) {
      //Get a sliding window of x items
      start = (currIdx - (Math.floor(pagesToShow / 2)));
      start = start < 0 ? 0 : start;
      end = start + pagesToShow;


      if (end > localPageCount) {
        start = start > 0 ? start - 1 : start;
        end = localPageCount;
      }
    }
    setPageRange(_.range(start, end));
  };

  const onClickIndex = (goToIndex: number) => {

    // const first = _.first(pageRange) || 0;
    let localPageCount = Math.ceil(products?.length / productsPerPage);
    applyPageRangeLogic(goToIndex - 1);
    setPageIndex(goToIndex);
    setShowPrevious(goToIndex > 0);
    setShowFirst(goToIndex > 0);
    setShowNext(goToIndex < localPageCount);
    setShowLast(goToIndex < localPageCount);
  };

  const onNext = () => {
    if (!showNext) return;
    let currIdx = pageIndex + 1;
    let localPageCount = Math.ceil(products?.length / productsPerPage);

    console.log(products);

    applyPageRangeLogic(currIdx);
    setShowPrevious(true);
    setShowFirst(true);
    setShowNext(currIdx + 1 < localPageCount);
    setShowLast(currIdx + 1 < localPageCount);
    setPageIndex(pageIndex + 1);
  };


  const onPrevious = async () => {
    if (!showPrevious) return;
    let currIdx = pageIndex - 1;

    let localPageCount = Math.ceil(products?.length / productsPerPage);
    applyPageRangeLogic(currIdx);

    setShowFirst(pageIndex > 1);
    setShowPrevious(pageIndex > 1);
    setShowNext(pageIndex < (localPageCount - 1));
    setShowLast(pageIndex < (localPageCount - 1));
    setPageIndex(pageIndex - 1);
  };

  const onFirst = () => {
    if (!showFirst) return;
    applyPageRangeLogic(0);
    setPageIndex(0);
    setShowPrevious(false);
    setShowFirst(false);
    setShowNext(true);
    setShowLast(true);
  };

  const onLast = () => {
    if (!showLast) return;

    let localPageCount = Math.floor(products?.length / productsPerPage);

    applyPageRangeLogic(localPageCount);
    setPageIndex(localPageCount);
    setShowPrevious(true);
    setShowFirst(true);
    setShowNext(false);
    setShowLast(false);
  };


  const sizes = config.pagination.even ? [
    { label: '8 per page', value: 8 },
    { label: '16 per page', value: 16 },
    { label: '24 per page', value: 24 },
  ]
    : [
      { label: '9 per page', value: 9 },
      { label: '18 per page', value: 18 },
      { label: '27 per page', value: 27 },
    ];


  const coretecCodes = config.coretecCodes;

  //Show the please call message
  const showPleaseCall = (product: any) => {
    let show = false;

    if (!customer?.is_matting) {
      return false;
    }

    for (let i = 0; i < coretecCodes.length; i++) {
      if (product.product_sku.substring(0, coretecCodes[i].length) == coretecCodes[i]) {
        return true;
      }
    }
    return show;
  };

  const onChangePageSize = (size: any) => {
    setProductsPerPage(size.value);
    onClickIndex(0);
  };

  const onClickProduct = (product: Product) => {
    history.push({ pathname: c.APP_ROUTES.PRODUCT_CUT, search: `sku=${product.product_sku || product.IMAGE_code}` });
  };

  const onSearch = debounce((term: string) => {
    const obj = {
      searchSelection: term,
      productTypeCode,
    };
    setProductTypeCode(productTypeCode);
    setSearchSelection(term);
    if (term == '') {
      setSearchSelection("");
    }
  }, 50);

  const onChange = (term: string) => {
    const obj = {
      searchSelection: term,
      productTypeCode,
    };
    setProductTypeCode(productTypeCode);
    setSearchSelection(term);
    if (term == '') {
      setSearchSelection("");
    }
  };


  const goTo = (product: Product) => {
    history.push({ pathname: c.APP_ROUTES.PRODUCT_CUT, search: `sku=${product.product_sku || product.IMAGE_code}` });
  };




  const renderPaging = () => (products?.length > 0 && pageRange.length > 0) && <div className="row gy-5 search-row pb-5">
    <div className="col-md-4">
      <div className="d-flex justify-content-start align-content-center flex-row align-items-center">
        <div className='dd-label'>Results:&nbsp;&nbsp;&nbsp;</div>
        <DropDown items={sizes} value={productsPerPage} onSelect={onChangePageSize} />
      </div>
    </div>
    <div className="col-md-8">
      <div className="lw-pagination">
        {config.pagination.showFirst &&
          <div className={`lw-pagination__control ${!showFirst ? 'disabled' : ''}`} onClick={onFirst}> First </div>
        }
        <div className={`lw-pagination__control ${!showPrevious ? 'disabled' : ''}`} onClick={onPrevious}> {
          config.pagination.prevIcon && <><img src={chevronLeft} /></>
        }
          {
            !config.pagination.prevIcon && <>Previous</>
          }
        </div>
        {pageRange.map((index) => (
          <div
            onClick={() => onClickIndex(index)}
            className={`lw-pagination__pill ${pageIndex === index ? 'active-pill' : ''}`}
            key={index}>
            {index + 1}
          </div>
        ))}
        <div className={`lw-pagination__control ${!showNext ? 'disabled' : ''}`} onClick={onNext}> {
          config.pagination.nextIcon && <><img src={chevronRight} /></>
        }
          {
            !config.pagination.nextIcon && <>Next</>
          } </div>
        {config.pagination.showLast &&
          <div className={`lw-pagination__control ${!showLast ? 'disabled' : ''}`} onClick={onLast}> Last </div>
        }
      </div>
    </div>
  </div>;



  const code = (productTypeCode ? productTypeCode : '') + (customer && customer?.is_matting ? 'MB' : '');




  return (
    <View>
      {
        (code != '' && config.suppressHeaderOnSearch == false) && <>      <PageHeader text={title} imageUrl={getCategoryBannerImage(code)} />
        </>
      }
      {(config.suppressHeaderOnSearch == false && code == '') &&
        <>
          <div className="search page-search actual-search-page">

            <div className="container">

              {config.categories.search.labels.superTitle &&
                <div className="supertitle">
                  <h4>
                    {config.categories.search.labels.superTitle}
                  </h4>
                </div>}
              <h3 className="products__header__title pb-5">
                Showing {products.length} result{products.length > 1 ? 's' : ''} for search '{searchSelection}'
              </h3>
            </div>
          </div></>}
      <div>
        <div>


          <div className="search page-search">
            <div className="container pb-2">
              <SearchBarFilter results={products} onNavigate={goTo} onChange={onChange} onSearch={onSearch} term={searchSelection} styleName="search-page" />
            </div>
          </div>
          <div className="container pb-5">

            <div className="container">
              {fetchingProducts && !fetchingFailed &&
                <div className="row mt-5">
                  <div className="col-12 mb-5 text-center">
                    <div className="spinner-border text-center text-dark" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>

                  </div>
                </div>
              }
              {renderPaging()}
              <div className="row search-row pb-5">
                {_.slice(products, pageIndex * productsPerPage, (pageIndex * productsPerPage) + productsPerPage)?.map((p, i) => (
                  <div key={`${p.quality_full_name}-${i}`} className="col-lg-4 col-md-6">
                    <CatalogueItem
                      onClick={() => onClickProduct(p)}
                      imageCode={p.IMAGE_code}
                      productName={config.showColourNames ? p.quality_full_name + ' ' + p.colour_full_name : p.quality_full_name}
                      productDetails={!config.showColourNames ? p.colour_full_name : ''}
                      price={ 0 }
                      skuCode={p.product_sku}
                      pleaseCall={showPleaseCall(p)}
                      phone={customer?.branch_phone || ''}
                      uom={p.selling_unitofmeasure}
                    />
                  </div>
                ))}
                {!fetchingProducts && !fetchingFailed && products?.length === 0 && <div className="text-center">No records found matching your search.</div>}
              </div>
              {renderPaging()}
            </div>
          </div>
        </div>
      </div>
    </View>
  );
};

export default Search;

import React from 'react';
import './PricelistTable.scss';
import PricelistPacksAndPallets from './../PricelistPacksAndPallets';

type Props = {
    rows: any;
    data: any;
};

const PricelistTable = (props: Props) => {

    const { rows, data } = props;

    let theRows = [...rows];

    const doReplace = (actionData: any, data: any) => {
        if (actionData == null || actionData.length == 0) {
            return '';
        }
        return actionData.split(data[0]).join(data[1]);

    };
    const doFormatCurrency = (actionData: any, data: any) => {
        return actionData;
    };



    const extractData = (column: any, key: any, pricelistRow: any, hideQuality: boolean) => {

        let x = null;
        if (pricelistRow.hasOwnProperty('action')) {
            switch (pricelistRow.action.name) {
                case 'replace':
                   x = doReplace(column[key], pricelistRow.action.data);
                   break;
                case 'formatCurrency':
                    x = doFormatCurrency(column[key], pricelistRow.action.data);
                    break;
            };
        }

        if( x != null ){
            let sp = x.split(' ');;
            let out = ''
            if (sp.length > 2) {
                out = sp[0] +  ' <span>' + sp[1] + '</span> <span>' + sp[2] + '</span>';
            } else if (sp.length > 1) {
                out = sp[0] +  ' <span>' + sp[1] + '</span>';
            } else {
                out = sp[0];    
            }    
            return  out;
        }

        if (Array.isArray(key)) {
            let out: any = [];
            key.forEach(y => {
                if (hideQuality && y == 'qfnam') {
                    return;
                }
                out.push(column[y]);
            });
            return out.join('  ');
        }


        return column[key];
    };

    let isPacksAndPallets = rows.filter((x: any) => x.key == 'packsandpallets');
    isPacksAndPallets = null != isPacksAndPallets && isPacksAndPallets.length > 0;


    let keys = [
        'list-palqty1',
        'list-palqty2',
        'list-palqty3',
        'list-palqty4',
        'best-palqty1',
        'best-palqty2',
        'best-palqty3',
        'best-palqty4',
    ];

    let palletHeadings: any = [];

    //Find how many different pallet quanitites we have 
    let totalPallets: any = [];
    if (data) {
        data.map((x: any) => {
            for (let i = 0; i < keys.length; i++) {

                if (x.hasOwnProperty(keys[i]) && x[keys[i]] > 0 && palletHeadings.indexOf(keys[i]) < 0) {
                    palletHeadings.push(keys[i]);
                }

                if (x.hasOwnProperty(keys[i]) && x[keys[i]] > 0 && totalPallets.indexOf(x[keys[i]]) < 0) {
                    totalPallets.push(x[keys[i]]);
                }
            }
        });
    }

    let totalColumns: any[] = [];
    let cols: any[] = [];

    let palletTitle = '';


    if (isPacksAndPallets) {

        let idx = rows.findIndex((x: any) => x.key == 'packsandpallets');
        let nRows = [...rows];
        theRows = nRows;

        if (idx > -1) {
            cols = [...nRows[idx].options];
            palletTitle = nRows[idx].title;
            theRows.splice(idx, 1);
        }
    }


    let palletHeadingKeys = isPacksAndPallets ? cols : [];



    const renderPalletColumn = (obj: any, column: any) => {


        if (!column.hasOwnProperty(obj.key) || column[obj.key] == '') {
            return '';
        }

        let ppRice = column[obj.key].split(' ');

        let out = ''
        if (ppRice.length > 2) {
            out = ppRice[0] +  ' <span>' + ppRice[1] + '</span> <span>' + ppRice[2] + '</span>';
        } else  if (ppRice.length > 1) {
            out = ppRice[0] + ' <span>' + ppRice[1] + '</span>';
        } else {
            out = ppRice[0];

        }

        return <><span dangerouslySetInnerHTML={{ __html: out }}></span></>
    };



    const splitIntoBuckets = (data: any) => {
        let outObj: any = {};

        if (data == null || data.length == 0) {
            return outObj;
        }

        for (let i = 0; i < data.length; i++) {
            if (!outObj.hasOwnProperty(data[i].qual)) {
                outObj[data[i].qual] = { title: data[i].qfnam, data: [], type: data[i].cType };
                outObj[data[i].qual].data.push(data[i]);
            } else {
                outObj[data[i].qual].data.push(data[i]);
            }
        }
        return outObj;
    };

    let splitData = splitIntoBuckets(data);

    const getRowClass = (obj: any, index: number) => {
     
        if (obj.type == 'D' && obj.data.length > 1) {
            return 'indent';
        }
        if (index == 0) {
            return 'standard';
        }
        return 'standard';

    };


    const renderCols = (cols: any, y: any) => {
      

        if (!cols) {
            return <></>
        }

        let emptyCols = 0;
        let filteredCols = [];

        for (let i = 0; i < cols.length; i++) {
            if (!y.hasOwnProperty(cols[i].key) || y[cols[i].key] == '') {
                emptyCols++;
                continue;
            }
            filteredCols.push(cols[i]);
        }
      
        let colspan = palletHeadingKeys.length - filteredCols.length; 

        if( filteredCols.length == 0 ){
            return <td key={Math.random() * 9999} colSpan={ colspan }></td>
        }


        return filteredCols && filteredCols.map((z: any, index) => (
            <td key={index} colSpan={ colspan }>
                {
                    renderPalletColumn(z, y)
                }
            </td>
        ));
    };

    return (
        <div className='pricelist-table-scroll'>
            <table className="pricelist-table">
                <thead>
                    <tr>
                        {
                            theRows && theRows.map((x: any, index) => (
                                <th dangerouslySetInnerHTML={{ __html: x.title }} key={index} className={x.hasOwnProperty('highlight') ? 'highlight' : ''}>

                                </th>
                            ))
                        }
                        {

                            palletHeadingKeys &&
                            <th colSpan={palletHeadingKeys.length} className='highlight' dangerouslySetInnerHTML={{ __html: palletTitle }}>
                            </th>

                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        splitData && Object.keys(splitData).map((x: any) => (

                            splitData[x].data && splitData[x].data.map((y: any, idx: number) => (
                                <>
                                    {
                                        (idx == 0 && splitData[x].type == 'D' && splitData[x].data.length > 1) &&
                                        <tr className='row-standard'>
                                            <td  colSpan={theRows.length + cols.length}>
                                                <span className='qual-title'>{splitData[x].title}</span>
                                            </td>
                                        </tr>
                                    }


                                    <tr className={'row-' + getRowClass(splitData[x], idx)} key={'row-' + idx}>
                                        {
                                            theRows && theRows.map((z: any, index) => (
                                                <td key={'col' + idx}>
                                                    {
                                                        (z.key != 'packsandpallets') &&
                                                        <span dangerouslySetInnerHTML={{ __html: extractData(y, z.key, z, splitData[x].type == 'D' && splitData[x].data.length > 1) }}></span>
                                                    }
                                                </td>
                                            ))
                                        }

                                        {
                                            renderCols(cols, y)
                                        }
                                   
                                    </tr>
                                </>
                            )
                            )
                        ))
                    }
                </tbody>
            </table>
        </div>
    );
};

export default PricelistTable;

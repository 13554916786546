import React, { useRef, useState } from 'react';
import './ProductCardY.scss';
import TextInput from '../TextInput';
import { Product } from '../../types/Product';
import ProductImage from '../ProductImage';
import { Price } from '../../types/Price';
import { Stock } from '../../types/Stock';
import { ProductType } from '../../types/ProductType';
import _ from 'lodash';
import Button from '../Button';
import BoxSvg from '../../assets/svgs/box-SVG.svg';
import { checkUnitPrice, generateUID, getCustomerID, isAuthenticated } from '../../helpers/functions';
import { OrderLine } from '../../types/Order';
import { lwRoundNumber, roundInteger, roundNumber } from '../../helpers/functions';
import { useDispatch, useSelector } from 'react-redux';
import { addToBasket } from '../../actions/Order';
import { emailNotifyStock } from '../../actions/Email';
import { getCustomer } from '../../selectors/Customer';
import { isSendingEmail } from '../../selectors/Email';
import TooltipLabel from '../TooltipLabel/TooltipLabel';
import ExpectedStockDate from '../ExpectedStockDate';
import ProductImageList from '../ProductImageList';
import CONSTANTS from '../../helpers/constants';
import { useHistory } from 'react-router-dom';
import TooltipViewer from '../TooltipViewer';
import { config } from '../../config/config';
import dateFormat from 'date-fns/format';
import { fetchStock } from '../../actions/Stock';


type Props = {
    product?: Product | null;
    price?: Price | null;
    stock?: Stock | null;
    productType?: ProductType | null;
    similar?: Array<Product> | null;
    onClick?: (arg: string) => void;
    onPopup?: (arg: string) => void;
};

let debounceTimeout: any = 0;

const ProductCardY = (props: Props) => {
    const { product, price, productType, stock, similar } = props;
    const dispatch = useDispatch();
    const customer = useSelector(getCustomer);
    const [currentSQMPacks, setCurrentPacks] = useState(0);
    const [reference, setReference] = useState('');
    const [orderLines, setOrderLines] = useState<OrderLine[]>([]);
    const [emailSent, setEmailSent] = useState(false);
    const [canCheckoutQuantity, setDoCheckoutQuantity] = useState(false);

    const sendingEmail = useSelector(isSendingEmail);
    const history = useHistory();
    const ref = useRef(null);
    const totalQty = stock?.pallet?.find(p => p.pallet_batchno.indexOf('TOTAL') === 0)?.pallet_qty || 0;

    const totalPresoldQuantity = stock?.pallet?.find(p => p.pallet_batchno === 'PRESOLD')?.pallet_qty || 0;

    const overrideUnits = customer?.drop_ship == true

    const onNotify = async () => {
        await dispatch(emailNotifyStock({
            customerName: customer?.NAME || 'N/A',
            customerID: getCustomerID() || 'N/A',
            customerEmail: customer?.contact_email || 'N/A',
            productQuality: product?.quality_full_name || 'N/A',
            productColor: product?.colour_full_name || 'N/A',
            productSKU: product?.product_sku || 'N/A',
            rep_email: customer?.rep_email || 'N/A',
            rep_name: customer?.rep_name || 'N/A',
            branchEmail: customer?.branch_email || ''
        }));
        setEmailSent(true);
        setTimeout(() => {
            setEmailSent(false);
        }, 10000);
    };

    const setCurrentSQMPacks = (val: string) => {

        let theVal = parseFloat(val) < 0 ? 0 : parseFloat(val);
        theVal = isNaN(theVal) ? 0 : theVal;
        
        if (product?.packs_only_qty as number > 0) {
            setDoCheckoutQuantity(parseFloat(val) % (product?.packs_only_qty as number) == 0);
            setCurrentPacks(Math.ceil(lwRoundNumber(theVal)));
            return;
        }

        // @ts-ignore
        setDoCheckoutQuantity(true);
        setCurrentPacks(Math.ceil(lwRoundNumber(theVal)));
    };

    const getPalletAvailability = (isAPresold: boolean) => {
        if (!isAPresold) {
            let x = stock?.pallet?.filter(y => y.pallet_batchno != 'PRESOLD');
            if (null == x || x.length == 0) {
                return '';
            }
            return x[0].pallet_availability;
        }

        let x = stock?.pallet?.filter(y => y.pallet_batchno == 'PRESOLD');
        if (null == x || x.length == 0) {
            return '';
        }
        return x[0].pallet_availability;
    };

    const onAddPackToOrderSummary = () => {
        const localOrderLines = [...orderLines];
        if (product && price && currentSQMPacks) {

            let isPresold = currentSQMPacks > getNonPresoldStock();
            let estimated = getPalletEstimatedDeliveryDate();


            localOrderLines.push({
                uid: generateUID(product.product_sku, product.quality_full_name, reference),
                product_sku: product.product_sku,
                product_quality: product.quality_full_name,
                product_type_code: product.product_type_code,
                product_type_code_name: productType != undefined && productType != null ? productType.product_type_desc : '',
                quantity: currentSQMPacks,
                pallet_id: '',
                half_roll: '',
                roll_id: '',
                length: 0,
                width: 0,
                item_price: pricePerUnit,
                line_reference: reference,
                line_price: pricePerUnit * currentSQMPacks,
                line_discount: price.cut_price_discount,
                colour_full_name: product.colour_full_name,
                line_availability: getPalletAvailability(isPresold),
                line_delivery_date: '',
                collection: false,
                carriage_charge_linit: price.carriage_charge_linit,
                carriage_charge_units: price.carriage_charge_units,
                carriage_charge_coir: price.carriage_charge_coir,
                presold: isPresold,
                cart_date: isPresold ? (estimated != 'TBA' && estimated != '' ? 'Earliest Estimated ' + estimated : estimated) : ''
            });
            //setReference('');
            setCurrentSQMPacks('');
            setOrderLines(localOrderLines);
        }
    };


    const onAddOrderSummaryToBasketAndContinue = () => {
        dispatch(addToBasket(orderLines, customer?.delivery_address_code, false, false));

        const el2 = ref.current as any;
        const styles = getComputedStyle(el2);
        const z = parseInt(styles.zIndex);

        if (z == 2) {
            history.push(CONSTANTS.APP_ROUTES.CART);
        } else {
            if (null != props.onPopup) {
                props.onPopup('You have added ' + orderLines.length + ' products to your basket');
            }
        }
        //Clear the summary 
        setOrderLines([]);

        setTimeout(() => {
            dispatch(fetchStock(product ? product.product_sku : ''));
        }, 1000);
    };

    const onMinus = (index: number) => {
        let localOrderLines = [...orderLines];
        localOrderLines = localOrderLines.filter((or, i) => i !== index);
        setOrderLines(localOrderLines);
    };

    const canOrderQuantity = () => {

        if (overrideUnits) {
            return true;
        }

        if (canCheckoutQuantity) {
            return true;
        }

        if (currentSQMPacks == 0) {
            return true;
        }
        return false;
    }
    const [activeTooltip, setActiveTooltip] = useState<string>('');

    const toggleToolTip = (name: string, direction: boolean) => {
        if (!direction) {
            setActiveTooltip('');
            return;
        }
        setActiveTooltip(name);
    };

    const getToggleState = (name: string) => {
        return name == activeTooltip;
    };

    const removeTooltip = () => {
        setActiveTooltip('');
    };
    const getPalletStatus = () => {
        let x = stock?.pallet?.filter(x => x.pallet_batchno != 'PRESOLD').length;


        if (x && x > 0) {

            let xTotal = 0;
            stock?.pallet?.filter(x => x.pallet_batchno != 'PRESOLD').forEach(z => {
                xTotal += z.pallet_qty;
            });

            if (product && xTotal < product?.packs_only_qty) {
                return 'Out of Stock';
            }

            return 'In Stock';
        }

        if (config.enablePresold) {
            let y = stock?.pallet?.filter(z => z.pallet_batchno == 'PRESOLD').length;

            let xTotal = 0;
            stock?.pallet?.filter(x => x.pallet_batchno == 'PRESOLD').forEach(z => {
                xTotal += z.pallet_qty;
            });

            if (product && xTotal < product?.packs_only_qty) {
                return 'Out of Stock';
            }
            if (y && y > 0 && config.enablePresold) {
                return 'Pre-Order';
            }
        }

        return 'Out of Stock';
    };


    const hasPresoldStock = () => {
        if (!config.enablePresold) {
            return false;
        }
        let presold = stock?.pallet?.filter(p => p.pallet_batchno == 'PRESOLD');
        return presold && presold.length > 0;
    };

    /* Get the expected stock date from the stock */
    const getExpectedDate = (theStock: any) => {
        if (!theStock) {
            return null;
        }

        let presolledPallets = theStock.pallet && theStock.pallet.filter((x: any) => x.pallet_batchno == 'PRESOLD');

        if (null == presolledPallets || presolledPallets.length == 0) {
            if (theStock.stock_expected_date && theStock.stock_expected_date != '2049-12-25') {
                return theStock.stock_expected_date;
            }
            return null;
        }

        return presolledPallets[0].pallet_expected_stock_date;
    };





    const getLongestPallet = () => {

        let longest = 0;
        let filteredPallets = stock?.pallet?.filter(y => y.pallet_batchno.indexOf('TOTAL') === -1);

        if (filteredPallets == null) {
            let stockTotal = stock?.pallet?.filter(y => y.pallet_batchno.indexOf('TOTAL') === 0);
            if (stockTotal == null) {
                return 0;
            }
            return stockTotal[0].pallet_qty;
        }




        filteredPallets.map(x => {
            if (x.pallet_qty > longest) {
                longest = x.pallet_qty;
            }
        });

        return longest;
    };

    const getTotalPresold = () => {
        let total = 0;
        stock?.pallet?.map(x => {
            if (x.pallet_batchno == 'PRESOLD') {
                total += x.pallet_qty;
            }
        });
        return total;
    };

    const checkExceedsPallet = () => {
        return false;
    };

    //Calculate the remaining after a subtraction -- can't be negative 
    const calculateRemaning = (inNumber: number) => {
        let x = inNumber < 0 ? 0 : inNumber;
        let tmp = ('' + x).split('.')
        if (tmp.length > 1) {
            let decimal = parseFloat('0.' + tmp[1]).toFixed(1);
            return parseFloat(tmp[0]) + decimal;
        }
        return x;
    };

    const totalUsedPresold = () => {
        let total = 0;
        orderLines.forEach(c => {
            if (c.product_sku === product?.product_sku && c.presold == true) {
                total += c.quantity;
            }
        });
        return total;
    };

    //Work out how many packs we have selected in total
    const workOutCutPieces = () => {
        let lengthList: any = [];
        orderLines.forEach(c => {
            if (c.product_sku === product?.product_sku) {
                lengthList.push(c.quantity);
            }
        });
        return lengthList;
    };


    //Get the estimated delivery date for a cut 
    const getPalletEstimatedDeliveryDate = (): string => {

        if (!config.enablePresold) {
            return '';
        }

        //Search the rolls for the current width
        let x = stock?.pallet;

        if (null == x) {
            return 'TBA';
        }

        //let total  
        let total = currentSQMPacks;
        let counter = 0;


        //How many presold rolls are we cutting into ? 
        let cutPieces = workOutCutPieces();

        let theRemainingPieces: any = [];

        x.forEach(y => {
            theRemainingPieces.push({ ...y });
        });

        //Go over the cut pieces and assign them to a roll
        cutPieces.map((piece: any) => {
            //Loop over the rolls 
            for (let i = 0; i < theRemainingPieces.length; i++) {
                if (theRemainingPieces[i] - piece > 0) {
                    theRemainingPieces[i].pallet_qty -= piece;
                    break;
                }
            }
        });


        for (let i = 0; i < theRemainingPieces.length; i++) {
            if (theRemainingPieces[i].pallet_qty >= total) {
                //This one is the date we need
                if (theRemainingPieces[i].pallet_batchno == 'PRESOLD') {

                    let dt = new Date(theRemainingPieces[i].pallet_expected_stock_date || '2022-01-01');
                    let dDate = theRemainingPieces[i].pallet_expected_stock_date != null ? dateFormat(dt, 'dd.MM.yyy') : '';
                    if (dDate == '2049-12-25') {
                        dDate = 'TBA';
                    }
                    return dDate;
                }
                return stock && stock.delivery_date.length > 0 ? dateFormat(new Date(stock.delivery_date[0].delivery_date), 'dd.MM.yyy') : 'TBA';
            }
        }
        return 'TBA';
    };


    const getNonPresoldStock = () => {
        let total = 0;
        orderLines.forEach(c => {
            if (c.product_sku === product?.product_sku && c.presold == false) {
                total += c.quantity;
            }
        });

        let inStockTotal = stock?.pallet?.filter(y => y.pallet_batchno != 'PRESOLD');

        if (!inStockTotal) {
            return 0;
        }

        let totalQty = 0;
        inStockTotal.map(x => {
            totalQty += x.pallet_qty;
        });

        return totalQty - total;
    };


    const getTotalNonPresold = () => {

        let inStockTotal = stock?.pallet?.filter(y => y.pallet_batchno != 'PRESOLD');

        if (!inStockTotal) {
            return 0;
        }

        let totalQty = 0;
        inStockTotal.map(x => {
            totalQty += x.pallet_qty;
        });

        return totalQty;
    };
    const checkExeedsTotal = () => {
        let total = 0;
        orderLines.forEach(c => {
            if (c.product_sku === product?.product_sku) {
                total += c.quantity;
            }
        });

        let inStockTotal = stock?.pallet?.filter(y => y.pallet_batchno != 'PRESOLD');

        if (!inStockTotal) {
            return true;
        }

        let totalQty = 0;
        inStockTotal.map(x => {
            totalQty += x.pallet_qty;
        });


        return ((total + currentSQMPacks) > totalQty);
    };


    const getExceedsAvailable = () => {
        let total = 0;
        orderLines.forEach(c => {
            if (c.product_sku === product?.product_sku) {
                total += c.quantity;
            }
        });
        let inStockTotal = stock?.pallet;

        if (!inStockTotal) {
            return true;
        }

        let totalQty = 0;
        inStockTotal.map(x => {
            totalQty += x.pallet_qty;
        });

        return ((total + currentSQMPacks) > totalQty);
    };

    const getAllStock = () => {
        return getTotalNonPresold() + getTotalPresold();
    }

    const checkIsAPallet = () => {
        let lp = getLongestPallet();
        return false;
        return currentSQMPacks == lp && currentSQMPacks > 0;
    };

    const getUsedPacks = () => {
        let total = 0;
        orderLines.forEach(c => {
            if (c.product_sku === product?.product_sku) {
                total += c.quantity;
            }
        });
        return total;
    };


    const exceedsPallet = checkExceedsPallet() && config.enablePresold;
    const totalPresold = getTotalPresold();
    const showPredorderNotice = (checkExeedsTotal() && currentSQMPacks > 0 && (totalPresold - totalUsedPresold() > 0));
    const isAPallet = checkIsAPallet() && config.enablePresold;
    const exceedsAvailableStock = getExceedsAvailable();

    const disableAddPacks = (!currentSQMPacks || reference.length <= 0 || !canOrderQuantity()) || (stock?.stock_availablity != 'yes' && !hasPresoldStock()) || exceedsAvailableStock && (getAllStock() - getUsedPacks() <= 0);
    let ordersummaryText = "Add To Order Summary";
    if (showPredorderNotice) {
        ordersummaryText = "Add Pre-Order to Summary";
    }


    const pricePerUnit = checkUnitPrice(currentSQMPacks, price);

    return (
        <div className="product-card-ex" onClick={removeTooltip}>
            <div className='row'>
                <div className='col-md-6 product-card-image hide-mob'>
                    <ProductImage className={'product__image'} imageCode={product?.IMAGE_code} skuCode={product?.product_sku} imageAlt={product?.colour_full_name} size='large' />
                </div>
                <div className='col-md-6'>

                    <div className="product-card-ex__header">
                        <div className="product-card-ex__header__img">
                            <div className="product-card-ex__header__img__name">
                                <p className="product-card-ex__header__img__name__top">
                                    {product?.quality_full_name.toLocaleLowerCase()} {product?.colour_full_name.toLowerCase()}
                                </p>
                                <p className="product-card-ex__header__img__name__bottom small text-grey">
                                    {_.startCase(_.toLower(productType?.product_type_desc.toLowerCase()))}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="product-card-ex__pricing product-card__pricing">
                        <h3>Pricing &amp; Availability</h3>
                        <div className='product-card__availability_row'>
                            <div>
                                <div className='text-orange product-card-ex__pricing__header product-card__pricing__header'>
                                    Available Units
                                </div>
                                <div className="product-card__pricing__content">
                                    <div className="product-card__pricing__prices">
                                        <div className='product-card-ex__pricing__price'>
                                            Price Per Unit: &pound; {pricePerUnit.toFixed(2)}
                                        </div>
                                    </div>
                                    <div className='product-card-ex__pricing__availability product-card__pricing__availability'>
                                        <div className='d-flex justify-content-end align-items-center'>
                                            {(getPalletStatus() == 'In Stock') &&
                                                <span className='badge badge-pill'>In Stock</span>
                                            }
                                            {((getPalletStatus() == 'Out of Stock') && hasPresoldStock() == false) &&
                                                <>
                                                    <span className='badge badge-pill out-of-stock'>Out of Stock</span>
                                                    <TooltipViewer onClick={(direction: boolean) => toggleToolTip('pallet', direction)} toggleState={getToggleState('pallet')} side='right' tipTitle='Out of Stock' tipText='Please contact our Customer Service Team for more information on expected arrival of new stock'></TooltipViewer>
                                                </>
                                            }
                                            {((getPalletStatus() == 'Pre-Order') && hasPresoldStock()) && <>
                                                <span className='badge badge-pill pre-order'>Pre-Order</span>
                                                <TooltipViewer onClick={(direction: boolean) => toggleToolTip('pallet', direction)} toggleState={getToggleState('pallet')} side='right' tipTitle='Pre Order' tipText='We are currently awaiting stock to arrive from the manufacturers. Your order will be despatched shortly after the date displayed'></TooltipViewer></>
                                            }

                                        </div>
                                    </div>
                                </div>
                                <div className="product-card__pricing__view_availability">
                                    {stock?.stock_availablity != 'yes' &&
                                        <div className='product-card-ex__pricing__availability product-card__pricing__availability'>
                                            <div className="d-flex justify-content-end stock-date">
                                                <ExpectedStockDate stock={getExpectedDate(stock)} showPipe={false} />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='product-card-image hide-desktop'>
                        <ProductImage className={'product__image'} imageCode={product?.IMAGE_code} skuCode={product?.product_sku} imageAlt={product?.colour_full_name} size='large' />
                    </div>

                    <div className='product-card-ex__colours'>
                        <ProductImageList similar={similar} current={product} onClick={props.onClick} />
                    </div>

                </div>
            </div>

            <div className='row'>
                <div className='col-md-6'>

                    {/*<!-- PACKS -->*/}
                    <div className="product-card-ex__accordion mt-3">
                        <div className="product-card-ex__accordion__header">
                            <div className="product-card-ex__accordion__header__title">
                                {product?.quality_full_name} {product?.colour_full_name}
                            </div>

                            <div className="product-card__price__right">
                                <div className='d-flex justify-content-end align-items-center'>
                                    <div className="product-card__price__right__details">
                                        <span className="text-small white">
                                            {getPalletStatus()}
                                        </span>
                                    </div>

                                    {getPalletStatus() == 'In Stock' && <div className="green-circle" />}
                                    {getPalletStatus() == 'Pre-Order' && <div className="amber-circle" />}
                                    {getPalletStatus() == 'Out of Stock' && <div className="red-circle" />}

                                </div>
                            </div>
                        </div>

                        <div className="product-card-ex__accordion__body">

                            <div className='inner'>
                                {(getPalletStatus() == 'Out of Stock') && <div className="row mb-4">
                                    <div className='pre-order'>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <Button disabled={sendingEmail || emailSent} type="link" text="Notify When Back In Stock" onClick={onNotify} />
                                            </div>
                                        </div>
                                    </div>

                                    {emailSent && <div className="alert alert-success" role="alert">We have registered your interest!</div>}
                                </div>}

                                {(getPalletStatus() != 'Out of Stock') &&
                                    <div className="row align-items-end">

                                        <div className="col-md-7">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className='d-flex justify-content-between align-items-end calculation'>
                                                        <div className='calculation__left'>
                                                            {
                                                                product && product?.product_template == 'Units' &&
                                                                <TooltipLabel text="Units" tipText="Sold in single units" />
                                                            }
                                                            {
                                                                (product?.product_template != 'Units' && (product?.product_group == 'TRIMS' || (product && product?.packs_only_qty as number > 0))) &&
                                                                <TooltipLabel text="QTY" tipText="Sold in single length pieces" />
                                                            }
                                                            {
                                                                (product?.product_template != 'Units' && (product?.product_group != 'TRIMS' && (!product || product?.packs_only_qty as number <= 0))) &&
                                                                <TooltipLabel text="Packs" tipText="Based on your SQM to nearest full pack" />
                                                            }
                                                            <div className="mt-2">
                                                                <TextInput type="number" bgColor="white" onChange={setCurrentSQMPacks} value={roundInteger(currentSQMPacks)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-5'>
                                            <div className='mobile-cut-summary'>
                                                <div><span className='small text-grey calculation--label'>Price Each</span><span className='cut-area'>£{roundNumber((price?.unit_price || 0))}</span></div>
                                                <div><span className='small text-grey calculation--label'>{config.showAvailableRollsInline != true ? 'Subtotal:' : 'Total:'}</span><span className='cut-subtotal text-orange'>£{roundNumber((currentSQMPacks || 0) * (price?.unit_price || 0))}</span></div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {getPalletStatus() != 'Out of Stock' &&
                                    <div>

                                        {(exceedsPallet == true && !exceedsAvailableStock) && <div className="mb-3 mt-3 alert alert-danger" role="alert">
                                            Your order exceeds our standard pallet quantity of {getLongestPallet()}. Please select a full pallet from the pallet section below and select the balance separately.
                                        </div>
                                        }

                                        {(isAPallet == true) && <div className="mb-3 mt-3 alert alert-danger" role="alert">
                                            Your order quantity equals a full pallet. Please place your order using the pallet section below
                                        </div>
                                        }


                                        {(getAllStock() - getUsedPacks() <= 0 && currentSQMPacks > 0) && <div className="mb-3 mt-3 alert alert-danger" role="alert">
                                            There are no available units. We are currently unable to fulfil this request.
                                        </div>
                                        }

                                        {!canOrderQuantity() && <div className="mb-3 mt-3 alert alert-danger" role="alert">This product may only be ordered in multiples of {product?.packs_only_qty}</div>}
                                        {
                                            (showPredorderNotice && !exceedsAvailableStock && config.enablePresold) && <div className="mb-3 mt-3 alert alert-warning" role="alert">{
                                                <span className='pre-order-stock'>Pre-order stock available: {calculateRemaning(totalPresold - totalUsedPresold())} units. Earliest estimated delivery date: {getPalletEstimatedDeliveryDate()}. </span>
                                            }
                                            </div>
                                        }

                                        {(exceedsAvailableStock == true && (getAllStock() - getUsedPacks()) > 0) && <div className="mb-3 mt-3 alert alert-danger" role="alert">
                                            Max available units: {getAllStock() - getUsedPacks()}
                                        </div>
                                        }


                                    </div>
                                }
                                {getPalletStatus() != 'Out of Stock' && <div className="row mt-5">
                                    <TextInput bgColor="white" required label="Reference" value={reference} onChange={setReference} />
                                </div>}

                                <div className="row mt-5">
                                    {getPalletStatus() != 'Out of Stock' &&
                                        <Button text={ordersummaryText} disabled={disableAddPacks}
                                            onClick={onAddPackToOrderSummary} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>


                    {/*<!-- ORDER SUMMARY -->*/}
                    <div className="product-card-ex__accordion mt-3">
                        <div className="product-card-ex__accordion__header">
                            <div className="product-card-ex__accordion__header__title">
                                Order Summary
                            </div>
                        </div>
                        <div className="product-card-ex__accordion__body">
                            <div className='inner'>

                                {orderLines.map((o, i) => <div key={i} className="mt-2 mb-4 product-card-ex__summary__row">

                                    <div onClick={() => onMinus(i)} className="text-grey remove-action">
                                        &times;
                                    </div>
                                    <div className='product-card__summary__status'>
                                        {
                                            o.presold && <span className="text-grey text-small"><span className="amber-circle" ></span><span>Pre-Order</span></span>
                                        }
                                        {
                                            !o.presold && <span className="text-grey text-small hide-desktop"><span className="green-circle" ></span><span>In Stock</span></span>
                                        }
                                    </div>
                                    <div className='product-card-ex__summary__col product-card__summary__col__title'>
                                        <span className='product-card-ex__summary__col__title__name'>
                                            {o.product_quality.toLowerCase()}
                                        </span>
                                        <span className='product-card__summary__col__colour'>
                                            <span className='text-grey'>
                                                {o.colour_full_name?.toLowerCase()}
                                            </span>
                                        </span>
                                    </div>
                                    <div className='product-card-ex__summary__col product-card-ex__summary__reference'>
                                        <span className="text-grey text-small">Reference:</span>
                                        <span className='summary-content-text'>{o.line_reference || 'N/A'}</span>
                                    </div>
                                    <div className='product-card-ex__summary__col product-card-ex__summary__col__subtotal'>
                                        <span className="text-grey text-small">Subtotal:</span>
                                        <span className='text-orange total'>£{roundNumber(o.line_price)}</span>
                                    </div>

                                    <div className='product-card-ex__summary__col product-card-ex__summary__delivery'>
                                        <div className="text-grey text-small">Delivery</div>
                                        {
                                            ((!o.presold && o.line_availability) || (o.presold == true)) &&

                                            <div className="d-flex w-100">
                                                <div className='w-100'>
                                                    <img src={BoxSvg} alt="Delivery" />
                                                    {
                                                        (!o.presold && o.line_availability) && <span className='summary-content-text'>{o.line_availability.replace('Delivery ', '')}</span>
                                                    }
                                                    {
                                                        (o.presold == true) && <span className='summary-content-text'>{o.cart_date}</span>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className='product-card-ex__summary__col product-card-ex__summary__width'>
                                        <div className="text-grey text-small">Price (per unit)</div>
                                        <div className="text-small summary-content-text">£{roundNumber(o?.item_price || 0)}</div>
                                    </div>
                                    <div className='product-card-ex__summary__col product-card-ex__summary__length'>
                                        <div className="text-grey text-small">Qty</div>
                                        <div className="small-grey summary-content-text">{o?.quantity || 0}</div>
                                    </div>
                                </div>,
                                )}

                                <div className="row mt-4">
                                    <Button text="Add to Basket" disabled={orderLines.length <= 0}
                                        onClick={onAddOrderSummaryToBasketAndContinue} />
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <div id="z-indexer" ref={ref}></div>
        </div>
    );
}
    ;

export default ProductCardY;

import React, { useEffect, useState } from 'react';
import '../styles/IndividualProduct.scss';
import View from '../components/View';
import ProductCard from '../components/ProductCard';
import { useQuery } from '../helpers/functions';
import { useDispatch, useSelector } from 'react-redux';
import { getProduct, getProducts } from '../selectors/Product';
import history from '../helpers/history';
import c from '../helpers/constants';
import { fetchProduct, fetchProducts, setProduct } from '../actions/Product';
import { fetchProductTypes } from '../actions/ProductType';
import { fetchPrice } from '../actions/Price';
import { getPrice } from '../selectors/Price';
import { fetchStock } from '../actions/Stock';
import { getStock } from '../selectors/Stock';
import { getProductTypes } from '../selectors/ProductType';
import ProductCardEx from '../components/ProductCardEx';
import ProductCardY from '../components/ProductCardY';
import CONSTANTS from '../helpers/constants';
import { NavLink } from 'react-router-dom';
import PopupBasket from '../components/PopupBasket';
import ProductCardUnitsPallets from '../components/ProductCardUnitsPallets';
import { config } from '../config/config';
import { filter } from 'lodash';

const IndividualProducts = () => {

  const query = useQuery();
  const dispatch = useDispatch();
  const [sku, setSku] = useState(query.get('sku'));
  const [clearancePath, setClearancePath] = useState(query.get('c') == '1');

  const [fetchedOnce, setFetchedOnce] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const current = useSelector(getProduct);
  const similar = useSelector(getProducts);
  const price = useSelector(getPrice);
  const stock = useSelector(getStock);
  const productTypes = useSelector(getProductTypes);

  const clearance = config.hasOwnProperty('enableSpecials') ? config.enableSpecials : false;


  const isPacksPallets = current && (current?.product_type_code === 'L' || current?.product_type_code === 'N' || current?.product_type_code === 'T');
  const isCarpets = current && (current?.product_type_code === 'C' || current?.product_type_code === 'V' || current?.product_type_code === 'G' || current?.product_type_code === 'E' || current?.product_type_code === 'Q');
  const isOther = !isCarpets && !isPacksPallets;

  useEffect(() => {
    if (sku) {
      const c = query.get('c');
      dispatch(fetchProduct(sku, c ? c : ""));
      dispatch(fetchProductTypes());
    }
  }, [dispatch]);

  useEffect(() => {

    let clearanceFlag = clearance && query.get('c')  ? query.get('c') : ''; 

    if (current && !fetchedOnce && current?.product_sku.substring(0, sku?.length) == sku) {
      dispatch(fetchProducts(current?.product_type_code, undefined, current?.quality_full_name, clearanceFlag ? clearanceFlag : ''));
      setFetchedOnce(true);
    }

    if (current) {
      dispatch(fetchPrice(current.product_sku));
      dispatch(fetchStock(current.product_sku));
    }
  }, [current, sku]);




  const changeProduct = (code: string) => {
    setSku(code);
    let and = '';

    if (clearancePath) {
      and = '&c=1';
    }

    history.push({ pathname: `${c.APP_ROUTES.PRODUCT_CUT}`, search: `?sku=${code}${and}` });
    const prod = similar?.find(s => s.product_sku === code);
    if (prod) {
      dispatch(setProduct(prod));
      dispatch(fetchPrice(prod.product_sku));
      dispatch(fetchStock(prod.product_sku));
      console.log(prod.product_sku);
    }
  };

  const changePopup = () => {
    setShowPopup(true);
  };

  const dismissPopup = () => {
    setShowPopup(false);
  };

  const productType = productTypes.find(pt => pt.product_type_code === current?.product_type_code);

  //Units
  //Rolls-Cuts
  //Packs-Pallets
  //Units-Pallets

  const filteredSimilar = clearancePath == true ? (similar.filter(x => x.clearance == 'C' || x.clearance == 'S') || []) : similar;

  filteredSimilar.sort((a, b) => {
    if (a.clearance > b.clearance) return -1;
    if (a.clearance < b.clearance) return 1;

    if (a.colour_full_name > b.colour_full_name) return 1;
    if (a.colour_full_name < b.colour_full_name) return -1;

    return 0;
  });

  return (
    <View>
      {current != null ?
        <>
          <div className='breadcumb'>
            <div className='container'>
              <ul>
                <li><NavLink exact={true} to={CONSTANTS.APP_ROUTES.DASHBOARD}>Home</NavLink></li>
                <li><span>&rsaquo;</span></li>
                {
                  (!clearance || current.clearance == '') &&
                  <li><NavLink exact={true} to={CONSTANTS.APP_ROUTES.SEARCH + '?productTypeCode=' + productType?.product_type_code}>{productType?.product_type_desc.toLowerCase()}</NavLink></li>
                }
                {
                  (clearance && current.clearance != '') &&
                  <li><NavLink exact={true} to={CONSTANTS.APP_ROUTES.CLEARANCE}>Clearance</NavLink></li>
                }
                <li><span>&rsaquo;</span></li>
                <li><NavLink exact={true} to={CONSTANTS.APP_ROUTES.PRODUCT_CUT + '?sku=' + current.product_sku}>{current.quality_full_name.toLocaleLowerCase()}</NavLink></li>
              </ul>
            </div>
          </div>
          <div className="container pb-5">
            <div className="g-5">
              {current && current.product_template == 'Rolls-Cuts' && <ProductCard product={current} stock={stock} price={price} productType={productType} similar={filteredSimilar} onClick={changeProduct} onPopup={changePopup} />}
              {current && current.product_template == 'Units' && <ProductCardY product={current} stock={stock} price={price} productType={productType} similar={filteredSimilar} onClick={changeProduct} onPopup={changePopup} />}
              {current && current.product_template == 'Packs-Pallets' && <ProductCardEx product={current} stock={stock} price={price} productType={productType} similar={filteredSimilar} onClick={changeProduct} onPopup={changePopup} />}
              {current && current.product_template == 'Units-Pallets' && <ProductCardUnitsPallets product={current} stock={stock} price={price} productType={productType} similar={filteredSimilar} onClick={changeProduct} onPopup={changePopup} />}
            </div>
          </div>
          {showPopup &&
            <PopupBasket onClick={dismissPopup}></PopupBasket>
          }
        </>
        : ''
      }
    </View>
  );
};

export default IndividualProducts;

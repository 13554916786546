import React from 'react';
import './App.scss';
import Router from '../Router';
import useGtag, { usePageViews } from '../../hooks/gtag';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavigateFunctionComponent from '../NavigateFunction';
import { generateSessionID, getUid, setUid } from '../../helpers/functions';
const App = () => {
  useGtag();
  usePageViews();

  let uid = getUid();
  if (uid == '') {
    uid = generateSessionID();
    setUid(uid);
  }


  return (
    <div className='App'>
      <NavigateFunctionComponent />
      <Router />
      <ToastContainer />
    </div>
  );
};

export default App;

import { result } from 'lodash';
import React from 'react';
import { Product } from '../../types/Product';
import './AutoSuggest.scss';

const AutoSuggest = (props: {
  results: Product[] | null;
  doClick: (arg: Product) => void;
  onMouseOut: () => void;
  onMouseOver: () => void;
}
) => {

  const {
    results,
    doClick = () => { },
    onMouseOut = () => {},
    onMouseOver = () => {}
  } = props;

  let theResults: Product[] = []; 

  if (results) {
    results.sort((a: Product, b: Product) => {
      let argA = `${a.quality_full_name}${a.colour_full_name}`.toLowerCase();
      let argB = `${b.quality_full_name}${b.colour_full_name}`.toLowerCase();

      if (argA < argB) {
        return -1;
      }
      if (argA == argB) {
        return 0;
      }
      return 1;
    });

    theResults = [...results].slice(0,  50);
  }


  return (
    <>
      {
        (results && theResults.length > 0) &&
        <div className='auto-suggest' onMouseLeave={ onMouseOut } onMouseEnter={ onMouseOver }>
          {
            theResults.map((x) =>
              <div className='auto-suggest-item' onClick={() => doClick(x)}>
                {x.quality_full_name} {x.colour_full_name}
              </div>
            )
          }
        </div>
      }
    </>
  );
};

export default AutoSuggest;

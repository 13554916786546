


import React from 'react';
import './ProductImageList.scss';
import { Product } from '../../types/Product';
import ProductImage from '../ProductImage/ProductImage';
import OrangeCircleEmpty from './../../assets/svgs/orange-circle-empty.svg';
import { config } from '../../config/config';

type Props = {
  similar?: Array<Product> | null;
  current?: Product | null;
  onClick?: (arg: string) => void;
};

const ProductImageList = (props: Props) => {
  const {
    similar,
    current,
  } = props;

  const clearance = config.hasOwnProperty('enableSpecials') ? config.enableSpecials : false;

  const onClick = (e: string) => {
    props.onClick?.(e);
  };

  if (null != similar && config.orderAtoZ == true) {
    similar.sort((a: Product, b: Product) => {
      if (a.quality_full_name > b.quality_full_name) {
        return 1;
      }
      if (a.quality_full_name < b.quality_full_name) {
        return -1;
      }
      return 0;
    });
  }

  return (
    <>
      <div className="image--list">

        <div className='image--list__selected'><span className='bold'>Selected Colour:</span><span>{current?.colour_full_name}</span></div>

        <div className='image--list__scroll'>
          {similar?.map(s => (
            <div key={s.product_sku} onClick={() => onClick(s.product_sku)} className={s.product_sku == current?.product_sku ? 'image--list__row mb-2 image--list__row__active' : 'image--list__row mb-2'}>
              <div className="image--list__product d-flex justify-content-start align-items-center">
                <div className='image--list__left__dot'>
                  <img src={s.product_sku == current?.product_sku ? config.orangeCircle : OrangeCircleEmpty} alt="Orange Circle" />
                </div>
                <div className='image--list__image mr-3'>
                  <ProductImage imageCode={s.IMAGE_code} skuCode={s.product_sku} imageAlt={s.colour_full_name} size='small' />
                </div>
                <div className='image--list__name mr-3'>{s.colour_full_name.toLowerCase()}</div>

                {
                  clearance && s.clearance != '' &&
                  <div className='image--list__clearance'><span className='badge badge-clearance'>Clearance &amp; Specials</span></div>
                }

                <div className='image--list__row__selected'><span className='small-text text-grey'>{s.product_sku == current?.product_sku ? 'selected' : ''}</span></div>
              </div>
            </div>
          ))}
        </div>
      </div>        </>
  );
};

export default ProductImageList;

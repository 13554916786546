export const privacyText = `<p class="pb-4">
    <b>Why do we have this privacy notice?</b>
    <br/>
    We are Likewise Group Plc and treating individuals and their personal information with respect
    reflects our core values and the values of our brand(s). So, we want you to know as much as possible
    about what we do with your personal information. Also, you and your personal information are
    protected by various laws and guidance and Likewise Group Plc is committed to upholding these and
    respecting your privacy and keeping your information safe. So, whilst this privacy notice is quite
    long, we want you to be fully informed.
    <br/>
    In this privacy notice any reference to &quot;Likewise Group&quot;, &quot;us&quot;, &quot;we&quot;, &quot;our&quot; or &quot;ourselves&quot; is a
    reference to Likewise Group Plc, and the particular part of the Likewise group that you have a
    relationship with and any reference to &quot;you&quot;, &quot;your&quot; and &quot;yourself&quot; is a reference to you as an
    individual who has a relationship with us or is in contact with us.
    <br/>
    This privacy notice applies to everyone who interacts with us as a customer who has purchased any of
    our products or services. anyone who has signed up to receive marketing materials from us, anyone
    who enters any of our promotions/competitions, except to the extent that the reason you interact
    with us is already covered by another of our privacy notice(s). For example, our Rest of the World
    privacy notice will apply to your general use of our website.
    <br/>
    Please note that we have a separate privacy notice that relates to personal information captured by
    our CCTV and Access Control systems. A copy can be found at www.likewiseplc.com. We also have a
    separate Recruitment privacy notice that applies generally to individuals who apply to work for us,
    a copy of which can be found at www.likewiseplc.com. Finally, we have a separate Rest of the World
    privacy notice that applies to any other individual that may interact with us, a copy of which can
    be found at www.likewiseplc.com. You should also read these privacy notices to the extent that they
    will apply to your activities as they may apply to you in addition to this privacy notice.
    <br/>
    This privacy notice provides details in accordance with data protection laws about how we collect
    and use personal information about you during and after your relationship with us.
    <br/>
    As this privacy notice covers a range of individuals and different types of relationships and
    interactions with us, not all aspects of this privacy notice may apply to you depending upon the
    nature of your relationship and interactions with us. If you are unsure then you can always ask us
    by contacting DCO@likewiseplc.com.
</p>

<p class="pb-4">
    <b>The controller of your personal information
    </b>
    <br/>
    For the purposes of data protection laws and this privacy notice, we are the controller of your
    personal information for the processing of your personal information. Being a controller of your
    personal information means that we are responsible for deciding how we hold and use your personal
    information. Our corporate details are Likewise Group Plc (registered number 08010067) which is
    incorporated in England and Wales with its registered office at Unit 4 Radial Park, Solihull
    Parkway, Birmingham Business Park, Solihull, England, B37 7YN. If you have any queries regarding
    your personal information will be dealt by DCO at DCO@likewiseplc.com.
</p>
<p class="pb-4">
    <b>Your duty to inform us of changes</b>
    <br/>
    It is important that the personal information we hold about you is accurate and current. Please keep
    us informed if your personal information changes during the period of your interactions with us.
</p>
<p class="pb-4">
    <b>What if you do not provide personal information?</b>
    <br/>
    Failing to provide some of the personal information we require may have an adverse impact on our
    ability to interact with you, for example we may not be able to provide you with products or
    services you would like to receive. However generally you are not obliged to provide us with any of
    your personal information.
</p>
<p class="pb-4">
    <b>If you have queries or concerns just ask!</b>
    <br/>
    We are not required to appoint a data protection officer to oversee our compliance with the data
    protection laws, however, we have appointed a data compliance officer (DCO) to do this. If you have
    any questions about this privacy notice or how we handle your personal information, please contact
    our DCO on DCO@likewiseplc.com.
</p>
<p class="pb-4">
    <b>Changes to this notice</b>
    <br/>
    We keep our privacy notice under regular review, and we may update this privacy notice at any time.
    The current version of this notice is available on our website at www.likewiseplc.com or by
    requesting a copy from DCO@likewiseplc.com. If there are any material changes to this privacy notice
    in the future, we will let you know, usually by updating the version on our website, and we may also
    email you to let you know.
</p>
<p class="pb-4">
    <b>Changes to this notice</b>
    <br/>
    We keep our privacy notice under regular review, and we may update this privacy notice at any time.
    The current version of this notice is available on our website at www.likewiseplc.com or by
    requesting a copy from DCO@likewiseplc.com. If there are any material changes to this privacy notice
    in the future, we will let you know, usually by updating the version on our website, and we may also
    email you to let you know.
</p>
<p class="pb-4">
    <b>Data protection principles
    </b>
    <br/>
    We are committed to being transparent about how we collect and use your personal information and in
    meeting our data protection obligations. Data protection laws say that the personal information we
    hold about you must be:
    - Used lawfully, fairly and in a transparent way.
    - Collected only for valid purposes that we have clearly explained to you and not used in any way
    that is incompatible with those purposes.
    - Relevant to the purposes we have told you about and limited only to those purposes.
    - Accurate and kept up to date.
    - Kept only as long as necessary for the purposes we have told you about.
    - Kept securely.
    To make sure this happens we are required under data protection laws to notify you of the
    information contained in this privacy notice. It is important that you read this document before you
    begin interacting with us so that you understand how and why we will process your personal
    information.
</p>
<p class="pb-4">
    <b>What personal information do we collect?</b><br/>
    In connection with your relationship or interactions with us, we may collect and process a wide
    range of personal information about you.<br/> This includes:<br/>
    Personal contact details such as name, title, address (including billing address and delivery
    address), email address and telephone number(s).<br/>
    Information about your date of birth, age, gender, marital status, and the name of any delivery
    recipient.<br/>
    Details regarding or connected to products or services that you have ordered from us.
    Device data where you use website which may include information about the device you use and the
    unique device identifier for example your device’s IMEA number, the MAC address of the device’s
    wireless network interface, or the mobile phone used by the device, mobile network information, your
    mobile operating system, the type of mobile browser you use, time zone setting, the IP address,
    device type, usernames and account details, location data which may include your current location
    disclosed by your own software. However, we do not use separate location tracking software.
    Profile data including your username, purchase history, your interests, preferences, feedback and
    responses and any inferences drawn from any of personal data to create a profile about you to
    reflect your preferences, characteristics, psychological trends, predispositions, behaviour,
    attitudes, intelligence, abilities, and aptitudes.<br/>
    Payment details, payment card details, bank account details, financial transactions, and refunds.<br/>
    Any terms and conditions relating to your relationship with us.<br/>
    Any communications between ourselves and you.<br/>
    Your social media handles, social media posts, information about your social media followers,
    information about any product/services endorsements by you and other aspects of your social media
    activity.<br/>
    Publicly available personal information, including any which you have shared via a public platform,
    online or on social media.<br/>
    Responses and results of surveys.<br/>
    Fraud prevention related information which may include details of other transactions you have been
    involved in.<br/>
    How you use our website as we collect information about the pages you look at and how you use them,
    usernames, account details and passwords, entry and exit data when you look at or leave our website,
    details of products, and materials that may be of interest to you, online subscription information,
    for example, when you subscribe to one of our updates, blogs or other materials, browser related
    information, cookies that are set on your device by our website (for more details see our separate
    Cookie Policy at www.likewiseplc.com).<br/>
    Your usage of the IT systems we make available to visitors to our premises such as any visitor
    internet facilities at our premises.<br/>
    IP address information which allows us to track your usage of our website.<br/>
    Identification information including your driving license and/or passport and background checks.
    Vehicle registration number make and model if you are driving to visit us at our premises or an
    event.<br/>
    Details of any queries, complaints, claims, and cases involving both us and you including any
    related communications.<br/>
    CCTV footage and other information obtained through electronic means such as swipe card records and
    access control systems if you visit our premises (see our separate CCTV and Access Control privacy
    notice at www.likewiseplc.com).<br/>
    Photographs, video footage, audio recordings and other content, for example any created as part of
    our marketing or promotion campaigns, when you enter a competition, posts made on social media or
    when you are on our premises or which you provide to us.<br/>
    Any other personal information you provide to us.<br/>
    We may also in some cases collect and process more sensitive special category personal information
    including:<br/>
    In some cases, equal opportunities monitoring information, including information about your ethnic
    origin, sexual orientation, and religion or beliefs. This will usually only be where it is relevant
    to promotions, campaigns, or other activities that may involve you.<br/>
    In very rare cases political opinions, religious or philosophical beliefs, trade union membership,
    genetic data, biometric data, data concerning sex life or sexual orientation may be processed for
    other purposes, but this will usually only be where you provide it to us, it is relevant to a
    business relationship with us, or it relates to a legal claim in some way.<br/>
    In very rare cases where it is relevant, we may also collect criminal records information about you,
    for example an offence committed by you or alleged to have been committed by you that impacts on
    your relationship with us.<br/>
    If you are providing us with details of any other individuals, they have a right to know and to be
    aware of what personal information we hold about them, how we collect it and how we use and may
    share that information. Please share this privacy notice with them. They also have the same rights
    as set out in this privacy notice in relation to their personal information that we collect.<br/>
    We aim not to collect personal information about children<br/>
    Our supply of products or services, our website, promotions, social media, content, blogs,
    materials, and other services we provide are not intended for use by anyone under the age of 18
    years and generally we do not knowingly collect personal information relating to anyone under the
    age of 18 years old unless for some reason you provide it to us.<br/>
    However, we may in some cases collect limited personal information related to children if they are
    connected to someone who is 18 or older whom we have a relationship with, for example a child who
    may attend our premises when accompanied by a responsible adult who is entitled to attend one of our
    premises.<br/>
</p>
<p class="pb-4">
    <b>Where do we collect your personal information from?</b><br/>
    We collect your personal information in a variety of ways and from a variety of sources as set out
    below:<br/>
    Most of your personal information is collected directly from you, for example through contact with
    you, through information you input into your account on our website, from orders placed by you, from
    correspondence with you, through your applications, entries to competitions/promotions, from
    correspondence with you or through other interactions with us, when you visit our premises or other
    personal information you provide to us.<br/>
    From other individuals known to you who may have given us your personal information so that we can
    send you any of our products as a gift.<br/>
    From websites, the internet, social media, or other platforms including public sources of
    information.<br/>
    From our website, and information technology and communications systems, access control systems and
    CCTV and suppliers we use in connection with them.<br/>
    From third parties appointed by you, for example any financial or legal advisors.<br/>
    From third parties appointed by us, for example legal advisors appointed by us, identity or
    background check providers, fraud prevention organisations, data cleansing service providers or
    market/data research and analysis service providers.<br/>
    From government or government related bodies, regulators, the police, law enforcement authorities or
    the security services.<br/>
    </p>
    <p class="pb-4">
        <b>What are our bases for processing your personal information?</b><br/>
    We will only use your personal information when the law allows us to. This means we must have one or
    more legal bases to use your personal information. Most of these will be self-explanatory. The most
    common legal bases which will apply to our use of your personal information are set out below:<br/>
    Where we need to perform the contract, we have entered into with you which covers your relationship
    with us or to take steps to enter into that contract.<br/>
    Where we need to comply with a legal obligation which applies to us, for example complying with laws
    relating to the sale of products to consumers or complying with data protection laws.<br/>
    Where it is necessary for legitimate interests pursued by us or a third party and your interests and
    fundamental rights do not override those interests. We have set out in the section below how we use
    your personal information together with more details on our legitimate interests.<br/>
    You have given your consent. Generally, we do not rely on or need your consent for most uses we make
    of your personal information, but we will need your consent to directly market our products and
    services to you by electronic communications channels such as email or SMS/MMS.<br/>
    Where we are processing any sensitive special category personal information about you (for example
    personal information revealing racial or ethnic origin, religious or philosophical beliefs) we also
    need to have one or more of the following legal bases for using your personal information.<br/>
    Where we have your explicit consent to do so.<br/>
    Where it is necessary for us to comply with our obligations and exercising our rights in the field
    of employment law, social security law and social protection law.<br/>
    Where we need to protect your vital interests (or someone else&quot;s vital interests).<br/>
    Where you have already made public the personal information.<br/>
    In establishing, exercising, or defending legal claims, whether those claims are against us or by
    us.<br/>
    Where it is necessary in the public interest.<br/>
    In some cases, more than one legal bases may apply to our use of your personal information.<br/>
</p>
<p class="pb-4"><b>How will we use your personal information?</b><br/>
    There are many ways we will need to use your personal information in the context of your
    relationship with us. We have set out the main uses below and indicated the main applicable legal
    bases of processing, but there may be other specific uses which are linked to or covered by the uses
    below.<br/>
    We will process your personal information to perform our relationship with you. As well as relating
    to the entry into or performance of a contract with you either directly or indirectly, this will
    also be in our legitimate interests. We may also in some limited cases rely on your consent.<br/>
    We will process your personal information to handle any order you (or an individual known to you
    where the delivery is to be made to you) has placed with us, and to pick, pack, despatch, ship and
    track that order to make sure it arrives safely. As well as relating to the entry into or
    performance of a contract with you either directly or indirectly, this will also be in our
    legitimate interests. We may also in some limited cases rely on your consent.<br/>
    We will process your personal information to provide any services you have asked to receive from us.
    As well as relating to the entry into or performance of a contract with you either directly or
    indirectly, this will also be in our legitimate interests. We may also in some limited cases rely on
    your consent.<br/>
    We will need to process your personal information to send to you any direct marketing materials
    about our products or services that you have asked to be sent to you. As well as relating to the
    entry into or performance of a contract with you either directly or indirectly, this will also be in
    our legitimate interests. We also need you to give consent if we are going to market to you by
    electronic channels such as email or SMS/MMS.<br/>
    We will process your personal information to build and develop a profile for you as a customer or
    potential customer of our products, services, and to aim to send or show you content, advertisements
    or marketing materials which are most likely to be of interest to you. As well as relating to the
    entry into or performance of a contract with you either directly or indirectly, this will also be in
    our legitimate interests. We also need you to give consent if we are going to market to you by
    electronic channels such as email or SMS/MMS.<br/>
    We also need to monitor and manage our relationship with you, which may involve communications with
    you, decisions regarding your relationship with us and in some cases meeting with you. As well as
    relating to the entry into or performance of a contract with you either directly or indirectly, this
    will also be in our legitimate interests.<br/>
    We may need to process your personal information in order to hold or conduct promotions, or
    campaigns. This will be in our legitimate interests, and in some cases, we may rely on your consent
    to do this, for example if you provide us with a photograph to use.<br/>
    We may need to process your personal information to manage our social media or online relationship
    with you. This will be in our legitimate interests, and in some cases, we may rely on your consent
    to do this, for example if you provide us with a video, photograph or content to use.<br/>
    We may need to process your personal information in order to hold or conduct, promotions, campaigns,
    and visits to our premises and manage your involvement in them. This may relate to the entry into or
    performance of a contract with you either directly or indirectly, it may also be in our legitimate
    interests or we may have a legal obligation to do so.<br/>
    We may need to process your personal information to help train our staff, and make sure they deliver
    the high standards expected in relation to our brand. This will be in our legitimate interests.
    In some cases, we may need to carry out background, identity, fraud prevention or other checks in
    relation to you to decide whether to enter into or to enforce a relationship we have with you. This
    will be in our legitimate interests, and in some cases, we may have a legal obligation to do so.<br/>
    As a business we may have many legal obligations connected to our relationship with you or connected
    to visiting our premises which we need to comply with, for example, to comply with consumer
    protection laws or to comply with data protection laws or to comply with health and safety laws so
    we can ensure it is safe for you to visit our premises.<br/>
    We will also need to keep and maintain proper records relating to your relationship with us and
    information about you which is relevant to that relationship. As well as relating to the entry into
    or performance of a contract with you either directly or indirectly, this will also be in our
    legitimate interests, and we may also have legal obligations to do this.<br/>
    In some cases, we may need to process your personal information to prevent, detect or prosecute
    criminal activity. This will also be in our legitimate interests; we may also have legal obligations
    or be exercising a legal right to do this, and it will also be in the public interest.<br/>
    You may have contacted us about a query, compliant or enquiry and we need to be able to respond to
    you and deal with the points you have raised. This will also be in our legitimate interests; we may
    also have legal obligations or be exercising a legal right to do this.<br/>
    We may need to gather evidence for and be involved in possible legal cases. As well as relating to
    the entry into of a contract with you either directly or indirectly, this will also be in our
    legitimate interests, we may also have legal obligations or be exercising a legal right to do this
    and it may also be needed to establish, bring, or defend legal claims.<br/>
    To manage and keep a record of our relationship with you and any associated information. It may
    relate to the entry into or performance of a contract with you either directly or indirectly, it
    will also be in our legitimate interests, and we may also have legal obligations or be exercising a
    legal right to do this.<br/>
    To ensure effective general business administration and to manage our business. As well as relating
    to the entry into or performance of a contract with you either directly or indirectly, this will
    also be in our legitimate interests, and we may also have legal obligations or be exercising a legal
    right to do this.<br/>
    To monitor any use, you make of our website, and information and communication systems and social
    media accounts to ensure network and information security, including preventing unauthorised access
    to our computer and electronic communications systems and preventing malicious software distribution
    and to protect your personal information. As well as relating to the entry into or performance of a
    contract with you either directly or indirectly, this will also be in our legitimate interests, and
    we may also have legal obligations or be exercising a legal right to do this. In relation to social
    media, you may also have already made the personal information public.<br/>
    To conduct data analytics and analysis studies to review and better understand trends and improve
    our business, use of our website, and social media which relates to us, and those same things in
    relation to our competitors. This will also be in our legitimate interests, and we may also have
    legal obligations or be exercising a legal right to do this. We may sometimes anonymise and
    aggregate personal information for insight and research purposes, but this information will not
    identify you.<br/>
    We may be carrying our market and/or product research, for example so that we can improve our
    offering and range of products or improve our use of our website, or social media. This will be in
    our legitimate interests.<br/>
    We always aim to use your personal information in an ethical and non-intrusive way. You are a
    customer or potential customer of ours and your happiness as a customer or potential customer is
    very important to us. We will not use your personal data to target, segment, or profile individuals
    based on their health (including pregnancy), negative financial status or condition, political
    affiliation or beliefs, racial or ethnic origin, religious or philosophical affiliation or beliefs,
    sex life or sexual orientation, data relating to an alleged or actual commission of a crime, for any
    unlawful or discriminatory purpose or in any other manner that would be inconsistent with your
    reasonable expectation of privacy.<br/>
</p>
<p class="pb-4"><b>Changing Marketing Preferences</b><br/>
    You have the right to opt out of receiving marketing communications from us at any time by:
    Updating your preferences in your account on our website.<br/>
    Informing us that you wish to change your marketing preferences by contacting our customer support
    team at DCO@likewiseplc.com.<br/>
    Making use of the simple “unsubscribe” link in emails or any other electronic marketing materials we
    send to you.<br/>
    Contacting us via email at DCO@likewiseplc.com or by post to Likewise Group Plc, Unit 4, Radial
    Park, Solihull Parkway, Birmingham B37 7YN.<br/>
    This will not stop service messages such as order updates and other non-marketing communications
    from us. It will also not affect advertising that may appear on our website or other websites.
    Please see below section entitled &quot;Automated decision-making&quot; for more information on how we use
    cookies to advertise to you.<br/>
</p>
<p class="pb-4"><b>Change of purpose</b><br/>
    We will only use your personal information for the purposes for which we collected it, unless we
    reasonably consider that we need to use it for another reason and that reason is compatible with the
    original purpose. If we need to use your personal information for an unrelated purpose, we will
    notify you by updating this privacy notice on our website, so please check back regularly for any
    updates.<br/>
    Please note that we may process your personal information without your knowledge or consent, in
    compliance with the above rules, where this is required or permitted by law. We will rarely need to
    rely on your consent to process any of your personal information.<br/>
</p>
<p class="pb-4"><b>Automated decision-making</b><br/>
    Automated decision-making takes place when an electronic system uses personal information to make a
    decision about that person without any human intervention which produces legal effects concerning
    them or similarly significantly affects them. We do not currently use this type of automated
    decision-making in our business in relation to you.<br/>
    You will not be subject to decisions that will have a significant impact on you based solely on
    automated decision-making unless we have a lawful basis for doing so and we have notified you.
    However, we do use automated processing so that we can show you personalised advertisements whilst
    browsing our website or those of other companies and to build a customer profile for you. Any
    advertisements you see may relate to your browsing activity on our website from your computer or
    other devices.<br/>
    These advertisements are provided by us via external market leading specialist providers using
    techniques such as pixels, web beacons, ad tags, mobile identifiers and ‘cookies’ placed on your
    computer or other devices (see further information on the use of cookies in our Cookie Policy at
    www.likewiseplc.com. You can remove or disable cookies at any time - see www.likewiseplc.com for
    further information.<br/>
    We may analyse your browsing and purchasing activity online and your responses to marketing
    communications. The results of this analysis, together with other demographic data, allow us to
    decide what advertisements are suitable for you and to ensure that we draw to your attention
    products, services, and offers that are tailored and relevant to you. To do so, we use software and
    other technology for automated processing. This allows us to provide a more personalised services
    and experience.<br/>
    We may review personal information held about you by external social media platform providers, such
    as the personal information available on social media platforms such as Twitter, Instagram, YouTube,
    Twitter, and Facebook.<br/>
    We aim to update you about products and services which are of interest and relevance to you as an
    individual. To help us do this, we process personal data by profiling and segmenting, identifying
    what our customers like and ensuring advertisements we show you are more relevant based on
    demographics, interests, purchase behaviour, online web browsing activity and engagement with
    previous communications.<br/>
</p>
<p class="pb-4"><b>Who has internal access to your personal information?</b><br/>
    Your personal information may be shared internally with our staff, including with our customer
    support, order fulfilment, loyalty and retention, customer relationship management, media, insights,
    campaign, technical and legal teams where access to your personal information is necessary for the
    performance of their roles. We only provide access to your personal information to those of our
    staff who need to have access to your personal information.
</p>
<p class="pb-4"><b>Who do we share your personal information with externally?</b><br/>
    When using your personal information, we may share it with third parties, but we will only do so
    when it is appropriate, and we have a legal basis for doing so. Third parties that we may share your
    personal information with include:<br/>
    Any third party approved by you.<br/>
    Service or product providers to our business, for example information technology services suppliers,
    logistics and warehousing providers, delivery and shipping providers, fraud prevention
    organisations, marketing and public relations service providers, market research and analysis
    providers, data cleansing providers.<br/>
    Third parties that process personal information on our behalf and in accordance with our
    instructions.<br/>
    Another company within our group of companies, especially if you have a relationship with that part
    of our group.<br/>
    Purchasers, investors, funders, and their advisers if we sell all or part of our business, assets or
    shares or restructure whether by merger, re-organisation or in another way.
    Our legal and other professional advisers, including our auditors or any professional advisors
    appointed by you, for example a legal advisor.<br/>
    Social media and other online platforms where relevant to our relationship with you.
    Governmental bodies, HMRC, regulators, police, law enforcement agencies, security services,
    courts/tribunals.<br/>
    We also use Google Analytics which sets cookies to collect information about how visitors use our
    website. See our Cookie Policy at www.likewiseplc.com. We use the information to compile reports and
    to help us improve the website. The cookies collect information in an anonymous form, including the
    number of visitors to the website and blog, where visitors have come to the website from and the
    pages they visited. To opt out of being tracked by Google Analytics across all websites visit
    http://tools.google.com/dlpage/gaoptout.<br/>
    We do not disclose personal information to anyone else except as set out above unless we are legally
    entitled to do so. We may provide third parties with aggregate statistical information and analytics
    about users of our products and services, but we will make sure no one can be identified from this
    information before we disclose it.<br/>
</p>
<p class="pb-4">
    <b>International transfers</b><br/>
    It is sometimes necessary to share your personal information outside of the UK and the European
    Economic Area (the EEA) or it will be collected outside of the UK and the EEA. This will typically
    occur when service providers to our business are located outside the EEA or if you are based outside
    the EEA. These transfers are subject to special rules under data protection laws.<br/>
    The same applies to any transfer of personal information to another part of our group of companies
    based outside of the UK and the EEA. We also apply the same standards to any transfer of personal
    information between members of our group, regardless of where the group company is based.<br/>
    If we transfer your personal information outside of the UK and the EEA, we will ensure that the
    transfer will be compliant with data protection laws and all personal information will be secure.
    Our standard practice is to assess the laws and practices of the destination country and relevant
    service provider and the security measures that are to be taken as regards the personal Information
    in the overseas location; alternatively, we use standard data protection clauses. This means that
    when a transfer such as this takes place, you can expect a similar degree of protection in respect
    of your personal information.<br/>
    Our directors and other key staff working for us may in limited circumstances access personal
    information from outside of the UK and EEA if they are on holiday abroad outside of the UK or EEA.
    If they do so they will be using our security measures and the same legal protections will apply
    that would apply to accessing personal information from our premises in the UK.<br/>
    In limited circumstances the people to whom we may disclose personal information may be located
    outside of the UK and EEA and we will not have an existing relationship with them, for example a
    foreign police force. In these cases, we will impose any legally required protections to the
    personal information as required by law before it is disclosed.<br/>
    Also, if you are based outside of the UK and EEA, then your personal data may be held and used
    outside of the UK and EEA anyway, but in most cases as described at the start the controller of your
    personal information will be Likewise Group Plc in the UK.<br/>
    If you would like any more details about how we protect your personal information in relation to
    international transfers, then please contact our DCO at DCO@likewiseplc.com.<br/>
</p>
<p class="pb-4"><b>How do we protect your personal information?</b><br/>
    We are committed to keeping your personal information safe and secure and so we have numerous
    security measures in place to protect against the loss, misuse, and alteration of information under
    our control. Our security measures include:<br/>
    Encryption of personal information where appropriate.<br/>
    Regular cyber security assessments of all service providers who may handle your personal
    information.<br/>
    Regular planning and assessments to ensure we are ready to respond to cyber security attacks and
    data security incidents.<br/>
    Regular penetration testing of systems.<br/>
    Security controls which protect our information technology systems infrastructure and our premises
    from external attack and unauthorised access.<br/>
    Aiming to use security systems implemented across our networks and hardware to ensure access and
    information are protected.<br/>
    Regular backups of information technology systems data with functionality to correct errors or
    accidental deletion/modification to data.<br/>
    Internal policies setting out our information security rules for our staff.<br/>
    Regular training for our staff to ensure staff understand the appropriate use and processing of
    personal information.<br/>
    Where we engage third parties to process personal information on our behalf, they do so on the basis
    of our written instructions, they are under a duty of confidentiality and are obliged to implement
    appropriate technical and organisational measures to ensure the security of personal information.
    We take information security very seriously and will use all reasonable endeavours to protect the
    integrity and security of the personal information we collect about you.<br/>
</p>
<p class="pb-4"><b>How long do we keep your personal information?</b><br/>
    We will hold your personal information for the duration of your relationship with us and then
    usually for a further period. We you are a customer this will usually be for up to 6 years after you
    last purchased or ordered any products or services from us. However, if you have only signed up to
    receive marketing, and you have never ordered or purchased anything from us, then we would not
    generally retain your personal information for that long, and usually we will only retain it for 2
    years after you last used any account you have with us or confirmed you wish to continue to receive
    direct marketing from us. Whichever time period normally applies, in some cases we may need to keep
    your personal information for longer, for example if it is still relevant to a dispute or legal case
    or claim.<br/>
    We will not retain your personal information for longer than necessary for the purposes for which it
    was collected, and it is being used. We do not guarantee to retain your personal information for the
    whole of the periods set out above; they are usually the maximum period, and in some cases, we may
    keep your personal information for a much shorter period.<br/>
    For more information, please see our Data Retention Policy which can be obtained from our DCO at
    DCO@likewiseplc.com.<br/>
</p>
<p class="pb-4"><b>Your rights</b><br/>
    As an individual whose personal information we collect and process, you have a number of rights. You
    may:<br/>
    Withdraw any consent you have given to us, although this will only be relevant where we are relying
    on your consent as a basis to use your personal information, but it is an absolute right. Once we
    have received notification that you have withdrawn your consent, we will no longer process your
    personal information for the purpose or purposes for which you originally gave your consent, unless
    we have another legal basis for doing so.<br/>
    Request details about how your personal information is being used. This right is linked with the
    right of access mentioned below.<br/>
    Request access and obtain details of your personal information that we hold (this is commonly known
    as a “data subject access request”). This enables you to receive a copy of the personal information
    we hold about you and to check that we are lawfully processing it.<br/>
    Request correction of the personal information that we hold about you. This enables you to have any
    incomplete or inaccurate information we hold about you corrected.<br/>
    Request erasure of your personal information. This means that you can ask us to delete or stop
    processing your personal information, for example where we no longer have a reason to process it.
    You also have the right to ask us to delete or remove your personal information where you have
    exercised your right to object to processing (set out below). The right to have data erased does not
    apply in all circumstances.<br/>
    Object to the processing of your personal information where we are relying on a legitimate interest
    (ours or that of a third party) and there is something about your particular situation which makes
    you want to object to processing on this ground.<br/>
    Object to direct marketing where we are processing your personal information for direct marketing
    purposes, for example contacting you about products that might interest you. This is an absolute
    right.<br/>
    Request the restriction of processing of your personal information. This enables you to ask us to
    stop processing your personal information for a period if data is inaccurate or there is a dispute
    about whether or not your interests override our legitimate grounds for processing data.
    Request the transfer of your personal information to another party in certain circumstances.<br/>
    Object to certain automated decision-making processes using your personal information.<br/>
    You should note that some of these rights, for example the right to require us to transfer your
    personal information to another service provider or the right to object to automated
    decision-making, may not always apply as they have specific requirements and exemptions which apply
    to them and they may not apply to personal information recorded and stored by us. Also, for example
    we do not use automated decision-making in relation to your personal information which has legal or
    other significant effects for you, but we do use automated processing to show you relevant
    advertisements. However, some of your rights have no conditions attached, so your right to withdraw
    consent or object to processing for direct marketing are absolute rights.<br/>
    If you would like to exercise any of these rights, please contact our DCO at DCO@likewiseplc.com.
    We may need to request specific information from you to help us confirm your identity and ensure
    your right to access the information (or to exercise any of your other rights). This is another
    appropriate security measure to ensure that personal information is not disclosed to any person or
    dealt with by a person who has no right to do so.<br/>
    Whilst this privacy notice sets out a general summary of your legal rights in respect of personal
    information, this is a complex area of law. More information about your legal rights can be found on
    the ICO’s website at https://ico.org.uk/for-the-public/.<br/>
</p>
<p class="pb-4"><b>Complaints</b><br/>
    We hope you don’t have any reason to complain, and we will always try to resolve any issues you
    have, but you always have the right to make a complaint at any time to the ICO if you are based in
    the UK about how we deal with your personal information or your rights in relation to your personal
    information. If you are based outside of the UK, you may have the right to complain to your local
    data protection regulator.<br/>
    You can make a compliant in writing to the ICO, Wycliffe House, Water Lane, Wilmslow, SK9 5AF,
    United Kingdom or you can go to https://ico.org.uk/make-a-complaint/.<br/>
</p>
<p class="pb-4"><b>Contacting us</b>
    If you have any queries regarding our use of your personal information or this privacy notice then
    please contact our DCO at DCO@likewiseplc.com or write to DCO, Likewise Group Plc, Unit 4, Radial
    Parkway, Solihull Parkway, Birmingham, B37 7YN. You can use these details regardless of which of our
    group companies you are working for or used to work for.
</p>`;
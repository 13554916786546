import axios from 'axios';
import c from '../helpers/constants';
import { EmailDeleteAccount, EmailForgotPassword, EmailMessage, EmailNewPassword, EmailNotifyStock, EmailUpdateDetails } from '../types/Email';

export function submitMessage(data: EmailMessage) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.EMAIL_SUBMIT_MESSAGE}`,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(data),
    })
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function forgotPassword(data: EmailForgotPassword) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.EMAIL_FORGOT_PASSWORD}`,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(data),
    })
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function newPassword(data: EmailNewPassword) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.EMAIL_NEW_PASSWORD}`,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(data),
    })
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function notifyStock(data: EmailNotifyStock) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.EMAIL_NOTIFY_STOCK}`,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(data),
    })
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function deleteAccount(data: EmailDeleteAccount) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.EMAIL_DELETE_ACCOUNT}`,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(data),
    })
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function updateDetails(data: EmailUpdateDetails) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.EMAIL_UPDATE_DETAILS}`,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(data),
    })
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}
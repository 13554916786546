import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import _ from 'lodash';
import {
  FETCH_PRODUCTS_FAILURE,
  FETCH_PRODUCTS_RESPONSE,
  FETCH_PRODUCTS_REQUEST,
  Product,
  ProductActionTypes, FETCH_PRODUCT_FAILURE, FETCH_PRODUCT_RESPONSE, FETCH_PRODUCT_REQUEST,
} from '../types/Product';

import { RootState } from '../store/configureStore';
import { getCustomerID, isAuthenticated } from '../helpers/functions';
import { getProduct, getProducts } from '../api/ProductApi';
import history from '../helpers/history';
import c from '../helpers/constants';

export function fetchProductsRequest(): ProductActionTypes {
  return {
    type: FETCH_PRODUCTS_REQUEST,
    payload: null,
  };
}

export function fetchProductsResponse(
  products: Product[],
): ProductActionTypes {
  return {
    type: FETCH_PRODUCTS_RESPONSE,
    payload: products,
  };
}

export function fetchProductsFailure(): ProductActionTypes {
  return {
    type: FETCH_PRODUCTS_FAILURE,
    payload: null,
  };
}

export function fetchProductRequest(): ProductActionTypes {
  return {
    type: FETCH_PRODUCT_REQUEST,
    payload: null,
  };
}

export function fetchProductResponse(
  product?: Product | null,
): ProductActionTypes {
  return {
    type: FETCH_PRODUCT_RESPONSE,
    payload: product,
  };
}

export function fetchProductFailure(): ProductActionTypes {
  return {
    type: FETCH_PRODUCT_FAILURE,
    payload: null,
  };
}

export const fetchProducts = (productTypeCode?: string, searchSelection?: string, searchQuality?: string, clearance?: string ):
  ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchProductsRequest());
    if (!isAuthenticated()) {
      history.push(c.APP_ROUTES.LOGIN);
    }
    const finalCustomerId = getCustomerID() || '';
    const asyncResp: any = await getProducts(finalCustomerId, productTypeCode, searchSelection, searchQuality, undefined, clearance);
    if (asyncResp?.return_code === 'OK') {
      await dispatch(fetchProductsResponse(asyncResp?.dsProductDetails?.['op-tt-prod']));
    } else {
      await dispatch(fetchProductsFailure());
    }
  };
  
export const clearProducts = ():
  ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchProductsRequest());
    await dispatch(fetchProductsResponse([]));
  };

export const fetchProduct = (sku: string, clearance?: string ):
  ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchProductRequest());
    if (!isAuthenticated()) {
      history.push(c.APP_ROUTES.LOGIN);
    }
    const finalCustomerId = getCustomerID() || '';
    const asyncResp: any = await getProduct(finalCustomerId, sku, clearance);

    if (asyncResp?.return_code === 'OK' && asyncResp?.return_items === 1) {
      await dispatch(fetchProductResponse(_.first(asyncResp?.dsProductDetails?.['op-tt-prod'] || [])));
    } else if (asyncResp.return_code == 'OK' && asyncResp.return_items > 1) {
      await dispatch(fetchProductResponse(_.first(asyncResp?.dsProductDetails?.['op-tt-prod'] || [])));
    } else {
      await dispatch(fetchProductFailure());
    }
  };

export const setProduct = (product: Product):
  ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    await dispatch(fetchProductResponse(product));
  };

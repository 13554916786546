import {
  FETCH_STATEMENTS_REQUEST,
  FETCH_STATEMENTS_RESPONSE,
  FETCH_STATEMENTS_FAILURE,
  StatementState,
  StatementActionTypes,
} from '../types/Statement';

const initialState: StatementState = {
  statements: [],
  isFetchingStatements: false,
  fetchingStatementsFailed: false,
};

const statementReducer = (
  state = initialState,
  action: StatementActionTypes,
): StatementState => {
  switch (action.type) {
    case FETCH_STATEMENTS_REQUEST:
      return {
        ...state,
        statements: [],
        isFetchingStatements: true,
        fetchingStatementsFailed: false,
      };
    case FETCH_STATEMENTS_RESPONSE:
      return {
        ...state,
        statements: action.payload || [],
        isFetchingStatements: false,
        fetchingStatementsFailed: false,
      };
    case FETCH_STATEMENTS_FAILURE:
      return {
        ...state,
        statements: [],
        isFetchingStatements: false,
        fetchingStatementsFailed: true,
      };
    default:
      return state;
  }
};

export default statementReducer;
import React from 'react';
import './MultiChoice.scss';
import OrangeCircleEmpty from './../../assets/svgs/orange-circle-empty.svg';
import { config } from '../../config/config';
import ExpectedStockDate from '../ExpectedStockDate';
import dateFormat from 'date-fns/format';

type PropsRolls = {
  price: number;
  batchId: string;
  selected: boolean;
  length: number;
  width: number;
  onClick: () => void;
  expectedDate: string;
  delivery: string;
  stock?: string;
  poDate?: string;
};

type PropsPallets = {
  price: number;
  palletQty: number;
  palletId: string;
  selected: boolean;
  delivery: string;
  inStock: boolean;
  expected?: string;
  stock?: string;
  poDate?: string;
  onClick: () => void;
};

const Pallets = (props: PropsPallets) => {
  const {
    palletQty,
    price,
    palletId,
    delivery,
    inStock,
    expected,
    stock,
    poDate,
    onClick,
  } = props;


  const stockVal = stock == 'PRESOLD' && !config.enablePresold ? 'OUTOFSTOCK' : stock;
  let dDate = delivery?.replace('Delivery ', '').replace('Next', 'Next\n');

  if( stock == 'PRESOLD' && (dDate == '' || dDate == null) ){
    let dt = new Date(poDate || '2022-01-01');
    dDate = poDate != null ? 'Estimated ' + dateFormat( dt, 'dd.MM.yyy') : ''; 
    if( poDate == '2049-12-25'){
      dDate = 'TBA';
    }
  }

  return (


    
    <div className='inner'>
      <div className="table--row" onClick={onClick}>
        <div className='table--row__col table--row__title__option'>
          <div className="multi-choice__left__dot mr-2">
            <img src={props.selected ? config.orangeCircle : OrangeCircleEmpty} alt="Orange Circle" />
          </div>
        </div>
        <div className='table--row__col table--row__title__batch'>
          <span className='grey-text table--row__title'>Pallet ID</span>
          <span>{stock}</span>
        </div>
        <div className='table--row__col table--row__title__length'>
          <span className='grey-text table--row__title'>Qty</span>
          <span>{palletQty}</span>
        </div>
        <div className='table--row__col table--row__title__price'>
          <span className='grey-text table--row__title'>Price Per Pack</span>
          <span>£{price.toFixed(2)}</span>
        </div>
        <div className='table--row__col table--row__title__delivery'>
          <span className='grey-text table--row__title'>Delivery</span>
          <span>{ dDate }</span>
        </div>
        <div className='table--row__col table--row__title__stock'>
          <span className='grey-text table--row__title'>Stock</span>
          <span>
            <span className='d-flex justify-content-start align-items-center'>
              {stockVal == 'PRESOLD' && <><span className="amber-circle" /><span>Pre-Order</span></>}
              {stockVal != 'PRESOLD' && <><span className="green-circle" /><span>In Stock</span></>}
            </span>
          </span>
        </div>
      </div>
    </div>

  );
};

const Rolls = (props: PropsRolls) => {
  const {
    price,
    batchId,
    length,
    width,
    delivery,
    stock,
    poDate,
    onClick,
  } = props;

  let dDate = delivery?.replace('Delivery ', '').replace('Next', 'Next\n');
  const stockVal = stock == 'PRESOLD' && !config.enablePresold ? 'OUTOFSTOCK' : stock;

  if( stock == 'PRESOLD'){
    let dt = new Date(poDate || '2022-01-01');
    dDate = poDate != null ? 'Estimated ' + dateFormat( dt, 'dd.MM.yyy') : ''; 
    if( poDate == '2049-12-25'){
      dDate = 'TBA';
    }
  }

  return (
    <div className='inner'>
      <div className="table--row" onClick={onClick}>
        <div className='table--row__col table--row__title__option'>
          <div className="multi-choice__left__dot mr-2">
            <img src={props.selected ? config.orangeCircle : OrangeCircleEmpty} alt="Orange Circle" />
          </div>
        </div>
        <div className='table--row__col table--row__title__batch'>
          <span className='grey-text table--row__title'>Batch ID</span>
          <span>{batchId}</span>
        </div>
        <div className='table--row__col table--row__title__width'>
          <span className='grey-text table--row__title'>Width</span>
          <span>{width} m</span>
        </div>
        <div className='table--row__col table--row__title__length'>
          <span className='grey-text table--row__title'>Length</span>
          <span>{length} m</span>
        </div>
        <div className='table--row__col table--row__title__price'>
          <span className='grey-text table--row__title'>Price Per M<sup>2</sup></span>
          <span>£{price.toFixed(2)}</span>
        </div>
        <div className='table--row__col table--row__title__delivery'>
          <span className='grey-text table--row__title'>Delivery</span>
          <span>{dDate}</span>
        </div>
        <div className='table--row__col table--row__title__stock'>
          <span className='grey-text table--row__title'>Stock</span>
          <span>
            <span className='d-flex justify-content-start align-items-center'>
              {stockVal == 'PRESOLD' && <>

                <span className="amber-circle" />
                <span>Pre-Order</span>    </>
              }
              {stockVal != 'PRESOLD' &&
                <>
                  <span className="green-circle" />
                  <span>In Stock</span>       </>}
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default { Rolls, Pallets };

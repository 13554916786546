import React, { useEffect, useState } from 'react';
import '../styles/Contact.scss';
import View from '../components/View';
import Button from '../components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { emailSubmitMessage } from '../actions/Email';
import { getCustomer } from '../selectors/Customer';
import { fetchCustomer } from '../actions/Customer';
import { config } from '../config/config';

const Contact = () => {
  const dispatch = useDispatch();
  const customer = useSelector(getCustomer);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [contactNo, setContactNo] = useState('');
  const [message, setMessage] = useState('');
  const [messageSent, setMessageSent] = useState(false);

  useEffect(() => {
    dispatch(fetchCustomer());
  }, [dispatch]);


  const onSubmit = async () => {
    await dispatch(emailSubmitMessage({
      firstName,
      lastName,
      email,
      contactNo,
      message,
      rep_email: customer?.rep_email || '',
      rep_name: customer?.rep_name || '',
      branchEmail: customer?.branch_email || ''
    }));
    setFirstName('');
    setLastName('');
    setEmail('');
    setContactNo('');
    setMessage('');
    setMessageSent(true);
    setTimeout(() => {
      setMessageSent(false);
    }, 10000);
  };

  return (
    <View>
      <div className='contact-outer'>
        {config.showContactHeader == true &&
          <div className="myaccount-header" style={{ backgroundImage: `url("${config.contactHeaderImage}")` }}>
            <div className='container'>
              <div className="myaccount-header__title">
                <h3>Contact Us</h3>
              </div>
            </div>
          </div>
        }
        <div className="container contact">
          <div className="row">
            <div className="col-lg-8 contact__left">
              {config.showContactHeader != true &&
                <h3 className="contact__left__title text-darkgrey">Contact Us</h3>
              }
              <p className="contact__left__subtitle">Complete our contact form</p>
              <p className="contact__left__text">
                For general help and support, please complete the form below.
              </p>
              <div className="row">
                <div className="col-lg-6 pb-3">
                  <p className="font-bold input-title-pb">First Name <span className="red-text">*</span></p>
                  <input
                    type="text"
                    name="fname"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    onBlur={(e) => setFirstName(e.target.value)}
                    className="form-control"
                    placeholder="First Name"
                  />
                </div>
                <div className="col-lg-6 pb-3">
                  <p className="font-bold input-title-pb">Last Name <span className="red-text">*</span></p>
                  <input
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    onBlur={(e) => setLastName(e.target.value)}
                    className="form-control"
                    placeholder="Last Name"
                  />
                </div>
                <div className="col-lg-6 pb-3">
                  <p className="font-bold input-title-pb">Email <span className="red-text">*</span></p>
                  <input
                    type="email"
                    value={email}
                    className="form-control"
                    onChange={(e) => setEmail(e.target.value)}
                    onBlur={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                  />
                </div>
                <div className="col-lg-6 pb-3">
                  <p className="font-bold input-title-pb">Contact No. <span className="red-text">*</span></p>
                  <input
                    type="text"
                    value={contactNo}
                    className="form-control"
                    onChange={(e) => setContactNo(e.target.value)}
                    onBlur={(e) => setContactNo(e.target.value)}
                    placeholder="Contact Number"
                  />
                </div>
                <div className="col-lg-12 pb-3">
                  <p className="font-bold input-title-pb">Enquiry <span className="red-text">*</span></p>
                  <textarea
                    className="form-enquiry"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onBlur={(e) => setMessage(e.target.value)}
                    placeholder="Enquiry"
                  />
                </div>
              </div>
              {messageSent && <div className="alert alert-success" role="alert">
                Your message has been sent!
              </div>}
              <Button onClick={onSubmit} disabled={!firstName || !lastName || !email || !contactNo || !message} text="Send Message" />
            </div>
            <div className="col-lg-4 contact__right">
              <h3 className="font-bold">Contact Us</h3>
              <div className="contact__right__order">
                <p className="contact__right__title">Enquiries</p>
                <p>
                  For any enquiries or questions regarding placing
                  <br />
                  an order or wishing to amend or cancel an order
                  <br /> please contact us
                </p>
                <p>Tel: {customer?.branch_phone}</p>
                <p>{customer?.branch_email}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </View>
  );
};

export default Contact;

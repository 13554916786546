import axios from 'axios';
import c from '../helpers/constants';
import { getApiKey, getAxiosToken, objectToQueryString } from '../helpers/functions';

export function getPricelist(customerId: string, type: string ) {
  const obj = {
    customerid: encodeURIComponent(customerId),
    prod_type: type,
  };
  const queryString = objectToQueryString(obj);


  let token = getAxiosToken();

  console.log( token ); 

  return (
    axios({
      url: `${c.API_ENDPOINTS.GET_PRICELIST}?${queryString}`,
      method: 'GET',
      cancelToken: token?.source,
      headers: {
        'Accept': 'application/json',
        'x-api-key': getApiKey()
      },
    })
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

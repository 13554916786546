import React, { useId, useRef, useState } from 'react';
import './ProductCard.scss';
import { GrDown, GrUp } from 'react-icons/gr';
import TextInput from '../TextInput';
import { Product } from '../../types/Product';
import { Price } from '../../types/Price';
import { Roll, Stock } from '../../types/Stock';
import { ProductType } from '../../types/ProductType';
import _ from 'lodash';
import Button from '../Button';
import DropDown from '../DropDown';
import MultiChoice from '../MultiChoice';
import { OrderLine } from '../../types/Order';
import { generateUID, getCustomerID, lwRoundNumber, renderCurrentLength, roundFloat, roundNumber } from '../../helpers/functions';
import { useDispatch, useSelector } from 'react-redux';
import { addToBasketCutsRolls } from '../../actions/Order';
import BoxSvg from '../../assets/svgs/box-SVG.svg';
import { emailNotifyStock } from '../../actions/Email';
import { getCustomer } from '../../selectors/Customer';
import { isFetchingStock } from '../../selectors/Stock';
import { isSendingEmail } from '../../selectors/Email';
import { getReturnCode, getReturnDescription } from '../../selectors/Order';
import ExpectedStockDate from '../ExpectedStockDate';
import ProductImage from '../ProductImage';
import ProductImageList from '../ProductImageList';
import CONSTANTS from '../../helpers/constants';
import { useHistory } from 'react-router-dom';
import TooltipViewer from '../TooltipViewer';
import { config } from '../../config/config';
import dateFormat from 'date-fns/format';
import { fetchStock } from '../../actions/Stock';

type Props = {
    product?: Product | null;
    price?: Price | null;
    stock?: Stock | null;
    productType?: ProductType | null,
    similar?: Array<Product> | null;
    onClick?: (arg: string) => void;
    onPopup?: (arg: string) => void;
};

type Cut = {
    sqm: number;
    width: number;
    length: number;
    price: number;
    toggle: boolean;
    reference: string;
    product_sku: string;
    product_quality: string;
    colour_full_name: string;
    presold: boolean;
};

const ProductCard = (props: Props) => {

    const scrollRef = useRef<HTMLDivElement>(null);
    const { product, price, stock, productType, similar } = props;
    const dispatch = useDispatch();
    const sendingEmail = useSelector(isSendingEmail);
    const [emailSent, setEmailSent] = useState(false);
    const customer = useSelector(getCustomer);
    const isFetchingStockVar = useSelector(isFetchingStock);
    const [currentLength, setCurrentLength] = useState<number | null>(0);
    const [reference, setReference] = useState('');
    const [rollReference, setRollReference] = useState('');
    const [selectedRoll, setSelectedRoll] = useState<Roll[]>([]);
    //const [selectedRollReference, setSelectedRollReference] = useState<string[]>([]);
    const returnCode = useSelector(getReturnCode);
    const returnDescription = useSelector(getReturnDescription);
    const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
    const [orderLines, setOrderLines] = useState<OrderLine[]>([]);
    const [cuts, setCuts] = useState<Cut[]>([]);
    const [toggleRolls, setToggleRolls] = useState(false);
    const [showAll, setShowAll] = useState(false);
    const history = useHistory();
    const ref = useRef(null);

    const [toggleMore, setToggleMore] = useState<boolean>(false);
    const rollstockMax = 100;


    const rollSQM = selectedRoll ? _.sum(_.map([...selectedRoll], (x) => { return x.roll_width * x.roll_length })).toFixed(2) : 0;
    const rollTotal = selectedRoll ? _.sum(_.map([...selectedRoll], (x) => { return (x.roll_prc ? x.roll_prc : 0) * x.roll_width * x.roll_length })) : 0;


    // Check if roll of width is in stock
    const rollWidthInStock = (currentWidth: number) => {
        if (stock?.stock_availablity !== 'yes') {
            return false;
        }
        return stock?.roll?.find(rolls => rolls.roll_width === currentWidth);
    };


    // Check if roll of width is in stock
    const presoldInStock = (currentWidth: number) => {
        if (!config.enablePresold) {
            return false;
        }
        return stock?.roll?.find(rolls => rolls.roll_width === currentWidth && rolls.roll_flag == 'PRESOLD');
    };




    let addToSummaryText = 'Add to Summary';
    let cutOptions: number[] = [];
    let rollOptions: number[] = [];
    let widthIndexes = ['WIDTH1', 'WIDTH2', 'WIDTH3', 'WIDTH4'];
    let widthOptions: any[] = [];
    let cutWidths: any[] = [];

    let myProduct = { ...product } as any;

    if (myProduct) {
        widthIndexes.map(x => {
            if (myProduct[x] > 0) {
                let widthLabel = myProduct[x];

                if (!rollWidthInStock(myProduct[x])) {
                    if (!presoldInStock(myProduct[x])) {
                        widthLabel = `${widthLabel} <span class="colour-red badge badge-pill">Out of Stock</span>`;
                    } else {
                        widthLabel = `${widthLabel} <span class="colour-orange badge badge-pill">Pre-Order</span>`;
                    }
                }
                widthOptions.push({ label: myProduct[x], value: myProduct[x] });
                rollOptions.push(myProduct[x]);
                cutWidths.push({ label: widthLabel, value: myProduct[x] });
                cutOptions.push(myProduct[x]);
            }
        });
    }

    const checkRollPrice = (): number => {
        let x = price?.roll_price ? price.roll_price : 0;

        if (!stock || !stock.roll) {
            return x;
        }

        let priceCount = stock?.roll?.filter(y => y.roll_prc && y.roll_prc < x);

        if (priceCount.length == stock.roll.length && stock.roll.length > 0) {
            return stock.roll[0].roll_prc ? stock.roll[0].roll_prc : x;
        }

        return x;
    };

    const widths = widthOptions;
    const [currentWidth, setCurrentWidth] = useState(widths?.[0]?.value);
    const [activeLength, setActiveLength] = useState(widths?.[0]?.value);

    if (widthOptions && widthOptions.length > 0 && activeLength == undefined) {
        setActiveLength(widthOptions[0].value);
        setCurrentWidth(widthOptions[0].value);
    }

    const hasCutStock = () => {
        if (isFetchingStockVar) {
            return true;
        }
        const hasLengthForWidth = stock?.roll?.find(r => r.roll_flag == 'L' && r.roll_width === currentWidth);
        const longestLength = stock?.roll?.find(r => r.roll_flag == 'L' && r.roll_width === currentWidth)?.roll_length || 0;
        return hasLengthForWidth && longestLength > 0 && stock?.stock_availablity === 'yes';
    };

    const hasPresoldStock = () => {
        if (!config.enablePresold) {
            return false;
        }
        if (isFetchingStockVar) {
            return false;
        }
        if (stock?.roll.length == 0) {
            return false;
        }

        const hasLengthForWidth = stock?.roll?.find(r => r.roll_flag == 'PRESOLD' && r.roll_width === currentWidth);
        const longestLength = stock?.roll?.find(r => r.roll_flag == 'PRESOLD')?.roll_length || 0;
        return hasLengthForWidth && longestLength > 0;
    };


    const getCutDate = () => {
        //Search the rolls for the current width
        let x = stock?.roll?.filter(y => y.roll_flag != 'PRESOLD');

        if (x == null || x.length == 0) {
            return;
        }
        return x[0].roll_availability;
    };


    let consumedData: any = {};

    const getUsedRolls = () => {
        const localOrderLines = [...orderLines];
        let usedIds: any = [];

        //A list of consumed rolls

        console.log(localOrderLines);
        localOrderLines.map(x => {

            if (consumedData.hasOwnProperty(x.roll_id) === false) {
                consumedData[x.roll_id] = 0;
            }

            if (x.roll_id != '' && x.roll_id != 'L') {
                consumedData[x.roll_id] += x.length;
            }
        });

        //Find the rolls
        stock?.roll?.map(y => {
            if (y.roll_id != '' && y.roll_id != 'L') {
                if (consumedData.hasOwnProperty(y.roll_id)) {
                    if (consumedData[y.roll_id] >= y.roll_length) {
                        usedIds.push(y.roll_id);
                    }
                }
            }
        });
        return usedIds;
    };

    const getEstimatedRollUsage = (roll: Roll) => {
        return roll.roll_length;
        if (!consumedData.hasOwnProperty(roll.roll_id)) {
            return roll.roll_length;
        }
        return roll.roll_length - consumedData[roll.roll_id]
    };


    const usedRolls = getUsedRolls();

    const rollsStock = config.enablePresold == true ? (stock?.roll?.filter(r => r.roll_flag != 'L' && !usedRolls.includes(r.roll_id)) || []) : (stock?.roll?.filter(r => r.roll_flag != 'L' && r.roll_flag != 'PRESOLD' && !usedRolls.includes(r.roll_id)) || []);



    //Calculate the remaining after a subtraction -- can't be negative 
    const calculateRemaning = (inNumber: number) => {
        let x = inNumber < 0 ? 0 : inNumber;
        let tmp = ('' + x).split('.');

        if (tmp.length > 1) {
            let t = parseFloat(tmp[0]);
            t = isNaN(t) ? 0 : t;
            let decimal = parseFloat('0.' + tmp[1]);
            decimal = isNaN(decimal) ? 0 : decimal;
            return t + decimal.toFixed(2);
        }
        return x;
    };

    const getNonPresoldDeliveryDate = () => {

    };


    const onAddCutsToOrderSummary = (localCuts: Cut[]) => {
        const localOrderLines = [...orderLines];
        if (product && localCuts.length > 0 && price && productType) {
            localCuts.forEach(c => {

                let estimated = getCutEstimatedDeliveryDate();
                let roll_id = c.presold ? getCutEsimatedRollId() : '';

                localOrderLines.push({
                    uid: generateUID(c.product_sku, c.product_quality, c.reference),
                    product_sku: c.product_sku,
                    product_quality: c.product_quality,
                    product_type_code: productType.product_type_code,
                    product_type_code_name: productType.product_type_desc,
                    quantity: 1,
                    length: lwRoundNumber(c.length),
                    width: c.width,
                    roll_id: roll_id,
                    pallet_id: '',
                    half_roll: '',
                    item_price: price.cut_price,
                    line_reference: c.reference,
                    line_price: c.length * c.width * price.cut_price,
                    line_discount: price.cut_price_discount,
                    colour_full_name: c.colour_full_name,
                    line_availability: getCutDate(),
                    line_delivery_date: '',
                    collection: false,
                    carriage_charge_linit: price.carriage_charge_linit,
                    carriage_charge_units: price.carriage_charge_units,
                    carriage_charge_coir: price.carriage_charge_coir,
                    presold: c.presold,
                    cart_date: estimated != 'TBA' && estimated != '' ? 'Earliest Estimated ' + estimated : estimated,
                });
            });
            setOrderLines(localOrderLines);
            setCuts([]);
        }
    };

    const onNotify = async () => {
        await dispatch(emailNotifyStock({
            customerName: customer?.NAME || 'N/A',
            customerID: getCustomerID() || 'N/A',
            customerEmail: customer?.contact_email || 'N/A',
            productQuality: product?.quality_full_name || 'N/A',
            productColor: product?.colour_full_name || 'N/A',
            productSKU: product?.product_sku || 'N/A',
            rep_email: customer?.rep_email || 'N/A',
            rep_name: customer?.rep_name || 'N/A',
            branchEmail: customer?.branch_email || '',
            width: currentWidth || ''
        }));
        setEmailSent(true);
        setTimeout(() => {
            setEmailSent(false);
        }, 10000);
    };

    const onAddRollToOrderSummary = () => {
        const localOrderLines = [...orderLines];
        if (product && selectedRoll && price && productType) {
            let i = 1;
            selectedRoll.forEach(x => {

                let dt = new Date(x.roll_expected_stock_date || '2022-01-01');
                let dDate = x.roll_expected_stock_date != null && x.roll_flag == 'PRESOLD' ? 'Earliest Estimated ' + dateFormat(dt, 'dd.MM.yyy') : '';
                if (dDate == '2049-12-25') {
                    dDate = 'TBA';
                }

                if (x.roll_flag != 'PRESOLD') {
                    dDate = '';
                }


                localOrderLines.push({
                    uid: generateUID(product.product_sku, product.quality_full_name, rollReference + ' ' + i),
                    product_sku: product.product_sku,
                    quantity: 1,
                    pallet_id: '',
                    length: x.roll_length,
                    product_quality: product.quality_full_name,
                    product_type_code: product.product_type_code,
                    product_type_code_name: productType.product_type_desc,
                    width: x.roll_width,
                    item_price: x.roll_prc ? x.roll_prc : price.roll_price,
                    roll_id: x.roll_id,
                    half_roll: x.roll_flag,
                    line_reference: rollReference + ' ' + i,
                    line_price: x.roll_length * x.roll_width * (x.roll_prc ? x.roll_prc : price.roll_price),
                    line_discount: price.roll_price_discount,
                    colour_full_name: product.colour_full_name,
                    line_availability: x.roll_availability,
                    line_delivery_date: '',
                    collection: false,
                    carriage_charge_linit: price.carriage_charge_linit,
                    carriage_charge_units: price.carriage_charge_units,
                    carriage_charge_coir: price.carriage_charge_coir,
                    presold: x.roll_flag == 'PRESOLD',
                    cart_date: dDate
                });
                i++;
            });
            setOrderLines(localOrderLines);
            setSelectedRoll([]);
            //setRollReference('');
            //Scroll up 
            if (scrollRef && scrollRef.current) {
                (scrollRef.current as any).scrollIntoView();
            }

        }
    };

    //Get the longest piece
    const getLongestPiece = () => {

        let longest = stock?.roll?.filter(x => x.roll_flag == 'L' && x.roll_width == currentWidth);

        if (null == longest || !longest || longest.length == 0) {

            longest = rollsStock?.filter(x => x.roll_flag != 'PRESOLD' && x.roll_width == currentWidth);

            if (null == longest || !longest || longest.length == 0) {
                return 0;
            }

            longest.sort((a, b) => { return a.roll_length > b.roll_length ? -1 : 0; });

            return longest[0] ? longest[0].roll_length : 0;
        }
        return longest[0] ? longest[0].roll_length : 0;
    };


    //Get the longest presold
    const getLongestPresold = () => {
        let longest = stock?.roll?.filter(x => x.roll_flag === 'PRESOLD' && x.roll_width == currentWidth);
        if (null == longest || !longest || longest.length == 0) {
            return 0;
        }
        longest.sort((a, b) => { return a.roll_length > b.roll_length ? -1 : 0; });
        return longest[0] ? longest[0].roll_length : 0;
    };



    const addSelectedRoll = (roll: Roll) => {
        //Selected roll empty so just push our roll
        if (null == selectedRoll) {
            setSelectedRoll([roll]);
            return;
        }

        let spliced = false;
        if (selectedRoll && selectedRoll.length > 0) {
            //Check if we already added
            spliced = selectedRoll.includes(roll);

            if (spliced) {
                setSelectedRoll(selectedRoll.filter(x => {
                    return x.roll_id != roll.roll_id;
                }));
                return;
            }
        }

        const localRolls = [...selectedRoll];
        localRolls.push(roll);
        setSelectedRoll(localRolls);
    };


    const onAddOrderSummaryToBasketAndContinue = () => {
        setHasSubmitted(true);
        dispatch(addToBasketCutsRolls(orderLines, customer?.delivery_address_code));



        const el2 = ref.current as any;
        const styles = getComputedStyle(el2);
        const z = parseInt(styles.zIndex);

        if (z == 2) {
            history.push(CONSTANTS.APP_ROUTES.CART);
        } else {
            if (null != props.onPopup) {
                props.onPopup('You have added ' + orderLines.length + ' products to your basket');
            }
        }

        //Clear the summary 
        setOrderLines([]);

        setTimeout(() => {
            dispatch(fetchStock(product ? product.product_sku : ''));
        }, 1000);
    };


    const onAddAnotherCut = () => {
        const localCuts = [...cuts];
        if (currentWidth && currentLength && price?.cut_price && product) {


            //The length we're adding
            let isPresold = false;
            let currVal = currentLength ? currentLength : 0;

            //The cut is longer then longest length but less than or equal to longest presold
            if (currVal > getLongestPiece() && currVal <= getLongestPresold()) {
                isPresold = true;
                //We have a total length of more than the total available
            } else if (totalLength - currentUsedLength() < 0) {
                isPresold = true;
            }

            let disableAddCut = (!rollWidthInStock(currentWidth) && !presoldInStock(currentWidth)) || !currentLength || currentLength == 0 || !currentWidth || reference.length <= 0 || currentLength < 1 && currentLength > 0;


            //Check that we dont' exceed the longest width 
            if (currVal > getLongestPiece() && currVal > getLongestPresold()) {
                return true
            }



            localCuts.push({
                width: parseFloat(currentWidth),
                length: lwRoundNumber(currentLength),
                price: price?.cut_price,
                sqm: lwRoundNumber(currentWidth * currentLength),
                toggle: false,
                reference: reference,
                product_sku: product.product_sku,
                product_quality: product.quality_full_name,
                colour_full_name: product.colour_full_name,
                presold: isPresold,
            });

            //setCuts(localCuts);
            setCurrentLength(0);
            onAddCutsToOrderSummary(localCuts);
        }
    };

    const doSetShowAll = () => {
        setShowAll(true);
        setActiveLength(0);
    };

    const doSetActiveLength = (index: number) => {
        setShowAll(false);
        setActiveLength(index);
    };



    let totalLength = 0;
    let totalPresold = 0;

    stock?.roll?.forEach(r => {
        if (r.roll_flag !== 'L' && r.roll_width === currentWidth && r.roll_flag != 'PRESOLD') {
            totalLength += r.roll_length;
        }
    });



    if (totalLength === 0 && stock?.stock_availablity === 'yes') {
        const innerRoll = stock?.roll?.find(r => r.roll_width === currentWidth && r.roll_flag == 'L');
        if (innerRoll) {
            totalLength = innerRoll.roll_length;
        }
    }

    //calculate the presold length 
    stock?.roll?.filter(y => y.roll_width == currentWidth && y.roll_flag == 'PRESOLD').map(x => {
        totalPresold += x.roll_length
    });

    const currentUsedLength = () => {
        let length = currentLength || 0;
        cuts.forEach(c => {
            if (c.width === currentWidth && c.product_sku === product?.product_sku && c.presold == false) {
                length += c.length;
            }
        });
        orderLines.forEach(c => {
            if (c.width === currentWidth && c.product_sku === product?.product_sku && c.presold == false) {
                length += c.length;
            }
        });
        return length;
    };


    const totalUsedPresold = () => {
        let length = 0;


        cuts.forEach(c => {
            if (c.width === currentWidth && c.product_sku === product?.product_sku && c.presold == true) {
                length += c.length;
            }
        });
        orderLines.forEach(c => {
            if (c.width === currentWidth && c.product_sku === product?.product_sku && c.presold == true) {
                length += c.length;
            }
        });
        return length;
    };


    const totalSqm = lwRoundNumber(currentWidth && currentLength && currentWidth * currentLength || 0);

    if (hasSubmitted && returnCode === 'OK') {
        setHasSubmitted(false);
        //history.push(forwardUrl);
    }

    const scrollToRolls = () => {
        const rollDiv = document.getElementById('rolls');
        if (null != rollDiv) {
            setToggleRolls(true);
            rollDiv.scrollIntoView();
        }
    };

    const onMinus = (index: number) => {
        let localOrderLines = [...orderLines];
        const o = localOrderLines[index];
        if (o.quantity === 1) {
            localOrderLines = localOrderLines.filter((or, i) => i !== index);
        } else {
            if (index >= 0) {
                localOrderLines[index].quantity -= 1;
                localOrderLines[index].line_price = localOrderLines[index].length * (o?.width || 0) * localOrderLines[index].item_price * localOrderLines[index].quantity;
            }
        }
        setOrderLines(localOrderLines);
    };

    const [activeTooltip, setActiveTooltip] = useState<string>('');

    const toggleToolTip = (name: string, direction: boolean) => {
        if (!direction) {
            setActiveTooltip('');
            return;
        }
        setActiveTooltip(name);
    };


    //Work out how many cut pieces we have 
    const workOutCutPieces = () => {
        let lengthList: any = [];
        cuts.forEach(c => {
            if (c.width === currentWidth && c.product_sku === product?.product_sku) {
                lengthList.push(c.length);
            }
        });
        orderLines.forEach(c => {
            if (c.width === currentWidth && c.product_sku === product?.product_sku) {
                lengthList.push(c.length);
            }
        });
        return lengthList;
    };


    const getCutEsimatedRollId = () => {


        //Search the rolls for the current width
        let x = stock?.roll?.filter(x => x.roll_width == currentWidth);

        if (null == x) {
            return '';
        }

        //let total  
        let total = currentLength ? currentLength : 0;
        let counter = 0;


        //How many presold rolls are we cutting into ? 
        let cutPieces = workOutCutPieces();
        let remaining = stock?.roll.filter(x => x.roll_width == currentWidth);

        if (null == remaining) {
            return '';
        }

        let theRemainingPieces: any = [];

        remaining.forEach(y => {
            theRemainingPieces.push({ ...y });
        });

        //Go over the cut pieces and assign them to a roll
        cutPieces.map((piece: any) => {
            //Loop over the rolls 
            for (let i = 0; i < theRemainingPieces.length; i++) {
                if (theRemainingPieces[i].roll_length - piece > 0) {
                    theRemainingPieces[i].roll_length -= piece;
                    break;
                }
            }
        });


        for (let i = 0; i < theRemainingPieces.length; i++) {
            if (theRemainingPieces[i].roll_length >= total) {
                //This one is the date we need
                //if (theRemainingPieces[i].roll_flag == 'PRESOLD') {
                return theRemainingPieces[i].roll_id;
                // }
                //in stock so next working day
                return '';
            }
        }
        return '';
    };



    //Get the estimated delivery date for a cut 
    const getCutEstimatedDeliveryDate = () => {

        if (!config.enablePresold) {
            return '';
        }

        //Search the rolls for the current width
        let x = stock?.roll?.filter(x => x.roll_width == currentWidth);

        if (null == x) {
            return 'TBA';
        }

        //let total  
        let total = currentLength ? currentLength : 0;
        let counter = 0;


        //How many presold rolls are we cutting into ? 
        let cutPieces = workOutCutPieces();
        let remaining = stock?.roll.filter(x => x.roll_width == currentWidth);

        if (null == remaining) {
            return 'TBA';
        }

        let theRemainingPieces: any = [];

        remaining.forEach(y => {
            theRemainingPieces.push({ ...y });
        });

        //Go over the cut pieces and assign them to a roll
        cutPieces.map((piece: any) => {
            //Loop over the rolls 
            for (let i = 0; i < theRemainingPieces.length; i++) {
                if (theRemainingPieces[i].roll_length - piece > 0) {
                    theRemainingPieces[i].roll_length -= piece;
                    break;
                }
            }
        });


        for (let i = 0; i < theRemainingPieces.length; i++) {
            if (theRemainingPieces[i].roll_length >= total) {
                //This one is the date we need
                if (theRemainingPieces[i].roll_flag == 'PRESOLD') {

                    let dt = new Date(theRemainingPieces[i].roll_expected_stock_date || '2022-01-01');
                    let dDate = theRemainingPieces[i].roll_expected_stock_date != null ? dateFormat(dt, 'dd.MM.yyy') : '';
                    if (dDate == '2049-12-25') {
                        dDate = 'TBA';
                    }
                    return dDate;
                }
                //in stock so next working day
                return stock && stock.delivery_date.length > 0 ? dateFormat(new Date(stock.delivery_date[0].delivery_date), 'dd.MM.yyy') : 'TBA';
            }
        }
        return 'TBA';
    };

    const getToggleState = (name: string) => {
        return name == activeTooltip;
    };

    const removeTooltip = () => {
        setActiveTooltip('');
    };

    const getRollStatus = () => {
        let x = stock?.roll?.filter(x => x.roll_id != '' && x.roll_flag != 'PRESOLD' && x.roll_flag != 'L' && (showAll || x.roll_width == activeLength)).length;

        if (x && x > 0) {
            return 'In Stock';
        }

        if (config.enablePresold) {
            let y = stock?.roll?.filter(z => z.roll_flag == 'PRESOLD' && (showAll || z.roll_width == activeLength)).length;

            if (y && y > 0 && config.enablePresold) {
                return 'Pre-Order';
            }
        }

        return 'Out of Stock';
    };

    /* Get the expected stock date from the stock */
    const getExpectedDate = (theStock: any) => {
        if (!theStock) {
            return null;
        }

        let presoldRolls = theStock?.roll && theStock?.roll.filter((x: any) => x.roll_flag == 'PRESOLD' && x.roll_width == currentWidth);

        if (null == presoldRolls || presoldRolls.length == 0) {
            if (theStock.stock_expected_date && theStock.stock_expected_date != '2049-12-25') {
                return theStock.stock_expected_date;
            }
            return null;
        }

        return presoldRolls[0].roll_expected_stock_date;
    };


    const isTooLong = (currentLength && (currentLength > getLongestPiece()) && (currentLength > getLongestPresold()));

    //Check if we need to show the presold notice 
    let showPredorderNotice = !isTooLong && (currentLength && currentLength > 0) && (config.enablePresold && currentUsedLength() < (totalPresold + totalLength)) && ((!hasCutStock() && hasPresoldStock() && currentUsedLength() > getLongestPiece()) || (hasCutStock() && currentUsedLength() > getLongestPiece() && stock?.stock_availablity !== 'no')) && ((currentLength ? currentLength : 0) <= getLongestPresold());

    //Get the roll status 
    const rollStatus = getRollStatus();

    const getDisableAddCut = () => {
        //Check that the width is in stock and we aren't between 0 and 1 
        let currVal = currentLength ? currentLength : 0;
        let disableAddCut = (!rollWidthInStock(currentWidth) && !presoldInStock(currentWidth)) || !currentLength || currentLength == 0 || !currentWidth || reference.length <= 0 || currentLength < 1 && currentLength > 0;


        //Check that we dont' exceed the longest width 
        if (currVal > getLongestPiece() && currVal > getLongestPresold()) {
            return true;
        }


        return disableAddCut;
    };

    //Get whether the cut needs to be disabled 
    let disableAddCut = getDisableAddCut();


    if (showPredorderNotice) {
        addToSummaryText = 'Add Pre-Order to Summary';
    }

    const getVeryLongestPiece = () => {
        let lngsPre = getLongestPresold();
        if (lngsPre == 0) {
            return getLongestPiece();
        }
        return lngsPre;
    };

    const remainingStock = calculateRemaning(totalLength - currentUsedLength() + (currentLength ? currentLength : 0));

    const showCannotDoNotice = (!(showPredorderNotice === true && isTooLong == false) && (!isTooLong && ((!hasCutStock() && hasPresoldStock() && currentUsedLength() > getLongestPiece()) || (hasCutStock() && (currentUsedLength() > getLongestPiece()) && stock?.stock_availablity !== 'no') && (currentLength && currentLength > 0)))) && (currentLength && currentLength < getLongestPiece());

    const noStockLeft = parseInt(calculateRemaning(totalLength - currentUsedLength() + (currentLength ? currentLength : 0)) + '') <= 0 && !showPredorderNotice && (currentLength != null && currentLength > 0);

    return (
        <div className="product-card" onClick={removeTooltip}>
            <div className='row'>
                <div className='col-md-6 product-card-image hide-mob'>
                    <ProductImage className={'product__image'} imageCode={product?.IMAGE_code} skuCode={product?.product_sku} imageAlt={product?.colour_full_name} size='large' />
                </div>
                <div className='col-md-6'>

                    <div className="product-card__header">
                        <div className="product-card__header__img">
                            <div className="product-card__header__img__name">
                                <p className="product-card__header__img__name__top">
                                    {product?.quality_full_name.toLocaleLowerCase()} {product?.colour_full_name.toLowerCase()}
                                </p>
                                <p className="product-card__header__img__name__bottom small text-grey">
                                    {_.startCase(_.toLower(productType?.product_type_desc.toLowerCase()))}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="product-card__pricing">
                        <h3>Pricing &amp; Availability</h3>
                        <div className='product-card__availability_row'>
                            {!product?.rolls_only &&
                                <div>
                                    <div className='text-orange product-card__pricing__header'>
                                        Available Cuts
                                    </div>
                                    <div className="product-card__pricing__content">
                                        <div className="product-card__pricing__prices">
                                            <div className='product-card__pricing__price'>
                                                Price Per SQM: &pound; {price?.cut_price?.toFixed(2) || 0}
                                            </div>
                                        </div>
                                        <div className='product-card__pricing__availability'>
                                            <div className='d-flex justify-content-end align-items-center w-100'>
                                                {hasCutStock() &&
                                                    <span className='badge badge-pill'>In Stock</span>
                                                }
                                                {!hasCutStock() && !hasPresoldStock() && <>
                                                    <span className='badge badge-pill out-of-stock'>Out of Stock</span>
                                                    <TooltipViewer onClick={(direction: boolean) => toggleToolTip('cut', direction)} toggleState={getToggleState('cut')} side='right' tipTitle='Out of Stock' tipText='Please contact our Customer Service Team for more information on expected arrival of new stock'></TooltipViewer></>

                                                }
                                                {!hasCutStock() && hasPresoldStock() && <>
                                                    <span className='badge badge-pill pre-order'>Pre-Order</span>
                                                    <TooltipViewer onClick={(direction: boolean) => toggleToolTip('cut', direction)} toggleState={getToggleState('cut')} side='right' tipTitle='Pre Order' tipText='We are currently awaiting stock to arrive from the manufacturers. Your order will be despatched shortly after the date displayed'></TooltipViewer></>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {!hasCutStock() &&
                                        <div className='product-card__pricing__view_availability'>
                                            <div className='product-card__pricing__availability'>
                                                <div className='stock-date'>
                                                    <ExpectedStockDate stock={getExpectedDate(stock)} showPipe={false} />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }

                            <div>
                                <div className='product-card__pricing__header'>
                                    Available Rolls
                                </div>
                                <div className="product-card__pricing__content">
                                    <div className="product-card__pricing__prices">
                                        <div className='product-card__pricing__price'>
                                            Price Per SQM: &pound; {checkRollPrice().toFixed(2) || 0.00}
                                        </div>
                                    </div>
                                    <div className='product-card__pricing__availability'>
                                        <div className='d-flex justify-content-end align-items-center stock-availability-justification'>
                                            <div>
                                                {rollStatus == 'In Stock' &&
                                                    <span className='badge badge-pill'>In Stock</span>
                                                }
                                                {rollStatus == 'Out of Stock' &&
                                                    <><span className='badge badge-pill out-of-stock'>Out of Stock</span>
                                                        <TooltipViewer side='left' onClick={(direction: boolean) => toggleToolTip('roll', direction)} toggleState={getToggleState('roll')} tipTitle='Out of Stock' tipText='Please contact our Customer Service Team for more information on expected arrival of new stock'></TooltipViewer></>
                                                }
                                                {rollStatus == 'Pre-Order' && <>
                                                    <span className='badge badge-pill pre-order'>Pre-Order</span>
                                                    <TooltipViewer onClick={(direction: boolean) => toggleToolTip('roll_p', direction)} toggleState={getToggleState('roll_p')} side='right' tipTitle='Pre Order' tipText='We are currently awaiting stock to arrive from the manufacturers. Your order will be despatched shortly after the date displayed'></TooltipViewer></>
                                                }
                                            </div>
                                            {
                                                config.showAvailableRollsInline == true && <>

                                                    <a className="px-2 py-1 hide-mob" onClick={scrollToRolls}>View Available Rolls</a>

                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {config.showAvailableRollsInline != true &&
                                    <div className="product-card__pricing__view_availability">
                                        <a className="px-2 py-1 hide-mob" onClick={scrollToRolls}>View Available Rolls &gt;</a>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className='product-card-image hide-desktop'>
                        <ProductImage className={'product__image'} imageCode={product?.IMAGE_code} skuCode={product?.product_sku} imageAlt={product?.colour_full_name} size='large' />
                    </div>

                    <div className='product-card__colours'>
                        <ProductImageList similar={similar} current={product} onClick={props.onClick} />
                    </div>

                </div>
            </div>
            <div className='row'>
                <div className='col-md-12 col-lg-6'>

                    {/*<!-- CURRENT CUT -->*/
                        product?.rolls_only == false &&
                        <div className="product-card__accordion mt-3 cut-box">
                            <div className="product-card__accordion__header">
                                <div className="product-card__accordion__header__title">
                                    Cut Length
                                </div>
                                <div className="product-card__price__right">

                                    <div className='d-flex justify-content-end align-items-center'>
                                        <div className="product-card__price__right__details">
                                            <span className="text-small white">
                                                {hasCutStock() ? 'In Stock' : (hasPresoldStock() ? 'Pre-Order' : 'Out Of Stock')}
                                            </span>
                                        </div>
                                        {hasCutStock() && <div className="green-circle" />}
                                        {!hasCutStock() && !hasPresoldStock() && <div className="red-circle" />}
                                        {!hasCutStock() && hasPresoldStock() && <div className="amber-circle" />}

                                    </div>
                                </div>
                            </div>
                            {<div className="product-card__accordion__body">
                                <div className='inner'>


                                    <div className="row align-items-end">
                                        <div className="col-md-8">




                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className='d-flex justify-content-between align-items-end calculation'>
                                                        <div className='calculation__left'>
                                                            <div className='small text-grey'>Width (m)</div>
                                                            <div className="mt-2">
                                                                {/* @ts-ignore */}
                                                                <DropDown items={cutWidths} value={currentWidth}
                                                                    onSelect={(item) => setCurrentWidth(item.value)} />
                                                            </div>
                                                        </div>
                                                        <div className='calculation__mid'>
                                                            <span className='text-orange'>&times;</span>
                                                        </div>
                                                        <div className='calculation__right'>
                                                            <div className='small text-grey'>Length (m)</div>
                                                            <div className="mt-2">
                                                                <TextInput type="number" bgColor="white"
                                                                    step={0.01}
                                                                    onChange={(val) => setCurrentLength(lwRoundNumber(val, true))}
                                                                    value={renderCurrentLength(currentLength)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className='mobile-cut-summary'>
                                                <div><span className='small text-grey calculation--label'>Total Area</span><span className='cut-area'>{totalSqm} m<sup>2</sup></span></div>
                                                <div><span className='small text-grey calculation--label'>{config.showAvailableRollsInline != true ? 'Subtotal:' : 'Total:'}</span><span className='cut-subtotal text-orange'>£{roundNumber(totalSqm * (price?.cut_price || 0))}</span></div>
                                            </div>
                                        </div>
                                    </div>


                                    {(!hasCutStock() && !hasPresoldStock()) && <div className="mt-4">
                                        <div className='out-of-stock mt-3'>
                                            <div className='d-flex'>
                                                <div>
                                                    <span className='badge badge-pill out-of-stock'>Out of Stock</span>
                                                </div>
                                                <div>
                                                    Get notified when <span className='italic'>{currentWidth}M width is back in stock</span>
                                                </div>
                                                <div className='wrap-last'>
                                                    <Button disabled={sendingEmail || emailSent} type="orange" size="sm" text="Notify Me" onClick={onNotify} />
                                                </div>

                                            </div>
                                        </div>

                                        {emailSent && <div className="alert alert-success mt-2" role="alert">We have registered your interest!</div>}
                                    </div>}



                                    {(hasCutStock() || hasPresoldStock()) &&
                                        <div>



                                            {/* @ts-ignore */}
                                            {(isTooLong == true && config.enablePresold) && <div className="mb-3 mt-3 alert alert-danger" role="alert">
                                                Your requested length exceeds the standard roll length of {getVeryLongestPiece()} m. Please reduce length or contact us for further assistance
                                            </div>}


                                            {/* @ts-ignore */}
                                            {(showCannotDoNotice == true && !noStockLeft && remainingStock <= 0) &&
                                                <div className="mb-3 mt-3 alert alert-danger" role="alert">
                                                    {getLongestPiece() > 0 && <>Largest available length: {getLongestPiece()} m.</>} Total stock available: {calculateRemaning(totalLength - currentUsedLength() + (currentLength ? currentLength : 0))} m. {(config.enablePresold && ((currentLength ? currentLength : 0) > getLongestPresold())) && <>Longest available pre-order length {getLongestPresold()}m.</>}We are currently unable to fulfil this request.
                                                </div>
                                            }


                                            {/* @ts-ignore */}
                                            {(noStockLeft == true && isTooLong == false) &&
                                                <div className="mb-3 mt-3 alert alert-danger" role="alert">
                                                    We are currently unable to fulfil this request.
                                                </div>
                                            }


                                            {
                                                (showPredorderNotice === true && isTooLong == false && config.enablePresold) && <div className="mb-3 mt-3 alert alert-warning" role="alert">
                                                    {
                                                        <span className='pre-order-stock'>Pre-order stock available: {calculateRemaning(totalPresold - totalUsedPresold())}m. Earliest estimated delivery date: {getCutEstimatedDeliveryDate()}. </span>
                                                    }
                                                </div>
                                            }


                                            {(hasCutStock() && currentUsedLength() < 1 && currentUsedLength() > 0 && stock?.stock_availablity !== 'no') && <div className="mb-3 mt-3 alert alert-danger" role="alert">Minimum cut length: 1m - We are unable to fulfill this request, for more information please contact our customer service team on: {customer?.branch_email ? customer.branch_email : 'webenquiries@likewisefloors.co.uk'}</div>}

                                            {(!hasCutStock() && hasPresoldStock() && currentUsedLength() < 1 && currentUsedLength() > 0) && <div className="mb-3 mt-3 alert alert-danger" role="alert">Minimum cut length: 1m - We are unable to fulfill this request, for more information please contact our customer service team on: {customer?.branch_email ? customer.branch_email : 'webenquiries@likewisefloors.co.uk'}</div>}

                                        </div>
                                    }
                                    {(hasCutStock() || hasPresoldStock()) && <div className="row mt-4">
                                        <TextInput placeholder="Reference" label="" required bgColor="white" value={reference} onChange={setReference} />
                                    </div>}


                                    {(hasCutStock() || hasPresoldStock()) &&
                                        <div className='row mt-4'>
                                            <div className='col-md-12'>
                                                <Button disabled={disableAddCut || noStockLeft} onClick={onAddAnotherCut} text={addToSummaryText} />
                                            </div>
                                        </div>
                                    }
                                </div>

                            </div>}

                        </div>
                    }

                    {/*<!-- ROLLS -->*/}
                    <div className="product-card__accordion mt-3" id="rolls">
                        <div className="product-card__accordion__header" onClick={() => setToggleRolls(!toggleRolls)}>
                            <div className="product-card__accordion__header__title">
                                Available Rolls
                            </div>

                            <div className="stock_toggle__header__display">
                                <div className="product-card__price__right">
                                    <div className='d-flex justify-content-end align-items-center'>
                                        <div className="product-card__price__right__details">
                                            <span className="text-small white">
                                                {getRollStatus()}
                                            </span>
                                        </div>
                                        {getRollStatus() == 'In Stock' && <div className="green-circle" />}
                                        {getRollStatus() == 'Pre-Order' && <div className="amber-circle" />}
                                        {getRollStatus() == 'Out of Stock' && <div className="red-circle" />}
                                    </div>
                                </div>

                                <div className="product-card-ex__accordion__header__toggle">
                                    {!toggleRolls && <GrDown className="product-card-ex__caret" />}
                                    {toggleRolls && <GrUp className="product-card-ex__caret" />}
                                </div>

                            </div>
                        </div>
                        {toggleRolls && <div className="product-card__accordion__body">

                            {rollsStock.filter(x => x.roll_id != '').length > 0 && <>
                                <div className='inner'>
                                    <div className='d-flex product-card__accordion__roll__toggle justify-content-center'>
                                        {config.showAvailableRollsInline &&
                                            <div>
                                                <button onClick={() => doSetShowAll()} className={showAll ? 'active btn-setlength' : 'btn-setlength'}>All</button>
                                            </div>
                                        }

                                        {rollOptions.length > 0 && rollOptions.map((c) =>
                                            <div key={c}>
                                                <button onClick={() => doSetActiveLength(c)} className={activeLength == c ? 'active btn-setlength' : 'btn-setlength'}>{c}M</button>
                                            </div>,
                                        )}
                                    </div>
                                </div>


                                {rollsStock.filter(r => r.roll_id != '' && (r.roll_width == activeLength || showAll == true)).length > 0 &&
                                    <div className="rolls-table">
                                        <div className='inner'>
                                            <div className='table--labels'>
                                                <div className='table--labels__title table--labels__title__option'><span className='grey-text'></span></div>
                                                <div className='table--labels__title table--labels__title__batch'><span className='grey-text'>Batch ID:</span></div>
                                                <div className='table--labels__title table--labels__title__width'><span className='grey-text'>Width:</span></div>
                                                <div className='table--labels__title table--labels__title__length'><span className='grey-text'>Length:</span></div>
                                                <div className='table--labels__title table--labels__title__cost'><span className='grey-text'>Per M<sup>2</sup>:</span></div>
                                                <div className='table--labels__title table--labels__title__delivery'><span className='grey-text'>Delivery:</span></div>
                                                <div className='table--labels__title table--labels__title__stock'><span className='grey-text'>Stock:</span></div>

                                            </div>
                                        </div>
                                        <div className='table-contents'>

                                            {stock && rollsStock.filter(r => r.roll_id !== '' && (showAll || r.roll_width == activeLength)).slice(0, 4).map(r =>
                                                <MultiChoice.Rolls
                                                    key={r.roll_id}
                                                    onClick={() => addSelectedRoll(r)}
                                                    price={r.roll_prc ? r.roll_prc : (price?.roll_price || 0)}
                                                    batchId={r.roll_serno}
                                                    length={getEstimatedRollUsage(r)}
                                                    width={r.roll_width}
                                                    selected={(selectedRoll != null && selectedRoll?.includes(r))}
                                                    delivery={r.roll_availability}
                                                    expectedDate={stock.stock_expected_date}
                                                    stock={r.roll_flag}
                                                    poDate={r.roll_expected_stock_date}
                                                />,
                                            )}


                                            {rollsStock.filter(r => r.roll_id != '' && (showAll || r.roll_width == activeLength)).length > 4 && toggleMore && product && rollsStock.filter(r => r.roll_id != '' && (showAll || r.roll_width == activeLength)).slice(4, rollstockMax).map(r =>
                                                <MultiChoice.Rolls
                                                    key={r.roll_id}
                                                    onClick={() => addSelectedRoll(r)}
                                                    price={r.roll_prc ? r.roll_prc : (price?.roll_price || 0)}
                                                    batchId={r.roll_serno}
                                                    length={getEstimatedRollUsage(r)}
                                                    width={r.roll_width}
                                                    selected={(selectedRoll != null && selectedRoll?.includes(r))}
                                                    delivery={r.roll_availability}
                                                    expectedDate={stock ? stock.stock_expected_date : ''}
                                                    stock={r.roll_flag}
                                                    poDate={r.roll_expected_stock_date}
                                                />,
                                            )}

                                            {rollsStock.filter(r => r.roll_id != '' && r.roll_width == activeLength).length > 4 &&
                                                <p className="text-orange cursor-pointer text-center text-small ps-3 mt-3" onClick={() => setToggleMore(!toggleMore)}>View {toggleMore ? 'Less' : 'More'}</p>
                                            }

                                        </div>
                                    </div>
                                }</>}


                            <div className='inner'>

                                {rollsStock.filter(r => r.roll_id != '' && (showAll || r.roll_width == activeLength)).length <= 0 &&
                                    <div className="no-pallets alert alert-danger text-center ps-3 mt-3">
                                        No Available Rolls
                                    </div>
                                }
                                {
                                    config.showAvailableRollsInline &&
                                    <div className='mobile-cut-summary mt-4'>
                                        <div><span className='small text-grey calculation--label'>Total Area</span><span className='cut-area'>{rollSQM} m<sup>2</sup></span></div>
                                        <div><span className='small text-grey calculation--label'>{config.showAvailableRollsInline != true ? 'Subtotal:' : 'Total:'}</span><span className='cut-subtotal text-orange'>£{roundNumber(rollTotal)}</span></div>
                                    </div>
                                }
                                {rollsStock.filter(r => r.roll_id != '' && r.roll_width == activeLength).length > 0 && <div className="mt-4">
                                    <TextInput label="" bgColor="white" required value={rollReference} placeholder="Reference" onChange={setRollReference} />
                                </div>}

                                {rollsStock.filter(r => r.roll_id != '' && r.roll_width == activeLength).length > 0 && <div className="mt-4">
                                    {selectedRoll && <Button disabled={!rollReference || currentUsedLength() > totalLength} text="Add to Summary" onClick={onAddRollToOrderSummary} />}
                                </div>}
                            </div>

                        </div>}
                    </div>

                </div>
                <div className='col-md-12 col-lg-6'>

                    {/*<!-- ORDER SUMMARY -->*/}
                    <div className="product-card__accordion mt-3" ref={scrollRef}>
                        <div className="product-card__accordion__header">
                            <div className="product-card__accordion__header__title">
                                {config.showAvailableRollsInline != true && <></>} Summary
                            </div>
                        </div>
                        <div className="product-card__accordion__body">
                            <div className='inner'>
                                {orderLines.map((o, i) => <div key={i} className="mt-2 mb-4 product-card__summary__row">

                                    <div onClick={() => onMinus(i)} className="text-grey remove-action">
                                        &times;
                                    </div>

                                    <div className='product-card__summary__status'>
                                        {
                                            o.presold && <span className="text-grey text-small"><span className="amber-circle" ></span><span>Pre-Order</span></span>
                                        }
                                        {
                                            !o.presold && <span className="text-grey text-small hide-desktop"><span className="green-circle" ></span><span>In Stock</span></span>
                                        }
                                    </div>

                                    <div className='product-card__summary__col product-card__summary__col__title'>
                                        <span className='product-card__summary__col__title__name'>
                                            {o.product_quality.toLowerCase()}
                                        </span>
                                        <span className='product-card__summary__col__colour'>
                                            <span className='text-grey'>
                                                {o.colour_full_name?.toLowerCase()}
                                            </span>

                                        </span>
                                    </div>
                                    <div className='product-card__summary__col product-card__summary__reference'>
                                        <span className="text-grey text-small">Reference:</span>
                                        <span className='summary-content-text'>{o.line_reference || 'N/A'}</span>
                                    </div>

                                    <div className='product-card__summary__col product-card__summary__col__subtotal'>
                                        <span className="text-grey text-small">Subtotal:</span>
                                        <span className='text-orange total'>£{roundNumber(o.line_price)}</span>
                                    </div>



                                    <div className='product-card__summary__col product-card__summary__delivery'>
                                        <div className="text-grey text-small">Delivery</div>

                                        <div className="d-flex w-100">
                                            <div className='w-100'>
                                                <img src={BoxSvg} alt="Delivery" />
                                                {
                                                    (o.line_availability && !o.presold) && <span className='summary-content-text'>{o.line_availability.replace('Delivery ', '')}</span>
                                                }
                                                {
                                                    (!o.line_availability && o.presold) && <span className='summary-content-text'>{o.cart_date}</span>
                                                }
                                            </div>
                                        </div>

                                    </div>
                                    <div className='product-card__summary__col product-card__summary__width'>
                                        <div className="text-grey text-small">Width</div>
                                        <div className="small-grey summary-content-text">{o?.width || 0} m</div>
                                    </div>
                                    <div className='product-card__summary__col product-card__summary__length'>
                                        <div className="text-grey text-small">Length</div>
                                        <div className="text-small summary-content-text">{o.length || 0} m</div>
                                    </div>
                                    <div className='product-card__summary__col product-card__summary__area'>
                                        <div className="text-grey text-small">Total Area</div>
                                        <div className="text-small summary-content-text">{roundFloat(o.length * (o?.width || 0), 1)} m<sup>2</sup></div>
                                    </div>
                                </div>,
                                )}
                                {hasSubmitted && (returnCode === 'NC' || returnCode === 'NS') && <div className="row mt-5">
                                    <div className="alert alert-danger" role="alert">
                                        {returnDescription}
                                    </div>
                                </div>}
                                <div className="row mt-4">
                                    <Button text="Add to Basket" disabled={orderLines.length <= 0}
                                        onClick={onAddOrderSummaryToBasketAndContinue} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="z-indexer" ref={ref}></div>
        </div>
    );
};


export default ProductCard;

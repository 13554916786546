import {
    FETCH_PRODUCT_AVAILABLE_TYPES_REQUEST,
    FETCH_PRODUCT_AVAILABLE_TYPES_RESPONSE,
    FETCH_PRODUCT_AVAILABLE_TYPES_FAILURE,
    ProductAvailableTypeState,
    ProductAvailableTypeAction,
    
  } from '../types/AvailableType';

  const initialState: ProductAvailableTypeState = {
    productAvailableTypes: [],
    isFetchingProductAvailableTypes: false,
    fetchingProductAvailableTypesFailed: false,
  };
  
  const availableTypeReducer = (
    state = initialState,
    action: ProductAvailableTypeAction,
  ): ProductAvailableTypeState => {
    switch (action.type) {
      case FETCH_PRODUCT_AVAILABLE_TYPES_REQUEST:
        return {
          ...state,
          productAvailableTypes: [],
          isFetchingProductAvailableTypes: true,
          fetchingProductAvailableTypesFailed: false,
        };
      case FETCH_PRODUCT_AVAILABLE_TYPES_RESPONSE:
        return {
          ...state,
          productAvailableTypes: action.payload || [],
          isFetchingProductAvailableTypes: false,
          fetchingProductAvailableTypesFailed: false,
        };
      case FETCH_PRODUCT_AVAILABLE_TYPES_FAILURE:
        return {
          ...state,
          productAvailableTypes: [],
          isFetchingProductAvailableTypes: false,
          fetchingProductAvailableTypesFailed: true,
        };
      default:
        return state;
    }
  };
  
  export default availableTypeReducer;
  
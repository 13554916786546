
import React, { useEffect } from 'react';
import './AccountSummary.scss';
import { useSelector, useDispatch } from 'react-redux';
import { getSummary } from '../../selectors/Summary';
import { fetchSummary } from '../../actions/Summary';
import { roundNumber } from '../../helpers/functions';


const AccountSummary = () => {

  const summary = useSelector(getSummary);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchSummary());
  }, [dispatch]);



  return (
    <div className='card'>
      <div className='account-card--header'>
          <h4>
              Account Summary
              <span className='account-card-balance'>Balance: &pound;{roundNumber(summary?.account_balance ?? 0 )}</span>    
          </h4>
      </div>
      <div className='account-card--content'>
          <div className='account-card--row'>
              <span className='table-header'>Type</span>
              <span className='table-header'>Amount</span>
          </div>

          <div className='account-card--row'>
              <span className='table-row'>Current</span>
              <span className='table-row'>&pound;{roundNumber(summary?.current ?? 0)}</span>
          </div>

          <div className='account-card--row'>
              <span className='table-row'>30 Days</span>
              <span className='table-row'>&pound;{roundNumber(summary?.thirty_days ?? 0)}</span>
          </div>

          <div className='account-card--row'>
              <span className='table-row'>60 Days</span>
              <span className='table-row'>&pound;{roundNumber(summary?.sixty_days ?? 0)}</span>
          </div>

          <div className='account-card--row'>
              <span className='table-row'>90 Days</span>
              <span className='table-row'>&pound;{roundNumber(summary?.ninety_days ?? 0)}</span>
          </div>

          <div className='account-card--row'>
              <span className='table-row'>120+ days</span>
              <span className='table-row'>&pound;{roundNumber(summary?.onetwenty_days ?? 0)}</span>
          </div>

          <hr className='account-card--divider'/>

          <div className='account-card--row'>
              <span className='table-row'>Sales Order</span>
              <span className='table-row'>&pound;{roundNumber(summary?.un_inv_orders ?? 0)}</span>
          </div>

          <div className='account-card--row'>
              <span className='table-row'>Held Orders</span>
              <span className='table-row'>&pound;{roundNumber(summary?.held_orders ? summary.held_orders : 0)}</span>
          </div>

          <div className='account-card--row'>
              <span className='table-row'>Unallocated Cash</span>
              <span className='table-row'>&pound;{roundNumber(summary?.os_cash ? summary.os_cash : 0)}</span>
          </div>

          <div className='account-card--row'>
              <span className='table-row'>Turnover YTD</span>
              <span className='table-row'>&pound;{roundNumber(summary?.turnover_ytd ?? 0)}</span>
          </div>

          <hr className='account-card--divider'/>

          <div className='account-card--row'>
              <span className='table-total'>Total Balance</span>
              <span className='table-total'>&pound;{roundNumber(summary?.account_balance ?? 0)}</span>
          </div>
      </div>
  </div>
  );

};
export default AccountSummary;

import React from 'react';
import './CardPopupCheckout.scss';
import ProductImage from '../ProductImage';
import { roundFloat, roundNumber } from '../../helpers/functions';
type Props = {
  quality: string;
  category?: string;
  categoryCode?: string;
  imageCode: string;
  linePrice: number;
  itemPrice: number;
  length: number | null;
  width: number | null;
  quantity: number;
  color?: string;
  reference: string;
  onDelete?: () => void;
  hideAction?: boolean;
  sqmPrice: number | null;
};

const CardPopupCheckout = (props: Props) => {

  const {
    hideAction = false,
    quality,
    categoryCode,
    imageCode,
    color = '',
    // packs = null,
    linePrice,
    length = 0,
    width = 0,
    // pallets = null,
    quantity,
    itemPrice = 0,
    onDelete = () => { },
    reference = '',
  } = props;

  const renderCutsAndRolls = () =>
    <div className="mt-2 popup-product">
      <div className='popup-product__col popup-product__col__title'>
        <span className='popup-product__col__title__name'>
          {quality.toLowerCase()}
        </span>
        <span className='popup-product__col__colour'>
          <span className='text-grey'>
            {color?.toLowerCase()}
          </span>
        </span>
      </div>
      <div className='popup-product__col popup-product__col__subtotal'>
        <span className="text-grey text-small">Subtotal:</span>
        <span className='text-orange total'>£{roundNumber(linePrice)}</span>
      </div>
      <div className='popup-product__col popup-product__reference'>
        <span className="text-grey text-small">Reference:</span>
        <span className='summary-content-text'>{reference || 'N/A'}</span>
      </div>     
      <div className='popup-product__col popup-product__width'>
        <div className="text-grey text-small">Width</div>
        <div className="small-grey summary-content-text">{(width || 0)} m</div>
      </div>
      <div className='popup-product__col popup-product__length'>
        <div className="text-grey text-small">Length</div>
        <div className="text-small summary-content-text">{(length || 0)} m</div>
      </div>
      <div className='popup-product__col popup-product__area'>
        <div className="text-grey text-small">Total Area</div>
        <div className="text-small summary-content-text">{roundFloat((length || 0) * (width || 0), 1)} m<sup>2</sup></div>
      </div>
    </div>
    ;


  const renderPacksAndPallets = () => 
    <div className="mt-2 popup-product popup-product-packs">
    <div className='popup-product__col popup-product__col__title'>
      <span className='popup-product__col__title__name'>
        {quality.toLowerCase()}
      </span>
      <span className='popup-product__col__colour'>
        <span className='text-grey'>
          {color?.toLowerCase()}
        </span>
      </span>
    </div>
    <div className='popup-product__col popup-product__col__subtotal'>
      <span className="text-grey text-small">Subtotal:</span>
      <span className='text-orange total'>£{roundNumber(linePrice)}</span>
    </div>
    <div className='popup-product__col popup-product__reference'>
      <span className="text-grey text-small">Reference:</span>
      <span className='summary-content-text'>{reference || 'N/A'}</span>
    </div>  
    <div className='popup-product__col popup-product__length'>
      <div className="text-grey text-small">Price (per pack)</div>
      <div className="text-small summary-content-text">&pound;{roundFloat((itemPrice || 0), 2)}</div>
    </div>
    <div className='popup-product__col popup-product__area'>
      <div className="text-grey text-small">Packs</div>
      <div className="text-small summary-content-text">{quantity}</div>
    </div>
  </div>;



  const renderAccessories = () => <div className="mt-2 popup-product popup-product-accessories">
  <div className='popup-product__col popup-product__col__title'>
    <span className='popup-product__col__title__name'>
      {quality.toLowerCase()}
    </span>
    <span className='popup-product__col__colour'>
      <span className='text-grey'>
        {color?.toLowerCase()}
      </span>
    </span>
  </div>
  <div className='popup-product__col popup-product__col__subtotal'>
    <span className="text-grey text-small">Subtotal:</span>
    <span className='text-orange total'>£{roundNumber(linePrice)}</span>
  </div>
  <div className='popup-product__col popup-product__reference'>
    <span className="text-grey text-small">Reference:</span>
    <span className='summary-content-text'>{reference || 'N/A'}</span>
  </div>   
  <div className='popup-product__col popup-product__length'>
    <div className="text-grey text-small">Price (per unit)</div>
    <div className="text-small summary-content-text">&pound;{roundFloat((itemPrice || 0), 2)}</div>
  </div>
  <div className='popup-product__col popup-product__area'>
    <div className="text-grey text-small">Units</div>
    <div className="text-small summary-content-text">{quantity}</div>
  </div>
</div>;


  const renderSwitch = () => {
    const isPacksPallets = categoryCode === 'L' || categoryCode === 'N' || categoryCode === 'T';
    const isCarpets = categoryCode === 'C' || categoryCode === 'V' || categoryCode === 'G' || categoryCode === 'E' || categoryCode === 'Q';

    if (isPacksPallets) {
      return renderPacksAndPallets();
    } else if (isCarpets) {
      return renderCutsAndRolls();
    } else {
      return renderAccessories();
    }
  };

  return (
    <div className="checkout-item popup-checkout-item w-100">
      <div className="checkout-item-row w-100 d-flex justify-content-between align-items-center">
        <div className="checkout-item-left">
          {!hideAction && <div onClick={onDelete} className="underline text-grey remove-action">
                 &times;
          </div>}
          <div className="checkout-item-row__img">
            <ProductImage imageCode={imageCode} imageAlt={imageCode} />
          </div>
        </div>
        <div className="checkout-item-right">
              {renderSwitch()}
        </div>
      </div>
    </div>
  );
};

export default CardPopupCheckout;

import React, { useEffect, useState } from 'react';
import '../styles/Pricelists.scss';
import View from '../components/View';
import MobileMenuBottom from '../components/MobileBottomMenu';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCustomer } from '../actions/Customer';
import {
    getCustomer,
} from '../selectors/Customer';
import { getProductTypes } from '../selectors/ProductType';
import { useHistory } from 'react-router-dom';
import Button from '../components/Button';
import { clearBasketRequest } from '../actions/Order';
import { filterType, setCustomerID, setCustomerPostcode, useQuery } from '../helpers/functions';
import { fetchProductTypes } from '../actions/ProductType';
import c from '../helpers/constants';
import { fetchPricelist } from '../actions/Pricelist';
import { getPricelist } from '../selectors/Pricelist';
import PricelistSearch from '../components/PricelistSearch';
import { config } from '../config/config';
import PricelistTable from '../components/PricelistTable';
import leftArrow from '../assets/svgs/chevron_left.svg';
import DropDown from '../components/DropDown';
import axios from 'axios';

const Pricelist = () => {
    const query = useQuery();
    const dispatch = useDispatch();

    const p = useSelector(getCustomer);
    const customer = useSelector(getCustomer);
    const pricelistConfigs = config.hasOwnProperty('priceListConfigs') ? config.priceListConfigs : [];

    const history = useHistory();
    const productTypes = useSelector(getProductTypes);
    const [productTypeCode, setProductTypeCode] = useState<any>(null);
    const [searchSelection, setSearchSelection] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const priceList = useSelector(getPricelist);
    const documentUrl = config.DOCUMENT_BASE_URL + 'Terms & Conditions.pdf'
    const productTypeTitle = filterType(productTypes.find(x => x.product_type_code == productTypeCode)?.product_type_desc ?? '', false);

    useEffect(() => {
        dispatch(fetchCustomer());
    }, [dispatch]);

    const onEmailClick = () => {
        history.push(c.APP_ROUTES.CONTACT);
    };

    const toAccount = () => {
        history.push(c.APP_ROUTES.ACCOUNT);
    };

    const onLogout = () => {
        clearBasketRequest();
        setCustomerID('');
        setCustomerPostcode('');
        window.location.href = window.location.href;
    };

    const onChange = (evt: any) => {
        setSearchSelection(evt);
    };

    const selectCategory = (code: string) => {
        history.push({ pathname: c.APP_ROUTES.PRICELIST, search: `productTypeCode=${code}` });
        setProductTypeCode(code);
        setLoading(true);
    };

    useEffect(() => {
        if (null == productTypes || productTypes.length == 0) {
            dispatch(fetchProductTypes());
        }
    }, []);


    useEffect(() => {

        let ptc = (null == productTypeCode || '' == productTypeCode) ? (query.get('productTypeCode') || '') : productTypeCode;
        if (ptc == '') {
            selectCategory('C')
        } else {
            setProductTypeCode(ptc);
        }
        if (productTypeCode != '' && null != productTypeCode) {
            dispatch(fetchPricelist(productTypeCode));
        }

    }, [dispatch, productTypeCode]);

    useEffect(() => {
        setLoading(false);
    }, [priceList])


    //Matting shouldn't be here 
    if (customer?.is_matting) {
        history.push(c.APP_ROUTES.DASHBOARD);
    }

    let pcCode = productTypeCode == null || productTypeCode == '' ? 'C' : productTypeCode;
    let thePriceListConfig: any = pricelistConfigs?.filter((x: any) => x.keys.indexOf(pcCode) > -1);
    thePriceListConfig = null != thePriceListConfig ? thePriceListConfig[0] : null;


    let filterKeys = ['qfnam', 'dfnam', 'widths'];
    let searchSelectionText = searchSelection ? searchSelection.toLowerCase() : '';


    //Text filter
    let filteredElements = searchSelectionText != '' && priceList != null && priceList.length > 0 ? [...priceList].filter((x: any) => {
        for (let i = 0; i < filterKeys.length; i++) {
            if (x.hasOwnProperty(filterKeys[i])) {
                if (((x[filterKeys[i]] + '').toLowerCase()).indexOf(searchSelectionText) >= 0) {
                    return true;
                }
            }
        }
        return false;
    }) : (priceList != null ? [...priceList] : []);


    const activeCategories = config.activePricelistCategories;
    /*[
        'C', 'N', 'G', 'V','N','L','H','E','T','B','A','Q'
    ];*/

    let categoryItems: any[] = [];
    productTypes.filter(x => activeCategories.includes(x.product_type_code)).map(x => {
        categoryItems.push({
            label: filterType(x.product_type_desc, true),
            value: x.product_type_code
        });
    });



    useEffect(() => {
        dispatch(fetchCustomer());
    }, [dispatch]);
    return (
        <View>
            <div className="grey-container">
                <div className="container myaccount pricelists">

                    <div className="myaccount-header">
                        <div className="myaccount-header__title">
                            <h3>
                                <div className='d-flex align-items-center justify-content-start' onClick={() => toAccount()}>
                                    <div>
                                        <img src={leftArrow} alt='Left Arrow' className='mr-2  pricelist-back-link' />
                                    </div>
                                    <div className='pricelist-back-link'>
                                        My Account
                                    </div>
                                </div>
                            </h3>
                        </div>
                        <div className="myaccount-header__logout">
                            <Button text="Logout" type="grey" onClick={onLogout} />
                        </div>
                    </div>

                    <div className="row ">
                        <div className="col-lg-3  pb-5 myaccount__content__nav">
                            <div className="account-nav-outer">
                                <h3 className='colour-title'>Pricelists</h3>
                                <ul>
                                    {productTypes && productTypes.filter(x => activeCategories.includes(x.product_type_code)).map((x, index) => (
                                        <li className="pb-2" key={index}><a onClick={() => selectCategory(x.product_type_code)} className={productTypeCode == x.product_type_code ? 'active' : ''}>{filterType(x.product_type_desc.toLowerCase(), true).toLowerCase()}</a></li>
                                    ))}
                                    <li className="pb-2"><a href={documentUrl} target='_blank'>Terms &amp; Conditions of Sale</a></li>
                                </ul></div>
                        </div>
                        <div className="col-lg-9">
                            <p className="pb-4 pricelist-title">{productTypeTitle?.toLowerCase()} Pricelist</p>

                            <div className='card-dropdown pb-4'>
                                <DropDown items={categoryItems} value={productTypeCode}
                                    placeholder="Select another address"
                                    onSelect={(item: any) => {
                                        selectCategory(item.value);
                                    }} />
                            </div>

                            <div className='card pricelist-card'>
                                <div className='card-body'>

                                    <div className='card-searchbox'>
                                        <PricelistSearch term={searchSelection} onChange={onChange} placeholder={productTypeTitle?.toLocaleLowerCase()} />
                                    </div>
                                    <div className='card-searchbox__results'>
                                        {(thePriceListConfig?.rows && filteredElements && !loading) && <>
                                            <PricelistTable rows={thePriceListConfig?.rows} data={filteredElements}></PricelistTable>
                                        </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="contact-bottom">
                <div className="contact-bottom__title">
                    <h3>Can&apos;t find what you&apos;re looking for?</h3>
                </div>
                <div className="contact-bottom__desc">
                    <p>
                        Our support team would be happy to help, simply call or email us.
                    </p>
                </div>
                <div className="contact-bottom__cta">
                    <div className="contact-bottom__cta__left">
                        <Button text="Call Us" onClick={onEmailClick} />
                    </div>
                    <div className="contact-bottom__cta__left">
                        <Button text="Email Us" onClick={onEmailClick} />
                    </div>
                </div>
            </div>

            <MobileMenuBottom />
        </View>
    );
};

export default Pricelist;

import {
    FETCH_COLOURGROUP_REQUEST,
    FETCH_COLOURGROUP_RESPONSE,
    FETCH_COLOURGROUP_FAILURE,
    ColourGroupState,
    ColourGroupActionTypes,
  } from '../types/ColourGroup';
  
  const initialState: ColourGroupState = {
    colourGroups: null,
    isFetchingColourGroup: false,
    fetchingColourGroupFailed: false,
  };
  
  const colourGroupsReducer = (
    state = initialState,
    action: ColourGroupActionTypes,
  ): ColourGroupState => {
    switch (action.type) {
      case FETCH_COLOURGROUP_REQUEST:
        return {
          ...state,
          colourGroups: null,
          isFetchingColourGroup: true,
          fetchingColourGroupFailed: false,
        };
      case FETCH_COLOURGROUP_RESPONSE:
        return {
          ...state,
          colourGroups: action.payload || null,
          isFetchingColourGroup: false,
          fetchingColourGroupFailed: false,
        };
      case FETCH_COLOURGROUP_FAILURE:
        return {
          ...state,
          colourGroups: null,
          isFetchingColourGroup: false,
          fetchingColourGroupFailed: true,
        };
      default:
        return state;
    }
  };
  
  export default colourGroupsReducer;
  
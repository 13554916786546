export const priceListConfig = [
  {
    keys: ['C', 'V', 'G', 'E', 'Q'],
    rows: [
      {
        key: ['qfnam', 'dfnam'],
        title: 'Product',
      },
      {
        key: 'widths',
        title: 'Width (m)',
        action: { name: 'replace', data: [',', '/'] }
      },
      {
        key: 'list-rprice',
        title: 'List Price Rolls<br/>£m<sup>2</sup>',
        action: { name: 'formatCurrency' }
      },
      {
        key: 'list-cprice',
        title: 'List Price Cuts<br/>£m<sup>2</sup>',
        action: { name: 'formatCurrency' }
      },
      {
        key: 'best-rprice',
        highlight: true,
        title: 'Your Price Rolls<br/>£m<sup>2</sup>',
        action: { name: 'formatCurrency' }
      },
      {
        key: 'best-cprice',
        highlight: true,
        title: 'Your Price Cuts<br/>£m<sup>2</sup>',
        action: { name: 'formatCurrency' }
      },
    ]
  },
  {
    keys: ['N'],
    rows: [
      {
        key: 'qfnam',
        title: 'Product',
      },
      {
        key: 'list-oprice',
        title: 'Pack Price<br/>£m<sup>2</sup>',
        action: { name: 'formatCurrency' }
      },
      {
        key: 'list-pprice1',
        title: 'Pallet Price<br/>£m<sup>2</sup>',
        action: { name: 'formatCurrency' }
      },
      {
        key: 'best-oprice',
        title: 'Your Pack Price<br/>£m<sup>2</sup>',
        highlight: true,
        action: { name: 'formatCurrency' }
      },
      {
        key: 'best-pprice1',
        title: 'Your Pallet Price<br/>£m<sup>2</sup>',
        highlight: true,
        action: { name: 'formatCurrency' }
      },
    ]
  },
  {
    keys: ['H'],
    rows: [
      {
        key: ['qfnam', 'dfnam'],
        title: 'Product',
      },
      {
        key: 'list-oprice',
        title: 'List Price (Roll / Box)',
      },
      {
        key: 'packsandpallets',
        highlight: false,
        title: 'QTY Break Pricing ( Roll / Box )',
        options: [
          {
            name: 'Your Pallet Price',
            key: 'best-pprice1',
            qty: 'best-palqty1'
          },
          {
            name: 'Your Pallet Price',
            key: 'best-pprice2',
            qty: 'best-palqty2',
            highlight: true,
          },
          {
            name: 'Your Pallet Price',
            key: 'best-pprice3',
            qty: 'best-palqty3',
            highlight: true,
          },
          {
            name: 'Your Pallet Price',
            key: 'best-pprice4',
            qty: 'best-palqty4',
            highlight: true,
          }
        ]
      },
    ]
  },
  {
    keys: ['L'],
    rows: [
      {
        key: 'qfnam',
        title: 'Product',
      },
      {
        title: 'Pack Price<br/>£m<sup>2</sup>',
        key: 'list-oprice',
        action: { name: 'formatCurrency' }
      },
      {
        title: 'Pallet Price<br/>£m<sup>2</sup>',
        key: 'list-pprice1',
        qty: 'list-palqty1',
        action: { name: 'formatCurrency' }

      },

      {
        title: 'Your Pack Price<br/>£m<sup>2</sup>',
        key: 'best-oprice',
        highlight: true,
        action: { name: 'formatCurrency' }
      },
      {
        key: 'packsandpallets',
        highlight: false,
        title: 'QTY Break Pricing<br/>£m<sup>2</sup>',
        options: [
          {
            name: 'Your Pallet Price',
            key: 'best-pprice1',
            qty: 'best-palqty1',
            highlight: true,
          },
          {
            name: 'Your Pallet Price',
            key: 'best-pprice2',
            qty: 'best-palqty2',
            highlight: true,
          },
          {
            name: 'Your Pallet Price',
            key: 'best-pprice3',
            qty: 'best-palqty3',
            highlight: true,
          },
          {
            name: 'Your Pallet Price',
            key: 'best-pprice4',
            qty: 'best-palqty4',
            highlight: true,
          }
        ]
      },
    ]
  },
  {
    keys: ['J','A', 'B'],
    rows: [
      {
        key: ['qfnam', 'dfnam'],
        title: 'Product',
      },
      {
        title: 'List Price',
        key: 'list-oprice',
        action: { name: 'formatCurrency' }
      },  
      {
        title: 'Your Price',
        key: 'best-oprice',
        highlight: true,
        action: { name: 'formatCurrency' }
      },
      {
        key: 'packsandpallets',
        highlight: false,
        title: 'QTY Break Pricing',
        options: [
          {
            name: 'Your Pallet Price',
            key: 'best-pprice1',
            qty: 'best-palqty1',
            highlight: true,
          },
          {
            name: 'Your Pallet Price',
            key: 'best-pprice2',
            qty: 'best-palqty2',
            highlight: true,
          },
          {
            name: 'Your Pallet Price',
            key: 'best-pprice3',
            qty: 'best-palqty3',
            highlight: true,
          },
          {
            name: 'Your Pallet Price',
            key: 'best-pprice4',
            qty: 'best-palqty4',
            highlight: true,
          }
        ]
      },
    ]
  },
  {
    keys: ['E', 'T', 'R',  'O'],
    rows: [
      {
        key: 'qfnam',
        title: 'Product',
      },
      {
        title: 'Pack Price<br/>£m<sup>2</sup>',
        key: 'list-oprice'
      },
      {
        title: 'Pallet Price<br/>£m<sup>2</sup>',
        key: 'list-pprice1',
        qty: 'list-palqty1'
      },
      {
        title: 'Your Pack Price<br/>£m<sup>2</sup>',
        key: 'best-oprice',
        highlight: true,
      },
      {
        key: 'packsandpallets',
        highlight: false,
        title: 'QTY Break Pricing<br/>£m<sup>2</sup>',
        options: [
          {
            name: 'Your Pallet Price',
            key: 'best-pprice1',
            qty: 'best-palqty1',
            highlight: true,
          },
          {
            name: 'Your Pallet Price',
            key: 'best-pprice2',
            qty: 'best-palqty2',
            highlight: true,
          },
          {
            name: 'Your Pallet Price',
            key: 'best-pprice3',
            qty: 'best-palqty3',
            highlight: true,
          },
          {
            name: 'Your Pallet Price',
            key: 'best-pprice4',
            qty: 'best-palqty4',
            highlight: true,
          }
        ]
      },
    ]
  },

];
import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_SUMMARY_FAILURE,
  FETCH_SUMMARY_REQUEST,
  FETCH_SUMMARY_RESPONSE,
  Summary,
  SummaryActionTypes,
} from '../types/Summary';

import { RootState } from '../store/configureStore';
import { getCustomerID, isAuthenticated } from '../helpers/functions';
import history from '../helpers/history';
import c from '../helpers/constants';
import { getAccountSummary } from '../api/SummaryApi';

export function fetchSummaryRequest(): SummaryActionTypes {
  return {
    type: FETCH_SUMMARY_REQUEST,
    payload: null,
  };
}

export function fetchSummaryResponse(
  summary: Summary,
): SummaryActionTypes {
  return {
    type: FETCH_SUMMARY_RESPONSE,
    payload: summary,
  };
}

export function fetchSummaryFailure(): SummaryActionTypes {
  return {
    type: FETCH_SUMMARY_FAILURE,
    payload: null,
  };
}

export const fetchSummary = ():
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchSummaryRequest());

    
    if (!isAuthenticated()) {
      history.push(c.APP_ROUTES.LOGIN);
    }
    const finalCustomerId = getCustomerID() || '';
    const asyncResp: any = await getAccountSummary(finalCustomerId);

    let summary = {} as Summary;
    if (null != asyncResp.data && undefined !== asyncResp.data){
      let data = asyncResp.data;

      summary = {
        account_balance: data.account_balance,
        current: data.current,
        thirty_days: data['30_days'],
        sixty_days: data['60_days'],
        ninety_days: data['90_days'],
        onetwenty_days: data['120_days'],
        un_inv_orders: data.un_inv_orders,
        held_orders: data.held_orders,
        turnover_ytd: data.turnover_ytd,
        os_cash: data.os_cash
      };

    }
    
    if (asyncResp.status === 200) {
      await dispatch(fetchSummaryResponse(summary));
    } else {
      await dispatch(fetchSummaryFailure());
    }
  };

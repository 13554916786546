import React from 'react';
import './DashboardBannerItem.scss';

type Props = {
  image: string;
  mobImage: string;
  link: string;
  active: boolean;
};
const DashboardBannerItem = (props: Props) => {

  const { image, mobImage, link, active } = props;



  return (
    <div className={active == true ? 'dashbanner--item active' : 'dashbanner--item'}>
      {link != '' && null != link ? <a href={link}></a> : ''}
      <div className='dasboardbanner--image'>
        {/* Previously using a background-img
        <div className='dasboardbanner--image' style={{
        backgroundImage: `url("${image}")`,
      }}> */}
        <img src={image} />
      </div>
      <div className='dasboardbanner--image__mob'>
        {/* Previously using a background-img
        <div className='dasboardbanner--image__mob' style={{
        backgroundImage: `url("${mobImage}")`,
      }}> */}
        <img src={mobImage}></img>
      </div>
    </div>
  );
};

export default DashboardBannerItem;

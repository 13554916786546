import React, { useEffect, useState } from 'react';
import './TextArea.scss';


type Props = {
  value?: string | number | null;
  onBlur?: (e: any) => void;
  onChange?: (e: any) => void;
  placeholder?: string;
  name?: string;
  id?: string;
  label?: string;
  required?: boolean;
  bgColor?: 'grey' | 'white';
  style?: 'rounded' | 'normal';
  disabled?: boolean;
};


const TextArea = (props: Props) => {
  const {
    onBlur = () => { },
    onChange = () => { },
    disabled = false,
    required = false,
    placeholder = '',
    value = '',
    name = '',
    id = '',
    label = '',
    bgColor = 'grey',
  } = props;
  const [hasError, setHasError] = useState(false);
  const innerOnChange = (e: any) => {
    if (required) {
      setHasError(e.target.value.length <= 0);
    }
    onChange(e.target.value);
  };
  const innerOnBlur = (e: any) => {
    if (required) {
      setHasError(e.target.value.length <= 0);
    }
    onBlur(e.target.value);
  };

  useEffect(() => {
    if (required) {
      setHasError(!value);
    }
  }, []);


  return (
    <div className={'text-area-container'}>
      {label && <label htmlFor={id} className={`form-label ${hasError && 'error-lbl'}`}>{label}{required && '*'}</label>}

      <textarea disabled={disabled} required={required} className={`form-control ${bgColor} ${hasError ? 'error': ''}`} value={null == value ? '' : value} name={name} id={id}
        onBlur={innerOnBlur}
        onChange={innerOnChange}
        placeholder={placeholder}
      >
      </textarea>


    </div>
  );
};

export default TextArea;

import React, { useEffect } from 'react';
import _, { debounce } from 'lodash';
import { useHistory } from 'react-router-dom';
import '../styles/Dashboard.scss';
import c from '../helpers/constants';
import View from '../components/View';
import Button from '../components/Button';
import Card from '../components/Card';
import { config } from './../config/config';
import CardOrderFull from '../components/CardOrderFull';
import CardWide from '../components/CardWide';

import SearchBarFilter from '../components/SearchBarFilter';

import { getCustomer } from '../selectors/Customer';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCustomer } from '../actions/Customer';
import { getCategoryImage } from '../helpers/functions';
import { getOrganisedOrders } from '../selectors/Order';
import { fetchOrders } from '../actions/Order';
import formatDate from 'date-fns/format';
import parseIso from 'date-fns/parseISO';
import DashboardBanner from '../components/DashboardBanner';
import { getAvailableProductTypes } from '../selectors/AvailableType';
import RotatingBanner from '../components/RotatingBanner';
import { Product } from '../types/Product';

import { roundFloat, roundNumber } from '../helpers/functions';
import HandVBanner from '../components/HandVBanner';

const Dashboard = () => {
    const history = useHistory();
    const onNavigate = (code: string) => {
        if( code == 'SP'){
            history.push({ pathname: c.APP_ROUTES.CLEARANCE });
            return;
        }
        history.push({ pathname: c.APP_ROUTES.SEARCH, search: `productTypeCode=${code}` });
    };

    const p = useSelector(getCustomer);
    const organisedOrders = useSelector(getOrganisedOrders);
    const finalOrders = _.chain(Object.keys(organisedOrders).sort((a, b) => parseInt(b) - parseInt(a))).take(config.dashboard.activities.maxInList).value();
    const productTypeList = useSelector(getAvailableProductTypes);
    const dispatch = useDispatch();
    const customer = useSelector(getCustomer);

    useEffect(() => {
        dispatch(fetchCustomer());
        dispatch(fetchOrders());
    }, [dispatch]);

    let staticProducts = customer?.is_matting ? [
        { name: 'Mats & Runners', image: getCategoryImage('O'), code: 'O' },
        { name: 'Matting', image: config.imgMatting, code: 'Q' },
        { name: 'Rugs', image: getCategoryImage('R'), code: 'R' },
        { name: 'Carpet Tiles', image: config.imgCarpetTiles, code: 'T' },
        { name: 'Adhesives & Screeds', image: config.imgScreed, code: 'B' },
        { name: 'Accessories', image: getCategoryImage('AM'), code: 'A' },
    ] : [
        { name: 'Carpet', image: config.imgCarpet, code: 'C' },
        { name: 'Vinyl', image: config.imgVinyl, code: 'V' },
        { name: 'Laminate', image: config.imgLaminate, code: 'N' },
        { name: 'Luxury Vinyl Tile', image: config.imgLVT, code: 'L' },
        { name: 'Underlay', image: getCategoryImage('H'), code: 'H' },
        { name: 'Artificial Grass', image: config.imgGrass, code: 'G' },
        { name: 'Contract', image: config.imgContractFloors, code: 'E' },
        { name: 'Carpet Tiles', image: config.imgCarpetTiles, code: 'T' },
        { name: 'Adhesives & Screeds', image: config.imgScreed, code: 'B' },
        { name: 'Accessories', image: getCategoryImage('A'), code: 'A' },
        { name: 'Rugs', image: getCategoryImage('R'), code: 'R' },
        { name: 'Matting', image: config.imgMatting, code: 'Q' },
        { name: 'Mats & Runners', image: getCategoryImage('O'), code: 'O' },
    ];

    if( config.source.indexOf('handv') > -1 ){
        staticProducts = [
            { name: 'Carpet', image: config.imgCarpet, code: 'C' },
            { name: 'Artificial Grass', image: config.imgGrass, code: 'G' },
            { name: 'Vinyl', image: config.imgVinyl, code: 'V' },
            { name: 'Laminate', image: config.imgLaminate, code: 'N' },
        ];    
    };


    //Filter based on the current user
    let staticCount = 0;
    const totalCount = staticProducts.length;

    staticProducts = staticProducts.filter(x => {
        let idx = productTypeList.findIndex(y => y.product_type_code == x.code);
        if (idx > -1) {
            if (config.dashboard.categories.maxInList === -1 || config.dashboard.categories.maxInList > staticCount) {
                staticCount++;
                return true;
            }
        }
        return false;
    });


    const onSearch = (term: string) => {
        history.push({ pathname: c.APP_ROUTES.SEARCH, search: `searchSelection=${term}` });
    };

    const goTo = (product: Product) => {
        history.push({ pathname: c.APP_ROUTES.PRODUCT_CUT, search: `sku=${product.product_sku || product.IMAGE_code}` });
    };


    return (
        <View>
            {
               (config.showHomeBanner && customer) &&
                    <>
                        {
                            config.site == 'handv' && <HandVBanner></HandVBanner>
                        }
                        {
                            config.site != 'handv' && <>
                                {
                                    !customer.is_matting &&  <DashboardBanner></DashboardBanner> 
                                }
                                    {
                                    customer.is_matting &&  <RotatingBanner text={''} images={config.mattingBanner} /> 
                                }
                            </>
                        }
                    </>
                
            }
           
            <div className="container hero-categories-container">
                <div className="hero-section">
                    <div className="hero-section__title text-center">
                        {config.categories.search.labels.superTitle &&
                            <div className="supertitle">
                                <h4>
                                    {config.categories.search.labels.superTitle}
                                </h4>
                            </div>}
                        <h1 className="hero-section__title__header">
                            Welcome <span className='text-orange'>{_.startCase(_.toLower(customer?.NAME))}</span>
                        </h1>
               
                    </div>
                    <SearchBarFilter onSearch={onSearch} onChange={onSearch} styleName="search-page" onNavigate={(x) => goTo(x)} />
                </div>

                <div className="categories">
                    <div className="categories__title">
                        {totalCount > staticCount &&
                            <div className="category-counts">Showing {staticCount} of {totalCount}</div>
                        }
                        <h3 className="subtitle ">Product Categories</h3>
                    </div>
                    <div className="row gy-4 categories__row ">
                        {customer && staticProducts.map((prod) => (
                            <div key={prod.name} className="col-lg-4 col-md-6 col-sm-6 center-align">
                                <Card
                                    title={prod.name}
                                    onClick={() => onNavigate(prod.code)}
                                    imageUrl={getCategoryImage(prod.code + (customer && customer.is_matting && prod.code == 'A' ? 'M' : ''))}
                                    imageAlt={prod.name}
                                />
                            </div>
                        ))}
                        {
                            (customer && config.enableSpecials) && 
                            <div key={'clearance'} className="col-lg-4 col-md-6 col-sm-6 center-align">
                            <Card
                                title={'Clearance & Specials'}
                                onClick={() => onNavigate('SP')}
                                imageUrl={getCategoryImage( 'SP' ) }
                                imageAlt={'Clearance & Specials'}
                            />
                        </div>
                        }
                    </div>
                </div>
                <div className="categories-cta">
                    <Button onClick={() => history.push(c.APP_ROUTES.PRODUCTS)} text={config.dashboard.categories.labels.viewAll} />
                </div>
            </div>

            {/* Activities Section - with reduced details on orders on customer information section */}
            {config.dashboard.activities.view === 'quick' &&
                <div className="container-fluid activities pt-4">
                    <div className="row">
                        <div className="col-lg-1 col-md-1" />
                        <div className="col-lg-5 col-md-12 col-sm-12">
                            <div className="activities__latest">
                                {config.dashboard.activities.labels.superTitle &&
                                    <h4 className="supertitle">
                                        {config.dashboard.activities.labels.superTitle}
                                    </h4>
                                }
                                <h3 className="subtitle">{config.dashboard.activities.labels.title}</h3>
                                {finalOrders?.map(o =>
                                    <div key={o} onClick={() => history.push(c.APP_ROUTES.ORDERS)}><CardWide
                                        orderNumber={o}
                                        // @ts-ignore
                                        date={formatDate(parseIso(_.first(organisedOrders[o])?.date_ordered), 'do MMM yyy')}
                                        checkedAlt="check"
                                    /></div>)}

                                <div className="activities-cta pb-5">
                                    <Button text="View All" onClick={() => history.push(c.APP_ROUTES.ORDERS)} />
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12 col-sm-12 activities-details">
                            <h3 className="pb-4">Business Details</h3>
                            <p className="pb-3">{_.startCase(_.toLower(p?.NAME))}</p>

                            <div className="row">
                                <div className="col-6">
                                    <p>{_.startCase(_.toLower(p?.address_1))}</p>
                                    <p>{_.startCase(_.toLower(p?.address_2))}</p>
                                    <p>{_.startCase(_.toLower(p?.town))}</p>
                                    <p>{_.startCase(_.toLower(p?.county))}</p>
                                    <p>{p?.pcode}</p>
                                </div>
                            </div>
                            <h3 className="py-4">Contact Details</h3>
                            <div className="row pb-4">
                                <div className="col-6">
                                    <p>{_.startCase(_.toLower(p?.contact_name))}</p>
                                    <p>{p?.contact_mob}</p>
                                    <p>{p?.contact_tel}</p>
                                    <p>{p?.contact_email}</p>
                                </div>
                            </div>

                            <Button text="Request Update" />
                        </div>

                    </div>
                </div>}

            {/* Activities Section - with full details on orders */}
            {config.dashboard.activities.view === 'full' &&
                <div id="activities-full" className="activities-full pt-4">
                    <div className="activities-full-label">
                        {config.dashboard.activities.labels.superTitle &&
                            <h4 className="supertitle">
                                {config.dashboard.activities.labels.superTitle}
                            </h4>
                        }
                        <h3 className="subtitle">{config.dashboard.activities.labels.title}</h3>
                        <div className="activities-cta pb-5">
                            <Button text={config.dashboard.activities.labels.viewAll} onClick={() => history.push(c.APP_ROUTES.ORDERS)} />
                        </div>
                    </div>
                    <div className="activities-full-list">
                        {finalOrders?.map(o =>
                            <div key={o}>
                                <CardOrderFull
                                    orderNumber={o}
                                    // @ts-ignore
                                    orderLines={organisedOrders[o]}
                                    // @ts-ignore
                                    status={_.first(organisedOrders[o]).order_status}
                                    // @ts-ignore
                                    items={organisedOrders[o].length}
                                    // @ts-ignore
                                    total={roundNumber(organisedOrders[o].reduce((runningTotal, line) => {
                                        return runningTotal + line.line_value;
                                    }, 0))}
                                    // @ts-ignore
                                    deliveryDate={formatDate(parseIso(_.first(organisedOrders[o])?.delivery_date), 'do MMM yyy')}
                                    // @ts-ignore
                                    date={formatDate(parseIso(_.first(organisedOrders[o])?.date_ordered), 'do MMM yyy')}
                                    checkedAlt="check"
                                />
                            </div>)}
                    </div>
                </div>}

            {/* Payments Section */}
            {/*<div className="payments container">*/}
            {/*    <div className="row payments__row">*/}
            {/*        <div className="col-lg-4 col-md-12 col-sm-12">*/}
            {/*            <h3 className="subtitle pb-3">Last Payments</h3>*/}
            {/*            <p className="pb-4">*/}
            {/*                Donec id elit non mi porta gravida at eget metus. Maecenas*/}
            {/*                faucibus.*/}
            {/*            </p>*/}
            {/*            <Button text="View All"/>*/}
            {/*        </div>*/}
            {/*        <div className="col-lg-8 col-md-12 col-sm-12">*/}
            {/*            <div className="row">*/}
            {/*                <div className="col-lg-6 col-md-12 col-sm-12">*/}
            {/*                    <CardSmall*/}
            {/*                        orderID="#01345"*/}
            {/*                        status="Overdue"*/}
            {/*                        date="10/02/21"*/}
            {/*                        amount="£000.00"*/}
            {/*                        color="#E22929"*/}
            {/*                    />*/}
            {/*                </div>*/}
            {/*                <div className="col-lg-6 col-md-12 col-sm-12">*/}
            {/*                    <CardSmall*/}
            {/*                        orderID="#01345"*/}
            {/*                        status="Overdue"*/}
            {/*                        date="10/02/21"*/}
            {/*                        amount="£000.00"*/}
            {/*                        color="#E22929"*/}
            {/*                    />*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </View>
    );
};

export default Dashboard;

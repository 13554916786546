import { RootState } from '../store/configureStore';

export const getProducts = (state: RootState) => state?.product?.products;

export const getProduct = (state: RootState) => state?.product?.product;

export const isFetchingProducts = (state: RootState) =>
  state?.product?.isFetchingProducts;

export const didFetchingProductsFailed = (state: RootState) =>
  state?.product?.fetchingProductsFailed;

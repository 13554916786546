export const SEND_EMAIL_REQUEST = 'SEND_EMAIL_REQUEST';
export const SEND_EMAIL_RESPONSE = 'SEND_EMAIL_RESPONSE';
export const SEND_EMAIL_FAILURE = 'SEND_EMAIL_FAILURE';

export interface EmailMessage {
  firstName: string;
  lastName: string;
  email: string;
  contactNo: string;
  message: string;
  rep_email: string;
  rep_name: string;
  branchEmail: string;
}

export interface EmailForgotPassword {
  contactEmail: string;
  contactName: string;
  contactTel: string;
  customerName: string;
  customerId: string;
}

export interface EmailNewPassword {
  contactEmail: string;
  contactName: string;
  contactTel: string;
  customerName: string;
  customerId: string;
  password: string;
  branchEmail: string;
}

export interface EmailNotifyStock {
  customerName: string;
  customerID: string;
  customerEmail: string;
  productQuality: string;
  productColor: string;
  productSKU: string;
  rep_email: string;
  rep_name: string;
  branchEmail: string;
  width?: string | undefined;
}

export interface EmailDeleteAccount {
  companyName: string;
  customerId: string;
  reason: string;
  password: string;
  branchEmail: string;
}

export interface EmailUpdateDetails {
  companyName: string;
  customerId: string;
  companyNo: string;
  contactNo: string;
  firstName: string;
  lastName: string;
  companyContactNo: string;
  email: string;
  addressLineOne: string;
  addressLineTwo: string;
  town: string;
  postcode: string;
  cAddressLineOne: string;
  cAddressLineTwo: string;
  cTown: string;
  cPostcode: string;
  branchEmail: string;
}


export interface EmailState {
  isSendingEmail: boolean;
  sendingEmailFailed: boolean;
}

interface SendEmailRequestAction {
  type: typeof SEND_EMAIL_REQUEST;
  payload: null;
}

interface SendEmailResponseAction {
  type: typeof SEND_EMAIL_RESPONSE;
  payload: null;
}

interface SendEmailFailureAction {
  type: typeof SEND_EMAIL_FAILURE;
  payload: null;
}

export type EmailActionTypes = SendEmailRequestAction | SendEmailFailureAction | SendEmailResponseAction;

import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_AVAILABLE_BANKS_FAILURE,
  FETCH_AVAILABLE_BANKS_RESPONSE,
  FETCH_AVAILABLE_BANKS_REQUEST,
  AvailableBanks,
  AvailableBanksAction,
} from '../types/AvailableBanks';

import { RootState } from '../store/configureStore';
import { getAvailableBanks, triggerPaymentAction } from '../api/AvailableBanksApi';
import { getCustomerID, isAuthenticated } from '../helpers/functions';
import history from '../helpers/history';
import c from '../helpers/constants';

export function fetchAvailableBanksRequest(): AvailableBanksAction {
  return {
    type: FETCH_AVAILABLE_BANKS_REQUEST,
    payload: null,
  };
}

export function fetchAvailableBanksResponse(
  availableBanks: AvailableBanks[],
): AvailableBanksAction {
  return {
    type: FETCH_AVAILABLE_BANKS_RESPONSE,
    payload: availableBanks,
  };
}

export function fetchAvailableBanksFailure(): AvailableBanksAction {
  return {
    type: FETCH_AVAILABLE_BANKS_FAILURE,
    payload: null,
  };
}

export const fetchAvailableBanks = ():
  ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchAvailableBanksRequest());
    if (!isAuthenticated()) {
      try {
        history.push(c.APP_ROUTES.LOGIN);
      } catch (e) {
      }
    }
    const finalCustomerId = getCustomerID() || '';
    const asyncResp: any = await getAvailableBanks(finalCustomerId);

    if (asyncResp) {
      await dispatch(fetchAvailableBanksResponse(asyncResp));
    } else {
      await dispatch(fetchAvailableBanksFailure());
    }
  };




export const payOrder = (orderNumber: number):
  ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const orderLines = getState().order.basket;
    if (!isAuthenticated()) {
      history.push(c.APP_ROUTES.LOGIN);
    }


    const asyncResp = await triggerPaymentAction(orderNumber);
    console.log(asyncResp);
    if (asyncResp.return_code === 'OK') {
    } else {
    }
  };
import React from 'react';
import './View.scss';
import Header from '../Header';
import Footer from '../Footer';
import MobileMenuBottom from '../MobileBottomMenu';
import CookieConsent from 'react-cookie-consent';
import c from '../../helpers/constants';
import { NavLink } from 'react-router-dom';
import { config } from '../../config/config';

const View = (props: any) => {

  return (
    <div>
      <div className="">
        <div className="">
          <div className="no-padding">
            <Header />
            <div className="main">{props.children}</div>
            <div className="footer-mobile-nav">
              <MobileMenuBottom />
            </div>
            <Footer />          
          </div>
        </div>
      </div>
      <CookieConsent
              location='bottom'
              buttonText='Accept'
              cookieName='cookieconsentCookie'
              style={{ background: '#403f3e', paddingLeft:'20px', paddingRight:'20px' }}
              buttonStyle={{ background: config.fillColour, color: '#FFF', borderRadius:'10px', fontSize: '14px' }}
              expires={150}             
              enableDeclineButton
              declineButtonText='Opt Out'
              
              onDecline={() => {
                let x = document.getElementById('tagmanager');
                if (x != null){
                  x.remove(); 
                }
                let y = document.getElementById('tagmanager-script');
                if (y != null){
                  y.remove(); 
                }
              }}
            >
              This website uses cookies to enhance the user experience. <NavLink to={c.APP_ROUTES.COOKIES}>Learn More</NavLink> 
        </CookieConsent>
    </div>
  );
};

export default View;

import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_STOCK_FAILURE,
  FETCH_STOCK_RESPONSE,
  FETCH_STOCK_REQUEST,
  Stock,
  StockActionTypes,
} from '../types/Stock';

import { RootState } from '../store/configureStore';
import { getCustomerID, isAuthenticated } from '../helpers/functions';
import { getStock } from '../api/StockApi';
import history from '../helpers/history';
import c from '../helpers/constants';

export function fetchStockRequest(): StockActionTypes {
  return {
    type: FETCH_STOCK_REQUEST,
    payload: null,
  };
}

export function fetchStockResponse(
  stock: Stock,
): StockActionTypes {
  return {
    type: FETCH_STOCK_RESPONSE,
    payload: stock,
  };
}

export function fetchStockFailure(): StockActionTypes {
  return {
    type: FETCH_STOCK_FAILURE,
    payload: null,
  };
}

export const fetchStock = (sku: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchStockRequest());
    if (!isAuthenticated()) {
      history.push(c.APP_ROUTES.LOGIN);
    }
    const finalCustomerId = getCustomerID() || '';
    const asyncResp: any = await getStock(finalCustomerId, sku);
    if (asyncResp.status === 200) {
      await dispatch(fetchStockResponse(asyncResp.data));
    } else {
      await dispatch(fetchStockFailure());
    }
  };

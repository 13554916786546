export const FETCH_CUSTOMER_REQUEST = 'FETCH_CUSTOMERS_REQUEST';
export const FETCH_CUSTOMER_RESPONSE = 'FETCH_CUSTOMERS_RESPONSE';
export const FETCH_CUSTOMER_FAILURE = 'FETCH_CUSTOMERS_FAILURE';

export interface DeliveryAddress {
  addcode: string;
  NAME: string;
  address_1: string;
  address_2: string,
  town: string;
  county: string;
  pcode: string;
  country: string;
  del_days?: boolean[];
}


export interface Customer {
  NAME: string;
  address_1: string;
  address_2: string,
  town: string;
  county: string;
  pcode: string;
  country: string,
  contact_name: string;
  contact_tel: string;
  contact_mob: string;
  contact_email: string;
  sales_group: string;
  branch_address1: string;
  branch_address2: string;
  branch_email: string;
  branch_name: string;
  branch_pcode: string;
  branch_phone: string;
  branch_town: string;
  buying_group: string,
  rep_name: string;
  rep_Tel: string,
  rep_email: string;
  credit_limit: string;
  delivery_instructions: string,
  take_rolls: string,
  take_4m_rolls: string,
  take_pallets: string,
  max_delivery_wgt: string,
  delivery_address_code: string;
  payment_terms: string;
  lw_bank_name: string,
  lw_bank_sortcode: string,
  lw_bank_account: string,
  delivery_addresses: DeliveryAddress[],
  drop_ship: boolean,
  is_matting: boolean,
  which_passwd: string
}

export interface CustomerState {
  customer: Customer | null,
  isFetchingCustomer: boolean;
  fetchingCustomerFailed: boolean;
}

interface FetchCustomerRequestAction {
  type: typeof FETCH_CUSTOMER_REQUEST;
  payload: null;
}

interface FetchCustomerResponseAction {
  type: typeof FETCH_CUSTOMER_RESPONSE;
  payload: Customer;
}

interface FetchCustomerFailureAction {
  type: typeof FETCH_CUSTOMER_FAILURE;
  payload: null;
}

export type CustomerActionTypes =
  | FetchCustomerRequestAction
  | FetchCustomerResponseAction
  | FetchCustomerFailureAction;

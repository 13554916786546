import React from 'react';
import { Dropdown } from 'react-bootstrap';
import './DropDown.scss';

type Item = {
  label: string;
  value: any;
};

type Props = {
  items: Item[],
  value: any,
  onSelect: (item: Item) => void,
  placeholder?: string;
};

const DropDown = (props: Props) => {
  const { items, onSelect, value, placeholder } = props;
  const selected = items.find(i => i.value === value);

  return (
    <div>
      <Dropdown>
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          {selected && <span dangerouslySetInnerHTML={{ __html: !selected ? '' : ((selected.label == '25/12/49' || selected.label == '27/12/49') ? 'TBA' : selected.label) }}></span> || placeholder || 'Please select..'}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {items.map(item => <Dropdown.Item key={item.value} onClick={() => onSelect(item)}><span dangerouslySetInnerHTML={{ __html: ((item.label == '25/12/49' || item.label == '27/12/49') ? 'TBA' : item.label) }}></span></Dropdown.Item>)}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default DropDown;

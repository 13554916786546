import React, { useState }  from 'react';
import './DashboardBanner.scss';
import DashboardBannerItem from '../DashboardBannerItem';
import { config } from '../../config/config';

const DashboardBanner = () => {
  const [bannerIdx, setBanner] = useState<number>(0);

  const banners = config.homeBanners as Array<any>;

  const doPrev = () => {
    if (bannerIdx - 1 < 0 ){
      setBanner(banners.length - 1);
      return;
    }
    setBanner(bannerIdx - 1);    
  };

  const goTo = (idx: number) => {
    setBanner(idx);
  };

  const doNext = () => {
    if (bannerIdx + 1 > (banners.length - 1)){
      setBanner(0);
      return;
    }
    setBanner(bannerIdx + 1);    
  };

  return (
        <div className="dashbanner">          
            { 
              banners && banners?.map((x, i) => 
                <DashboardBannerItem key={i}  link={ x.link } image={ x.image }  mobImage={ x.smallImage } active={i == bannerIdx}></DashboardBannerItem>,
              )
            }     
            { banners.length > 1 &&
            <div className='dashbanner--controls'>
              <a onClick={() => doPrev()}><span className='previous'>&#8963;</span></a>
              <div className='dashbanner--controls--numbers'>
                { banners.map((x, i) => (
                  <a onClick={() => goTo(i)} key={i}>
                    <span className={bannerIdx == i ? 'number is-active' : 'number'}>{i + 1}</span>
                    <span className={bannerIdx == i ? 'circle bg-orange' : 'circle circle-empty'}></span>
                  </a>))}
              </div>
              <a onClick={() => doNext()}><span className='next'>&#8963;</span></a>
            </div>
           }
        </div>

  );
};

export default DashboardBanner;

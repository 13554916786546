import React, { useEffect } from 'react';
import c from '../helpers/constants';
import history from '../helpers/history';
import '../styles/Products.scss';
import View from '../components/View';
import PageHeader from '../components/PageHeader';
import SearchBarFilter from '../components/SearchBarFilter';
import Card from '../components/Card';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { getCategoryImage } from '../helpers/functions';
import { didFetchingProductTypesFailed, getProductTypes, isFetchingProductTypes } from '../selectors/ProductType';
import { fetchProductTypes } from '../actions/ProductType';
import { config } from './../config/config';

import { ProductType } from '../types/ProductType';
import { getAvailableProductTypes } from '../selectors/AvailableType';
import { getCustomer } from '../selectors/Customer';
import { Product } from '../types/Product';
import { fetchCustomer } from '../actions/Customer';


const Products = () => {
    const fetchingProductTypes = useSelector(isFetchingProductTypes);
    let productTypes = useSelector(getProductTypes);
    const fetchingFailed = useSelector(didFetchingProductTypesFailed);
    const productTypeList = useSelector(getAvailableProductTypes);
    const customer = useSelector(getCustomer);



    const onClick = (productType: ProductType) => {
        history.push({ pathname: `${c.APP_ROUTES.SEARCH}`, search: `?productTypeCode=${productType.product_type_code}` });
    };

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchCustomer());
        dispatch(fetchProductTypes());
    }, [dispatch]);




    const onSearch = (term: string) => {
        history.push({ pathname: c.APP_ROUTES.SEARCH, search: `searchSelection=${term}` });
    };


    //Filter based on the current user
    productTypes = productTypes.filter(x => {
        let idx = productTypeList.findIndex(y => y.product_type_code == x.product_type_code);
        return idx > -1;
    });

    if (config.source.indexOf('handv') > -1) {
        let staticProducts = ['C', 'G', 'V', 'N'];
        productTypes = productTypes.filter((a: ProductType) => {
            return staticProducts.indexOf(a.product_type_code) > -1;
        });
    };

    if (customer && customer.is_matting) {
        let staticProducts = ['O', 'Q', 'R', 'T', 'B', 'A'].reverse();
        productTypes = productTypes.sort((a: ProductType, b: ProductType) => {
            return staticProducts.indexOf(a.product_type_code) < staticProducts.indexOf(b.product_type_code) ? 1 : -1
        });
    }


    const goTo = (product: Product) => {
        history.push({ pathname: c.APP_ROUTES.PRODUCT_CUT, search: `sku=${product.product_sku || product.IMAGE_code}` });
    };

    const onNavigate = (code: string) => {
        if (code == 'SP') {
            history.push({ pathname: c.APP_ROUTES.CLEARANCE });
            return;
        }
        history.push({ pathname: c.APP_ROUTES.SEARCH, search: `productTypeCode=${code}` });
    };


    return (
        <View>
            <PageHeader text={config.categories.header.labels.title} imageUrl={config.imgProducts} />
            <div className="container products">
                <div className="products__header">
                    {config.categories.search.labels.superTitle &&
                        <div className="supertitle">
                            <h4>
                                {config.categories.search.labels.superTitle}
                            </h4>
                        </div>}
                    <h3 className="products__header__title pb-5">
                        {config.categories.search.labels.title}
                    </h3>
                    <div className="products__header__search">
                        <SearchBarFilter onSearch={onSearch} onChange={onSearch} results={[]} onNavigate={(x) => goTo(x)} />
                    </div>
                </div>
                {fetchingProductTypes && !fetchingFailed &&
                    <div className="row">
                        <div className="col-12 mb-5 text-center">
                            <div className="spinner-border text-center text-dark" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                }
                <div className="row gy-4 products__row">
                    {productTypes?.map((p) => (
                        <div key={p.product_type_code} className="col-lg-4 col-md-6 col-sm-6">
                            <Card
                                title={(_.toLower(p?.product_type_desc)).replace(/\w+/g, _.capitalize)}
                                imageUrl={customer && customer.is_matting && p.product_type_code == 'A' ? getCategoryImage('AM') : getCategoryImage(p.product_type_code)}
                                imageAlt={p.product_type_desc}
                                onClick={() => onClick(p)}
                            />
                        </div>
                    ))}
                    {
                        (customer && config.enableSpecials) &&
                        <div key={'clearance'} className="col-lg-4 col-md-6 col-sm-6 center-align">
                            <Card
                                title={'Clearance & Specials'}
                                onClick={() => onNavigate('SP')}
                                imageUrl={getCategoryImage('SP')}
                                imageAlt={'Clearance & Specials'}
                            />
                        </div>
                    }
                </div>
            </div>

        </View>
    );
};

export default Products;

export const FETCH_COLOURGROUP_REQUEST = 'FETCH_COLOURGROUPS_REQUEST';
export const FETCH_COLOURGROUP_RESPONSE = 'FETCH_COLOURGROUPS_RESPONSE';
export const FETCH_COLOURGROUP_FAILURE = 'FETCH_COLOURGROUPS_FAILURE';

export interface ColourGroup {
  code: string;
  name: string;
}

export interface ColourGroupState {
  colourGroups: ColourGroup[] | null,
  isFetchingColourGroup: boolean;
  fetchingColourGroupFailed: boolean;
}

interface FetchColourGroupRequestAction {
  type: typeof FETCH_COLOURGROUP_REQUEST;
  payload: null;
}

interface FetchColourGroupResponseAction {
  type: typeof FETCH_COLOURGROUP_RESPONSE;
  payload: ColourGroup[];
}

interface FetchColourGroupFailureAction {
  type: typeof FETCH_COLOURGROUP_FAILURE;
  payload: null;
}

export type ColourGroupActionTypes =
  | FetchColourGroupRequestAction
  | FetchColourGroupResponseAction
  | FetchColourGroupFailureAction;

import React, { useEffect } from 'react';
import '../styles/About.scss';
import View from '../components/View';
import tile from './../assets/images/Carpet.png';
import Rolls from '../assets/images/rolls.png';
import Play from '../assets/images/play.png';
import Worker from '../assets/images/worker.png';
import Vinyl from './../assets/images/Vinyl.png';
import Laminate from './../assets/images/Laminate.png';
import Card from '../components/Card';
import { Link } from 'react-router-dom';

const About = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
  return (
        <View>
            <div className="about">
                <div className="container">
                    <div className="row about__hero">
                        <div className="col-lg-6 pb-3 order-lg-1 order-2">
                            <div className="about__hero__left">
                                <div className="about__hero__left__line"></div>
                                <h1>
                                    Likewise Floors are one of the largest floorcovering
                                    distributors in UK
                                </h1>
                            </div>
                        </div>
                        <div className="col-lg-6 order-lg-2 order-sm-1">
                            <img src={Rolls} alt="Carpets" className="about__hero__image"/>
                        </div>
                    </div>
                    <div className="row about__desc">
                        <div className="col-lg-6 pb-4">
                            <p>
                                We hold stocks of a huge range of flooring products sourced
                                globally from world class manufacturers covering both
                                residential and commercial sectors. Our extensive product range
                                includes carpet, vinyl, laminate, LVT, artificial grass and
                                more.
                            </p>
                        </div>
                        <div className="col-lg-6">
                            <p>
                                We have an expanding distribution network covering most of the UK offering a next day
                                delivery service. Our team of experts and customer care team are available to help with
                                any enquiries you may have. Please give us a call or drop us an email.
                            </p>
                        </div>
                    </div>
                    <div className="row about__img__section gy-4">
                        <div className="col-lg-6">
                            <img
                                src={Worker}
                                alt="Carpets"
                                className="about__img__section__image"
                            />
                        </div>
                        <div className="col-lg-6">
                            <video poster={Play} width="100%" style={{ height: '25rem', objectFit: 'contain', backgroundColor: '#DEDEDE' }} controls>
                                <source src="https://likewisefloors.co.uk/likewise-intro.mp4" type="video/mp4" />
                            </video>
                        </div>
                    </div>
                    <div className="row py-5 gy-4 about__products">
                        <div className="col-lg-4 col-md-6">
                            <Link to="/products" className="no-decoration">
                                <Card title="Carpet" imageUrl={tile} imageAlt="My Product"/>
                            </Link>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <Link to="/products" className="no-decoration">
                                <Card title="Vinyl" imageUrl={Vinyl} imageAlt="My Product"/>
                            </Link>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <Link to="/products" className="no-decoration">
                                <Card title="Laminate" imageUrl={Laminate} imageAlt="My Product"/>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </View>
  );
};

export default About;

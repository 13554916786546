import React from 'react';
import { Modal } from 'react-bootstrap';
import CloseButtonSVG from '../../assets/svgs/modal-close.svg';
import './Modal.scss';

type Props = {
  title: string;
  children: any,
  onClose: () => void;
  show: boolean;
};
const LWModal = (props: Props) => {
  return (
        <Modal size="lg" show={props.show}>
            <Modal.Header closeButton={false}>
                <Modal.Title>{props.title}</Modal.Title>
                <img onClick={props.onClose} className="lw-modal-close-button" src={CloseButtonSVG}
                     alt="modal-close-button"/>
            </Modal.Header>
            <Modal.Body>
                {props.children}
            </Modal.Body>
        </Modal>
  );
};

export default LWModal;

import React from 'react';
import c from '../../helpers/constants';
import './MobileMenuBottom.scss';
import Home from './../../assets/svgs/homeLW.svg';
import Search from './../../assets/svgs/searchLW.svg';
import List from './../../assets/svgs/list-textLW.svg';
import User from './../../assets/svgs/userLW.svg';
import {  NavLink } from 'react-router-dom';

const MobileMenuBottom = () => {
  return (
    <div className="footer-mobile-nav">
      <div className="menu-bottom">
        <div className="home-icon">
          <NavLink to={c.APP_ROUTES.DASHBOARD} exact activeClassName="active">  
            <img src={Home} alt="Home" />
          </NavLink>       
        </div>
        <div className="search-icon">
          <NavLink to={c.APP_ROUTES.SEARCH}>
            <img src={Search} alt="Search" />
          </NavLink>
        </div>        
        <div className="list-icons">
          <NavLink to={c.APP_ROUTES.PRODUCTS} exact activeClassName="active">
            <img src={List} alt="List" />
          </NavLink>          
        </div>
        <div className="user-icons">
          <NavLink to={c.APP_ROUTES.ACCOUNT} exact activeClassName="active">
            <img src={User} alt="User" />
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default MobileMenuBottom;

import React, { useEffect, useState } from 'react';
import './CardCheckout.scss';
import ProductImage from '../ProductImage';
import { roundFloat, roundNumber } from '../../helpers/functions';
import DropDown from '../DropDown';
import dateFormat from 'date-fns/format';
import exclamation from '../../assets/svgs/excmark.svg';
import { config } from '../../config/config';

type Props = {
  quality: string;
  lineNo: number;
  category?: string;
  categoryCode?: string;
  imageCode: string;
  linePrice: number;
  itemPrice: number;
  length: number | null;
  width: number | null;
  quantity: number;
  color?: string;
  reference: string;
  onChange?: (field: string, val: string) => void;
  onDelete?: () => void;
  hideAction?: boolean;
  deliveryDates?: string[];
  deliveryDate: string;
  collection: boolean;
  sqmPrice: number | null;
  poDates?: any[];
  isDropShip?: boolean;
};

const CardCheckout = (props: Props) => {

  const {
    lineNo = 0,
    hideAction = false,
    deliveryDates,
    deliveryDate,
    quality,
    category,
    categoryCode,
    imageCode,
    color = '',
    // packs = null,
    linePrice,
    length = 0,
    width = 0,
    // pallets = null,
    quantity,
    itemPrice = 0,
    sqmPrice = 0,
    onChange = () => { },
    onDelete = () => { },
    reference = '',
    collection = false,
    poDates = [],
    isDropShip = false
  } = props;

  const filteredPoDates = isDropShip ? [poDates[0]] : poDates;
  const filteredDeliveryDates = deliveryDates ? (isDropShip ? [deliveryDates[0]] : deliveryDates) : [];

  const renderCutsAndRolls = () =>
    <>
      <div>
        <div>
          Width
        </div>
        <div className='bold'>{(width || 0)}</div>
      </div>

      <div>
        <div>
          Length
        </div>
        <div className='bold'>{(length || 0)}</div>
      </div>

      <div className="checkout-item-bottom__sqm">
        <div>
          SQM
        </div>
        <div className='bold'>{roundFloat((width || 0) * (length || 0), 1)}</div>
      </div>


      <div>
        <div>
          <span>Price (per sqm)</span>
        </div>
        <div className='bold'>£{itemPrice.toFixed(2)}</div>
      </div>


      {!hideAction && <div className='checkout-item-remove_action'>
        <div onClick={onDelete} className="underline text-grey">
          Remove
        </div>
      </div>}


    </>;

  const renderPacksAndPallets = () => <div className="flex-row d-flex w-100">
    <div className="checkout-item-bottom__sqm">
      <p style={{ marginBottom: 0 }}>
        <span>Packs</span>
      </p>
      <span className='bold'>{quantity}</span>
    </div>
    <div className="checkout-item-bottom__sqm">
      <p style={{ marginBottom: 0 }}>
        <span>Price (per pack)</span>
      </p>
      <p className='bold'>£{itemPrice.toFixed(2)}</p>
    </div>
    {(sqmPrice != null && sqmPrice > 0) &&
      <div className="checkout-item-bottom__sqm">
        <p style={{ marginBottom: 0 }}>
          <span>Price (per SQM)</span>
        </p>
        <p className='bold'>£{sqmPrice ? sqmPrice.toFixed(2) : 0.00}</p>
      </div>
    }
    <div className="checkout-item-remove_action">
      {!hideAction && <div onClick={onDelete} className="underline text-grey">
        Remove
      </div>}
    </div>
  </div>;


  const renderAccessories = () => <div className="flex-row d-flex w-100">
    <div className="checkout-item-bottom__sqm">
      <p style={{ marginBottom: 0 }}>
        <span>QTY</span>
      </p>
      <p className='bold'>{quantity}</p>
    </div>
    <div className="checkout-item-bottom__sqm">
      <p style={{ marginBottom: 0 }}>
        <span>Price (per unit)</span>
      </p>
      <p className='bold'>£{itemPrice.toFixed(2)}</p>
    </div>
    <div className="checkout-item-remove_action">
      {!hideAction && <div onClick={onDelete} className="underline text-grey">
        Remove
      </div>}
    </div>
  </div>;

  const renderSwitch = () => {
    const isPacksPallets = categoryCode === 'L' || categoryCode === 'N' || categoryCode === 'T';
    const isCarpets = categoryCode === 'C' || categoryCode === 'V' || categoryCode === 'G' || categoryCode === 'E' || categoryCode === 'Q';

    if (isPacksPallets) {
      return renderPacksAndPallets();
    } else if (isCarpets) {
      return renderCutsAndRolls();
    } else {
      return renderAccessories();
    }
  };

  return (
    <div className="checkout_item">

      <div className='checkout_item__inner'>

        <div className="checkout_item_img">
          <ProductImage imageCode={imageCode} imageAlt={imageCode} size='small' />
        </div>

        <div className="checkout_item_content">

          <div className="checkout_item_row_1">

            <div className="checkout_item_descr">
              {
                config.showCategoryInlineOnCheckout == false &&

                < p className="text-grey">{category}</p>
              }
              <p>
                <b>{quality} {color} {
                  config.showCategoryInlineOnCheckout == true && <>
                    {category}
                  </>
                }</b>
              </p>
            </div>

            <div className="checkout_item_detail">
              <div className="checkout_item_detail_row">
                {renderSwitch()}
              </div>
            </div>

          </div>

          <div className="checkout_item_row_2">

            <div className="checkout_item_descr">
              <div>Reference</div>
              <div className="text-orange">{reference}</div>
            </div>

            <div className="checkout_item_detail">
              <div className="checkout_item_detail_row">
                <div>
                  <div>
                    <label className="control-label mb-1">{(poDates && poDates.length > 0) ? 'Scheduled ' : ''}Shipping</label>
                  </div>
                  <div>
                    {(deliveryDates && (!poDates || poDates.length == 0)) &&
                      <div className="shipping_method">
                        <div>
                          <DropDown
                            items={filteredDeliveryDates?.map(d => ({ label: d, value: d }))}
                            value={deliveryDate}
                            onSelect={(item) => (onChange('deliveryDate', item.value))}
                            placeholder={(!collection) ? "Delivery Date" : "Collection Date"}
                          />
                        </div>
                      </div>
                    }

                    {(poDates && poDates.length > 0) &&
                      <div className="shipping_method">
                        <div>
                          <DropDown
                            items={filteredPoDates?.map(d => ({ label: d, value: d }))}
                            value={deliveryDate}
                            onSelect={(item) => (onChange('deliveryDate', item.value))}
                            placeholder={(!collection) ? "Preferred Delivery Date" : "Preferred Collection Date"}
                          />
                        </div>
                      </div>
                    }


                    {!deliveryDates ? dateFormat(new Date(deliveryDate || '2022-01-01'), 'do MMM yyy') : ''}
                  </div>
                </div>

                <div>
                  <div>
                    Total (Excl VAT)
                  </div>
                  <div>
                    <span className='bold'>£{roundNumber(linePrice)}</span>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>

      </div>
      {
        (poDates && poDates.length > 0) &&
        <div className='alert alert-warning cart-alert mt-3'>
          <img src={exclamation} className='mr-3 exclamation' /><strong>Pre-Order Product:</strong> We are currently awaiting stock to arrive from the manufacturers and we will contact you nearer the time to confirm your delivery date. We aim to deliver your order on your preferred delivery date selected, however this is only estimated until stock arrives. If you have any questions, please contact our team.
        </div>
      }

    </div >
  );
};

export default CardCheckout;

export const FETCH_STOCK_REQUEST = 'FETCH_STOCK_REQUEST';
export const FETCH_STOCK_RESPONSE = 'FETCH_STOCK_RESPONSE';
export const FETCH_STOCK_FAILURE = 'FETCH_STOCK_FAILURE';

export interface DueDate {
  delivery_date: Date,
}

export interface Roll {
  roll_length: number;
  roll_width: number;
  roll_serno: string;
  roll_id: string;
  roll_flag: string;
  roll_availability: string;
  roll_expected_stock_date?: string;
  roll_prcflag?:string;
  roll_prc?:number;
}

export interface Pallet {
  pallet_expected_stock_date?: string ;
  pallet_qty: number;
  pallet_id: string;
  pallet_batchno: string;
  pallet_availability: string;
  pallet_prcflag?:string;
  pallet_prc?:number;
}

export interface Stock {
  stock_availablity: string;
  stock_expected_date: string;
  delivery_date: DueDate[];
  roll: Roll[];
  pallet: Pallet[];
}

export interface StockState {
  stock: Stock | null,
  isFetchingStock: boolean;
  fetchingStockFailed: boolean;
}

interface FetchStockRequestAction {
  type: typeof FETCH_STOCK_REQUEST;
  payload: null;
}

interface FetchStockResponseAction {
  type: typeof FETCH_STOCK_RESPONSE;
  payload: Stock;
}

interface FetchStockFailureAction {
  type: typeof FETCH_STOCK_FAILURE;
  payload: null;
}

export type StockActionTypes =
  | FetchStockRequestAction
  | FetchStockResponseAction
  | FetchStockFailureAction;

import { createStore, applyMiddleware, combineReducers, Action } from 'redux';
import thunk, { ThunkAction } from 'redux-thunk';
import productTypeReducer from '../reducers/ProductType';
import productReducer from '../reducers/Product';
import orderReducer from '../reducers/Order';
import statementReducer from '../reducers/Statement';
import customerReducer from '../reducers/Customer';
import stockReducer from '../reducers/Stock';
import priceReducer from '../reducers/Price';
import emailReducer from '../reducers/Email';
import summaryReducer from '../reducers/Summary';
import availableTypeReducer from '../reducers/AvailableType';
import colourGroupsReducer from '../reducers/ColourGroup';
import pricelistReducer from '../reducers/Pricelist';
import availableBanksReducer from '../reducers/AvailableBanks';

const rootReducer = combineReducers({
  productType: productTypeReducer,
  product: productReducer,
  order: orderReducer,
  statement: statementReducer,
  customer: customerReducer,
  stock: stockReducer,
  price: priceReducer,
  email: emailReducer,
  summary: summaryReducer,
  availableProductType: availableTypeReducer,
  colourGroups: colourGroupsReducer,
  priceLists: pricelistReducer,
  availableBanks: availableBanksReducer
});

export type RootState = ReturnType<typeof rootReducer>;

const store = createStore(rootReducer, applyMiddleware(thunk));

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;

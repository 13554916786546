import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_PRICE_FAILURE,
  FETCH_PRICE_RESPONSE,
  FETCH_PRICE_REQUEST,
  Price,
  PriceActionTypes,
} from '../types/Price';

import { RootState } from '../store/configureStore';
import { getCustomerID, isAuthenticated } from '../helpers/functions';
import { getPrice } from '../api/PriceApi';
import history from '../helpers/history';
import c from '../helpers/constants';

export function fetchPriceRequest(): PriceActionTypes {
  return {
    type: FETCH_PRICE_REQUEST,
    payload: null,
  };
}

export function fetchPriceResponse(
  price: Price,
): PriceActionTypes {
  return {
    type: FETCH_PRICE_RESPONSE,
    payload: price,
  };
}

export function fetchPriceFailure(): PriceActionTypes {
  return {
    type: FETCH_PRICE_FAILURE,
    payload: null,
  };
}

export const fetchPrice = (sku: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchPriceRequest());
    if (!isAuthenticated()) {
      history.push(c.APP_ROUTES.LOGIN);
    }
    const finalCustomerId = getCustomerID() || '';
    const asyncResp: any =  await getPrice(finalCustomerId, sku);
    if (asyncResp?.status === 200) {
      await dispatch(fetchPriceResponse(asyncResp.data));
    } else {
      await dispatch(fetchPriceFailure());
    }
  };

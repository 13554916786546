/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import _ from 'lodash';
import './OrderSummary.scss';
import formatDate from 'date-fns/format';
import parseIso from 'date-fns/parseISO';
import { Order } from '../../types/Order';
import ProductImage from '../ProductImage';
import StatusLight from '../StatusLight';

type Props = {
    orderLines: Order[];
    orderNumber: string;
};

const OrderSummary = (props: Props) => {
    const {
        orderLines = [],
        orderNumber,
    } = props;
    const first = _.first(orderLines);
    let dateLast = '';
    let dateDelivery = '';
    if (first) {
        dateLast = formatDate(parseIso(first.date_ordered), 'do MMM yyy');
        dateDelivery = formatDate(parseIso(first.delivery_date), 'do MMM yyy');
    }
    // @ts-ignore
    let total = 0;
    orderLines.forEach((cur) => total += cur.line_value);


    //Statuses that require us to display the sub status 
    const specialStatuses = ['Awaiting Stock', 'On Hold'];

    const renderCutsAndRolls = (item: Order) => {
        return <>
            <div className="px-5 pb-2">
                <p className="text-grey-small">Product</p>
                <div className="product-row">
                    <p className="text-grey-smaller">{item.product_desc}</p>
                    <p className="text-grey-smaller"><span className='text-black mr-2'>Reference:</span>{item.line_reference || 'N/A'}</p>
                </div>
            </div>
            <div className="px-5">
                <div className="d-flex border-bottom-yay w-100 justify-content-between">
                    <div className="product-row text-start">
                        <ProductImage className="product-row__img" imageCode={`${item.product_sku}`.trim()} size='small' />
                    </div>
                    <div className="w-minus-img">

                        <div className="row g-0 order-summary__table__content ">
                            <div className="col-2">
                                <div className="product-row">
                                    <p className="text-grey-small">Width(m)</p>
                                    <p className="text-grey-smaller">{item.WIDTH}</p>
                                </div>
                            </div>
                            <div className="col-2">
                                <div className="product-row">
                                    <p className="text-grey-small">Length(m)</p>
                                    <p className="text-grey-smaller">{item.LENGTH}</p>
                                </div>
                            </div>
                            <div className="col-1">
                                <div className="product-row">
                                    <p className="text-grey-small">SQM</p>
                                    <p className="text-grey-smaller">{item.sqm}</p>
                                </div>
                            </div>
                            <div className="col-2">
                                <div className="product-row">
                                    <p className="text-grey-small">Price Per SQM</p>
                                    <p className="text-grey-smaller">£{item.price_per_sqm ? item.price_per_sqm.toFixed(2) : 0.00}</p>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="product-row">
                                    <p className="text-grey-small">Status</p>
                                    <StatusLight status={specialStatuses.includes(item.order_status) ? item.order_sub_status : item.order_status} />
                                </div>
                            </div>
                            <div className="col-2">
                                <div className="product-row">
                                    <p className="text-grey-small">Total</p>
                                    <p className="text-grey-smaller">
                                        £{item.line_value ? (parseFloat("" + item.line_value)).toFixed(2) : 0.00} Excl. VAT
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>;
    };

    const renderPacksAndPallets = (item: Order) => {
        return <>
            <div className="px-5 pb-2">
                <p className="text-grey-small">Product</p>
                <div className="product-row">
                    <p className="text-grey-smaller">{item.product_desc}</p>
                    <p className="text-grey-smaller"><span className='text-black mr-2'>Reference:</span>{item.line_reference || 'N/A'}</p>
                </div>
            </div>
            <div className="px-5">
                <div className="d-flex border-bottom-yay w-100 justify-content-between">
                    <div className="product-row text-start">
                        <ProductImage className="product-row__img" imageCode={`${item.product_sku}`.trim()} />
                    </div>
                    <div className="w-minus-img">
                        <div className="row g-0 order-summary__table__content">

                            <div className="col-2">
                                <div className="product-row">
                                    <p className="text-grey-small">Packs</p>
                                    <p className="text-grey-smaller">{item.qty_ordered}</p>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="product-row">
                                    <p className="text-grey-smal">Packs</p>
                                    <p className="text-grey-smaller">£{item.line_price ? item.line_price.toFixed(2) : 0.00}</p>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="product-row">
                                    <p className="text-grey-small">Status</p>
                                    <StatusLight status={specialStatuses.includes(item.order_status) ? item.order_sub_status : item.order_status} />
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="product-row">
                                    <p className="text-grey-small">Total</p>
                                    <p className="text-grey-smaller">
                                        £{item.line_value ? (parseFloat("" + item.line_value)).toFixed(2) : 0.00} Excl. VAT
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>;
    };

    const renderAccessories = (item: Order) => {
        return <>
            <div className="px-5 pb-2">
                <p className="text-grey-small">Product</p>
                <div className="product-row">
                    <p className="text-grey-smaller">{item.product_desc}</p>
                    <p className="text-grey-smaller"><span className='text-black mr-2'>Reference:</span>{item.line_reference || 'N/A'}</p>
                </div>
            </div>
            <div className="px-5">
                <div className="d-flex border-bottom-yay w-100 justify-content-between">
                    <div className="product-row text-start">
                        <ProductImage className="product-row__img" imageCode={`${item.product_sku}`.trim()} />
                    </div>
                    <div className="w-minus-img">
                        <div className="row g-0 order-summary__table__content">

                            <div className="col-2">
                                <div className="product-row">
                                    <p className="text-grey-small">QTY</p>
                                    <p className="text-grey-smaller">{item.qty_ordered}</p>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="product-row">
                                    <p className="text-grey-small">Price Per Unit</p>
                                    <p className="text-grey-smaller">£{item.line_price ? item.line_price.toFixed(2) : 0.00}</p>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="product-row">
                                    <p className="text-grey-small">Status</p>
                                    <StatusLight status={specialStatuses.includes(item.order_status) ? item.order_sub_status : item.order_status} />
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="product-row">
                                    <p className="text-grey-small">Total</p>
                                    <p className="text-grey-smaller">
                                        £{item.line_value ? (parseFloat("" + item.line_value)).toFixed(2) : 0.00} Excl. VAT
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>;
    };

    const renderSwitch = (item: Order) => {
        const isPacksPallets = item.product_type_code === 'L' || item.product_type_code === 'N' || item.product_type_code === 'T';
        const isCarpets = item.product_type_code === 'C' || item.product_type_code === 'V' || item.product_type_code === 'G' || item.product_type_code === 'E' || item.product_type_code === 'Q';
        if (isCarpets) {
            return renderCutsAndRolls(item);
        } else if (isPacksPallets) {
            return renderPacksAndPallets(item);
        } else {
            return renderAccessories(item);
        }
    };

    const reference = orderLines[0].order_reference;

    return (
        <div className="order-summary py-4 px-4">
            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-6">Order Number: <span className="medium-grey-text">{orderNumber}</span></div>
                        <div className="col-md-6 text-end">Total: <span className="medium-grey-text">£{total.toFixed(2)}</span></div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-12">
                            Order Date: <b>{dateLast}</b> <span className="date-separator">|</span> Scheduled Delivery Date: <b>{dateDelivery}</b>
                        </div>
                        <div className="col-md-12">
                            Order Reference: <b>{reference != '' ? reference : 'N/A'}</b>
                        </div>
                    </div>
                </div>
                <div className="hr-line"></div>
                <div className="row g-0 order-summary__table">
                    <div className="order-summary__table__header pb-3 px-5">
                        <div className="order-summary__table__header__left">
                            <p className="text-orange">
                                <b>Order Summary</b>
                            </p>
                        </div>
                    </div>
                    {orderLines.map((o) => renderSwitch(o))}
                </div>
            </div>
        </div>
    );
};

export default OrderSummary;

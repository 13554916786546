import React, { useEffect } from 'react';
import '../styles/MyAccount.scss';
import '../styles/Passwordreset.scss';

import View from '../components/View';
import Button from '../components/Button';
import MobileMenuBottom from '../components/MobileBottomMenu';
import MyAccountHeader from '../components/MyAccountHeader';
// import CardSmall from '../components/CardSmall';
import AccountNav from '../components/AccountNav';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCustomer } from '../actions/Customer';
import {
    getCustomer,
} from '../selectors/Customer';
import c from '../helpers/constants';
import history from '../helpers/history';
import ChangePassword from '../components/ChangePassword';


const ChangePasswordView = () => {
    const p = useSelector(getCustomer);

    const onEmailClick = () => {
        history.push(c.APP_ROUTES.CONTACT);
    };

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchCustomer());
    }, [dispatch]);
    return (
        <View>
            <div className="grey-container">
                <div className="myaccount">
                    <MyAccountHeader title={'My Account'} />
                    <div className='myaccount-container container'>
                        <div className="row ">
                            <div className="col-lg-3  pb-5 myaccount__content__nav">
                                <AccountNav />
                            </div>
                            <div className="col-lg-9  myaccount-summary password-reset">
                                <p className="pb-4 account-information-title" >Change Password</p>
                                <h4 className="font-bold pb-5">Password Reset Request</h4>
                                <ChangePassword></ChangePassword>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="contact-bottom">
                <div className="contact-bottom__title">
                    <h3>Can&apos;t find what you&apos;re looking for?</h3>
                </div>
                <div className="contact-bottom__desc">
                    <p>
                        Our support team would be happy to help, simply call or email us.
                    </p>
                </div>
                <div className="contact-bottom__cta">
                    <div className="contact-bottom__cta__left">
                        <Button text="Call Us" onClick={onEmailClick} />
                    </div>
                    <div className="contact-bottom__cta__left">
                        <Button text="Email Us" onClick={onEmailClick} />
                    </div>
                </div>
            </div>
            <MobileMenuBottom />
        </View>
    );
};

export default ChangePasswordView;

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './app/store/configureStore';
import App from './app/components/App/App';
import { HashRouter, Router } from 'react-router-dom';
import history from './app/helpers/history';
import 'bootstrap/dist/css/bootstrap.min.css';

declare let window: any;

const renderReactDom = () => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <HashRouter>
          <App />
        </HashRouter>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
};

const renderDesktopDom = () => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <Router history={history}>
          <App />
        </Router>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
  );

};
if (window.cordova) {
  document.addEventListener('deviceready', () => {
    renderReactDom();
  }, false);
} else {
  renderDesktopDom();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//console.log(reportWebVitals());

import {
  FETCH_PRICE_REQUEST,
  FETCH_PRICE_RESPONSE,
  FETCH_PRICE_FAILURE,
  PriceState,
  PriceActionTypes,
} from '../types/Price';

const initialState: PriceState = {
  price: null,
  isFetchingPrice: false,
  fetchingPriceFailed: false,
};

const priceReducer = (
  state = initialState,
  action: PriceActionTypes,
): PriceState => {
  switch (action.type) {
    case FETCH_PRICE_REQUEST:
      return {
        ...state,
        price: null,
        isFetchingPrice: true,
        fetchingPriceFailed: false,
      };
    case FETCH_PRICE_RESPONSE:
      return {
        ...state,
        price: action.payload || [],
        isFetchingPrice: false,
        fetchingPriceFailed: false,
      };
    case FETCH_PRICE_FAILURE:
      return {
        ...state,
        price: null,
        isFetchingPrice: false,
        fetchingPriceFailed: true,
      };
    default:
      return state;
  }
};

export default priceReducer;

import React from 'react';
import './CatalogueItem.scss';
import ProductImage from '../ProductImage';
import PleaseCall from '../PleaseCall';
import { template } from 'lodash';

type Props = {
  imageCode: string;
  productName: string;
  productDetails: string;
  skuCode: string;
  price: number;
  pleaseCall: boolean;
  phone: string;
  uom?: string;
  template?: string;
  onClick: () => void;
};
const CatalogueItem = (props: Props) => {

  const { imageCode, productName, productDetails, skuCode, onClick, pleaseCall, phone, uom, price, template } = props;


  const doClick = () => {

    if (pleaseCall) {
      return;
    }
    onClick();
  };

  let fullRollText = '(Full Rolls)';

  switch (template) {
    case 'Rolls-Cuts':
      fullRollText = '(Full Rolls)';
      break;
    case 'Packs-Pallets':
      fullRollText = '(Full Pallet)';
      break;
    case 'Units':
      fullRollText = '';
      break;
  }



  return (
    <div className={pleaseCall ? "please-call-item catalogueItem" : "catalogueItem"} onClick={doClick}>
      <div className="catalogueItem__img">
        <ProductImage imageCode={imageCode} skuCode={skuCode} imageAlt={productName} size='medium' />
      </div>
      <div className="catalogueItem__description text-left">
        <p>{productName.toLowerCase()}</p>
        <p>{productDetails}</p>
        {((price !== null && price !== undefined) && uom && price > 0) && <>
          <p className='cost text-orange'>&pound;{`${price}`.trim()}<small> Per {uom} {fullRollText}</small></p>
        </>
        }
      </div>
      {
        pleaseCall &&
        <PleaseCall phone={phone}></PleaseCall>
      }
    </div>
  );
};

export default CatalogueItem;

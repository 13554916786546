import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import dateFormat from 'date-fns/format';
import {
    FETCH_COLOURGROUP_FAILURE,
    FETCH_COLOURGROUP_RESPONSE,
    FETCH_COLOURGROUP_REQUEST,
    ColourGroup,
    ColourGroupActionTypes,
} from '../types/ColourGroup';


import { RootState } from '../store/configureStore';
import { getColourGroups } from '../api/ColourGroupsApi';


export function fetchColourGroupRequest(): ColourGroupActionTypes {
    return {
        type: FETCH_COLOURGROUP_REQUEST,
        payload: null,
    };
}

export function fetchColourGroupResponse(
    colourGroup: ColourGroup[],
): ColourGroupActionTypes {
    return {
        type: FETCH_COLOURGROUP_RESPONSE,
        payload: colourGroup,
    };
}

export function fetchColourGroupFailure(): ColourGroupActionTypes {
    return {
        type: FETCH_COLOURGROUP_FAILURE,
        payload: null,
    };
}

export const fetchColourGroup = (customerId?: string):
    ThunkAction<void, RootState, unknown, Action<string>> =>
    async (dispatch) => {
        dispatch(fetchColourGroupRequest());

        const asyncResp: any = await getColourGroups(customerId || '');


        if (asyncResp?.hasOwnProperty('dsColourGroups')) {

            //get matting customer - not really an ideal solution  
            let groups = asyncResp?.dsColourGroups?.['op-colour-groups'];

            let cGroups: ColourGroup[] = [];
            if (groups != null && groups.length > 0) {

                groups.map((x: any) => {
                    if( x['colour_group_code'] == 'NS'){
                        return;
                    }
                    cGroups.push({
                        code: x['colour_group_code'],
                        name: x['colour_group_name']
                    });
                });
            }
            await dispatch(fetchColourGroupResponse(cGroups));
        } else {
            await dispatch(fetchColourGroupFailure());
        }
    };

import {
  FETCH_SUMMARY_RESPONSE,
  FETCH_SUMMARY_FAILURE,
  FETCH_SUMMARY_REQUEST,
  SummaryState,
  SummaryActionTypes,
} from '../types/Summary';
  
const initialState: SummaryState = {
  summary: null,
  isFetchingSummary: false,
  fetchingSummaryFailed: false,
};
  
const summaryReducer = (
  state = initialState,
  action: SummaryActionTypes,
): SummaryState => {
  switch (action.type) {
    case FETCH_SUMMARY_REQUEST:
      return {
        ...state,
        summary: null,
        isFetchingSummary: true,
        fetchingSummaryFailed: false,
      };
    case FETCH_SUMMARY_RESPONSE:
      return {
        ...state,
        summary: action.payload || null,
        isFetchingSummary: false,
        fetchingSummaryFailed: false,
      };
    case FETCH_SUMMARY_FAILURE:
      return {
        ...state,
        summary: null,
        isFetchingSummary: false,
        fetchingSummaryFailed: true,
      };
    default:
      return state;
  }
};
 
export default summaryReducer;
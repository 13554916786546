import axios from 'axios';
import c from '../helpers/constants';
import { getApiKey, objectToQueryString } from '../helpers/functions';

export function getOrderHistory(customerId: string | null, sku?: string, dateFrom?: string, dateTo?: string, orderStatus?: string) {
  const obj = {
    customerid: encodeURIComponent(customerId ?? ''),
    productsku: encodeURIComponent( sku ? sku : ''),
    datefrom: !dateFrom ? '01/01/2020' : dateFrom,
    dateto: dateTo,
    orderstatus: orderStatus,
  };
  const queryString = objectToQueryString(obj);
  return (
    axios({
      url: `${c.API_ENDPOINTS.ORDER_HISTORY}?${queryString}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'x-api-key': getApiKey()
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


export function postSalesOrder(data: any) {
  
  return (
    axios({
      url: `${c.API_ENDPOINTS.SALES_ORDER}`,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-api-key': getApiKey()
      },
      data: JSON.stringify(data),
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { RootState } from '../store/configureStore';
import {
  EmailActionTypes,
  EmailForgotPassword,
  EmailMessage,
  EmailNotifyStock,
  EmailNewPassword,
  EmailDeleteAccount,
  EmailUpdateDetails,
  SEND_EMAIL_FAILURE,
  SEND_EMAIL_REQUEST,
  SEND_EMAIL_RESPONSE,  
} from '../types/Email';
import { forgotPassword, newPassword, notifyStock, submitMessage, deleteAccount, updateDetails } from '../api/EmailApi';

export function sendEmailRequest(): EmailActionTypes {
  return {
    type: SEND_EMAIL_REQUEST,
    payload: null,
  };
}
export function sendEmailResponse(): EmailActionTypes {
  return {
    type: SEND_EMAIL_RESPONSE,
    payload: null,
  };
}
export function sendEmailFailure(): EmailActionTypes {
  return {
    type: SEND_EMAIL_FAILURE,
    payload: null,
  };
}

export const emailSubmitMessage = (data: EmailMessage):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    try {
      dispatch(sendEmailRequest());
      await submitMessage(data);
      dispatch(sendEmailResponse());
    } catch (e) {
      console.error(e);
      dispatch(sendEmailFailure());
    }
  };

export const emailForgotPassword = (data: EmailForgotPassword):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    try {
      dispatch(sendEmailRequest());
      await forgotPassword(data);
      dispatch(sendEmailResponse());
    } catch (e) {
      console.error(e);
      dispatch(sendEmailFailure());
    }
  };

export const emailNotifyStock = (data: EmailNotifyStock):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    try {
      dispatch(sendEmailRequest());
      await notifyStock(data);
      dispatch(sendEmailResponse());
    } catch (e) {
      console.error(e);
      dispatch(sendEmailFailure());
    }
  };

export const emailNewPassword = (data: EmailNewPassword):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    try {
      dispatch(sendEmailRequest());
      await newPassword(data);
      dispatch(sendEmailResponse());
    } catch (e) {
      console.error(e);
      dispatch(sendEmailFailure());
    }
  };

export const emailDeleteAccount = (data: EmailDeleteAccount):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    try {
      dispatch(sendEmailRequest());
      await deleteAccount(data);
      dispatch(sendEmailResponse());
    } catch (e) {
      console.error(e);
      dispatch(sendEmailFailure());
    }
  };


export const emailUpdateAccount = (data: EmailUpdateDetails):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    try {
      dispatch(sendEmailRequest());
      await updateDetails(data);
      dispatch(sendEmailResponse());
    } catch (e) {
      dispatch(sendEmailFailure());
    }
  };
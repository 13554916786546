import React from 'react';
import { Button as Status } from 'react-bootstrap';
import '../styles/Payments.scss';
import View from '../components/View';
import Button from '../components/Button';
import MobileMenuBottom from '../components/MobileBottomMenu';
import MyAccountHeader from '../components/MyAccountHeader';
import AccountNav from '../components/AccountNav';

const Payments = () => {
  return (
    <View>
      <div className="grey-container">
        <div className="container myaccount">
          <MyAccountHeader title={'My Account'} />
          <div className="row pb-5">
            <div className="col-lg-3  pb-5 myaccount__content__nav">
              <AccountNav />
            </div>
            <div className="col-lg-9 ">
              <div className="row myaccount__content__body__top ">
                <div className="col-lg-4">
                  <div className="row">
                    <div className="col">
                      <p className="font-bold">All</p>
                    </div>
                    <div className="col">
                      <p className="font-bold">Unpaid</p>
                    </div>
                    <div className="col">
                      <p className="font-bold">Overdue</p>
                    </div>
                    <div className="col">
                      <p className="font-bold">Paid</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-md-6 myaccount__content__body__top__right">
                  <div className="myaccount__content__body__top__right__search">
                    <div className="myaccount__content__body__top__right__search__text">
                      <p>Search Order</p>
                    </div>
                    <div className="myaccount__content__body__top__right__search__button">
                      <Button text="Search" />
                    </div>
                  </div>
                </div>
                <div className="row pt-5">
                  <div className="col-lg-2">
                    <p className="text-grey font-bold pb-2">Order</p>
                  </div>
                  <div className="col-lg-2">
                    <p className="text-grey font-bold pb-2">Amount</p>
                  </div>
                  <div className="col-lg-2">
                    <p className="text-grey font-bold pb-2">Status</p>
                  </div>
                  <div className="col-lg-2">
                    <p className="text-grey font-bold pb-2">Due Date</p>
                  </div>
                  <div className="col-lg-2">
                    <p className="text-grey font-bold pb-2">Items</p>
                  </div>
                  <div className="col-lg-1">
                    <p className="text-grey font-bold pb-2">PDF</p>
                  </div>
                  <div className="col-lg-1">
                    <p className="text-grey font-bold">Action</p>
                  </div>
                </div>
                <hr className="my-4" />
                <div className="row">
                  <div className="col-lg-2 pb-2">
                    <p className="">Order Number: 01234</p>
                  </div>
                  <div className="col-lg-2 pb-2">
                    <p className="">£457.89</p>
                  </div>
                  <div className="col-lg-2 pb-2">
                    <Status className="btn-green">Paid</Status>
                  </div>
                  <div className="col-lg-2 pb-2">
                    <p className="">01.01.21</p>
                  </div>
                  <div className="col-lg-2 pb-2">
                    <p className="">14</p>
                  </div>
                  <div className="col-lg-1 pb-2">
                    <p className="">Invoice</p>
                  </div>
                  <div className="col-lg-1">
                    <p className=""></p>
                  </div>
                </div>
                <hr className="my-4" />
                <div className="row">
                <div className="col-lg-2 pb-2">
                    <p className="">Order Number: 01234</p>
                  </div>
                  <div className="col-lg-2 pb-2">
                    <p className="">£457.89</p>
                  </div>
                  <div className="col-lg-2 pb-2">
                    <Status className="btn-green">Paid</Status>
                  </div>
                  <div className="col-lg-2 pb-2">
                    <p className="">01.01.21</p>
                  </div>
                  <div className="col-lg-2 pb-2">
                    <p className="">14</p>
                  </div>
                  <div className="col-lg-1 pb-2">
                    <p className="">Invoice</p>
                  </div>
                  <div className="col-lg-1">
                    <p className=""></p>
                  </div>
                </div>
                <hr className="my-4" />
                <div className="row">
                <div className="col-lg-2 pb-2">
                    <p className="">Order Number: 01234</p>
                  </div>
                  <div className="col-lg-2 pb-2">
                    <p className="">£457.89</p>
                  </div>
                  <div className="col-lg-2 pb-2">
                    <Status className="btn-green">Paid</Status>
                  </div>
                  <div className="col-lg-2 pb-2">
                    <p className="">01.01.21</p>
                  </div>
                  <div className="col-lg-2 pb-2">
                    <p className="">14</p>
                  </div>
                  <div className="col-lg-1 pb-2">
                    <p className="">Invoice</p>
                  </div>
                  <div className="col-lg-1">
                    <p className=""></p>
                  </div>
                </div>
                <hr className="my-4" />
                <div className="row">
                <div className="col-lg-2 pb-2">
                    <p className="">Order Number: 01234</p>
                  </div>
                  <div className="col-lg-2 pb-2">
                    <p className="">£457.89</p>
                  </div>
                  <div className="col-lg-2 pb-2">
                    <Status className="btn-green">Paid</Status>
                  </div>
                  <div className="col-lg-2 pb-2">
                    <p className="">01.01.21</p>
                  </div>
                  <div className="col-lg-2 pb-2">
                    <p className="">14</p>
                  </div>
                  <div className="col-lg-1 pb-2">
                    <p className="">Invoice</p>
                  </div>
                  <div className="col-lg-1">
                    <p className=""></p>
                  </div>
                </div>
                <hr className="my-4" />
                <div className="row">
                <div className="col-lg-2 pb-2">
                    <p className="">Order Number: 01234</p>
                  </div>
                  <div className="col-lg-2 pb-2">
                    <p className="">£457.89</p>
                  </div>
                  <div className="col-lg-2 pb-2">
                    <Status className="btn-green">Paid</Status>
                  </div>
                  <div className="col-lg-2 pb-2">
                    <p className="">01.01.21</p>
                  </div>
                  <div className="col-lg-2 pb-2">
                    <p className="">14</p>
                  </div>
                  <div className="col-lg-1 pb-2">
                    <p className="">Invoice</p>
                  </div>
                  <div className="col-lg-1">
                    <p className=""></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="contact-bottom">
        <div className="contact-bottom__title">
          <h3>Can&apos;t find what you&apos;re looking for?</h3>
        </div>
        <div className="contact-bottom__desc">
          <p>
            Our support team would be happy to help, simply call or email us.
          </p>
        </div>
        <div className="contact-bottom__cta">
          <div className="contact-bottom__cta__left">
            <Button text="Call Us" />
          </div>
          <div className="contact-bottom__cta__left">
            <Button text="Email Us" />
          </div>
        </div>
      </div>
      <MobileMenuBottom />
    </View>
  );
};

export default Payments;

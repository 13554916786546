import React, { useEffect } from 'react';
import '../styles/TermsOfSale.scss';
import View from '../components/View';
import PageHeader from '../components/PageHeader';
import carpet from './../assets/images/tile-term.png';
import { config } from './../config/config';

const TermsOfSale = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <View>
      <PageHeader text="Terms of Sale" imageUrl={carpet} />
      <div className="container p-5">
        <p className="font-bold pb-3">Terms and Conditions</p>
        {
          <div dangerouslySetInnerHTML={{ __html: config.termsText }} />
        }
      </div>
    </View>
  );
};

export default TermsOfSale;

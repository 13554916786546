export const FETCH_SUMMARY_REQUEST = 'FETCH_SUMMARY_REQUEST';
export const FETCH_SUMMARY_RESPONSE = 'FETCH_SUMMARY_RESPONSE';
export const FETCH_SUMMARY_FAILURE = 'FETCH_SUMMARY_FAILURE';

export interface Summary {
  account_balance: number;
  current: number;
  thirty_days: number;
  sixty_days: number;
  ninety_days: number;
  onetwenty_days: number;
  un_inv_orders: number;
  held_orders: number;
  turnover_ytd: number;
  os_cash: number; 
}

export interface SummaryState {
  summary: Summary | null,
  isFetchingSummary: boolean;
  fetchingSummaryFailed: boolean;
}

interface FetchSummaryRequestAction {
  type: typeof FETCH_SUMMARY_REQUEST;
  payload: null;
}

interface FetchSummaryResponseAction {
  type: typeof FETCH_SUMMARY_RESPONSE;
  payload: Summary;
}

interface FetchSummaryFailureAction {
  type: typeof FETCH_SUMMARY_FAILURE;
  payload: null;
}

export type SummaryActionTypes =
  | FetchSummaryFailureAction
  | FetchSummaryRequestAction
  | FetchSummaryResponseAction;

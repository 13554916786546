import axios from 'axios';
import c from '../helpers/constants';
import { getApiKey, objectToQueryString } from '../helpers/functions';

export function getPrice(customerId: string, stockCode: string, lengthCutsRolls?: string, quantityUnits?: string) {
  const obj = {
    customerid: encodeURIComponent(customerId),
    stockcode: encodeURIComponent(stockCode),
    lengthcutsrolls: lengthCutsRolls,
    quantityunits: quantityUnits,
  };
  const queryString = objectToQueryString(obj);
  return (
    axios({
      url: `${c.API_ENDPOINTS.GET_PRICE}?${queryString}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'x-api-key': getApiKey()
      },
    })
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export const FETCH_PRODUCT_TYPES_REQUEST = 'FETCH_PRODUCT_TYPES_REQUEST';
export const FETCH_PRODUCT_TYPES_RESPONSE = 'FETCH_PRODUCT_TYPES_RESPONSE';
export const FETCH_PRODUCT_TYPES_FAILURE = 'FETCH_PRODUCT_TYPES_FAILURE';

export interface ProductType {
  product_type_code: string;
  product_type_desc: string;
}

export interface ProductTypeState {
  productTypes: ProductType[],
  isFetchingProductTypes: boolean;
  fetchingProductTypesFailed: boolean;
}

interface FetchProductTypesRequestAction {
  type: typeof FETCH_PRODUCT_TYPES_REQUEST;
  payload: null;
}

interface FetchProductTypesResponseAction {
  type: typeof FETCH_PRODUCT_TYPES_RESPONSE;
  payload: ProductType[];
}

interface FetchProductTypesFailureAction {
  type: typeof FETCH_PRODUCT_TYPES_FAILURE;
  payload: null;
}

export type ProductTypeActionTypes =
  | FetchProductTypesRequestAction
  | FetchProductTypesResponseAction
  | FetchProductTypesFailureAction;

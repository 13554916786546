export const FETCH_STATEMENTS_REQUEST = 'FETCH_STATEMENTS_REQUEST';
export const FETCH_STATEMENTS_RESPONSE = 'FETCH_STATEMENTS_RESPONSE';
export const FETCH_STATEMENTS_FAILURE = 'FETCH_STATEMENTS_FAILURE';

export interface Statement {
  transaction_type: string;
  transaction_date: string;
  own_reference: string;
  customer_reference: string;
  invoice_number: number;
  goods_amount: number;
  vat_amount: number;
  total_amount: number;
  balance_oustanding: number;
  due_date: string;
  transaction_status: string;
}

export interface StatementState {
  statements: Statement[],
  isFetchingStatements: boolean;
  fetchingStatementsFailed: boolean;
}

interface FetchStatementsRequestAction {
  type: typeof FETCH_STATEMENTS_REQUEST;
  payload: null;
}

interface FetchStatementsResponseAction {
  type: typeof FETCH_STATEMENTS_RESPONSE;
  payload: Statement[];
}

interface FetchStatementsFailureAction {
  type: typeof FETCH_STATEMENTS_FAILURE;
  payload: null;
}

export type StatementActionTypes =
  | FetchStatementsRequestAction
  | FetchStatementsResponseAction
  | FetchStatementsFailureAction;

export const FETCH_PRICELIST_REQUEST = 'FETCH_PRICELIST_REQUEST';
export const FETCH_PRICELIST_RESPONSE = 'FETCH_PRICELIST_RESPONSE';
export const FETCH_PRICELIST_FAILURE = 'FETCH_PRICELIST_FAILURE';


export interface Pricelist {
    product_group: string;
}

export interface PricelistState {
    prices: null | Pricelist[],
    isFetchingPrices: boolean;
    fetchingPricesFailed: boolean;
}

interface FetchPricelistRequestAction {
    type: typeof FETCH_PRICELIST_REQUEST;
    payload: null;
}

interface FetchPricelistResponseAction {
    type: typeof FETCH_PRICELIST_RESPONSE;
    payload: Pricelist[];
}

interface FetchPricelistFailureAction {
    type: typeof FETCH_PRICELIST_FAILURE;
    payload: null;
}

export type PricelistActionTypes =
    | FetchPricelistRequestAction
    | FetchPricelistResponseAction
    | FetchPricelistFailureAction;

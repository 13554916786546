import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_PRODUCT_TYPES_FAILURE,
  FETCH_PRODUCT_TYPES_RESPONSE,
  FETCH_PRODUCT_TYPES_REQUEST,
  ProductType,
  ProductTypeActionTypes,
} from '../types/ProductType';

import { RootState } from '../store/configureStore';
import { getProductTypes } from '../api/ProductTypesApi';
import { getCustomerID, isAuthenticated } from '../helpers/functions';
import history from '../helpers/history';
import c from '../helpers/constants';

export function fetchProductTypesRequest(): ProductTypeActionTypes {
  return {
    type: FETCH_PRODUCT_TYPES_REQUEST,
    payload: null,
  };
}

export function fetchProductTypesResponse(
  productTypes: ProductType[],
): ProductTypeActionTypes {
  return {
    type: FETCH_PRODUCT_TYPES_RESPONSE,
    payload: productTypes,
  };
}

export function fetchProductTypesFailure(): ProductTypeActionTypes {
  return {
    type: FETCH_PRODUCT_TYPES_FAILURE,
    payload: null,
  };
}

export const fetchProductTypes = ():
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchProductTypesRequest());
    if (!isAuthenticated()) {
      try {
        history.push(c.APP_ROUTES.LOGIN);
      } catch (e){
      }
    }
    const finalCustomerId = getCustomerID() || '';
    const asyncResp: any = await getProductTypes(finalCustomerId);
    if (asyncResp?.dsProductType) {
      await dispatch(fetchProductTypesResponse(asyncResp.dsProductType?.['op-tt-types']));
    } else {
      await dispatch(fetchProductTypesFailure());
    }
  };

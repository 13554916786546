
import { useState } from "react";
import SetupInterceptors from "../../helpers/axiosinstance";
import history from '../../helpers/history';

const NavigateFunctionComponent = (props: any)  => {

    const [ran,setRan] = useState(false);

    {/* only run setup once */}
    if(!ran){
       SetupInterceptors(history);
       setRan(true);
    }
    return <></>;
}
export default NavigateFunctionComponent;
import React, { useState } from 'react';
import './HandVBanner.scss';
import DashboardBannerItem from '../DashboardBannerItem';
import { config } from '../../config/config';

const HandVBanner = () => {
  const [bannerIdx, setBanner] = useState<number>(0);

  const banners = config.homeBanners as Array<any>;

  const setStrokeData = () => {
    const radius = 26;
    let angle = (((bannerIdx + 1) / banners.length)) * 360;
    const circumference = 2 * Math.PI * radius;
    const strokeDasharray = (angle / 360) * circumference;
    const strokeOffset = ((bannerIdx + 1) / banners.length) * circumference;

    // 6. Create dash array of two elements (combined they must equal the entire circumference).
    //    First has the length of visible portion. Second, the remaining part.
    let dash = [
      strokeDasharray,
      circumference - strokeDasharray
    ];

    return [
      dash,
      strokeOffset + 'px'
    ];
  };


  let strokeData = setStrokeData();
  const strokeDasharray = strokeData[0];
  const dashOffset = strokeData[1];

  const doPrev = () => {
    if (bannerIdx - 1 < 0) {
      setBanner(banners.length - 1);
      return;
    }
    setBanner(bannerIdx - 1);
  };

  const goTo = (idx: number) => {
    setBanner(idx);
  };

  const doNext = () => {
    if (bannerIdx + 1 > (banners.length - 1)) {
      setBanner(0);
      return;
    }
    setBanner(bannerIdx + 1);
  };

  return (
    <div className="dashbanner">
      {
        banners && banners?.map((x, i) =>
          <DashboardBannerItem key={i} link={x.link} image={x.image} mobImage={x.smallImage} active={i == bannerIdx}></DashboardBannerItem>,
        )
      }
      {banners.length > 1 &&

        <>
          <div className="rotator-inner">
            <div className="graph">
              <svg>
                <circle cx="30" cy="30" r="26" stroke-dasharray={strokeDasharray} stroke-dashoffset={dashOffset} />
              </svg>
              <span className="graph-counter">
                <span>{bannerIdx + 1}/</span><span>{banners.length}</span>
              </span>
            </div>
          </div>

          <div className='dashbanner--controls'>
            <a onClick={() => doPrev()}><span className='previous'>&#8963;</span></a>
            <div className='dashbanner--controls--numbers'>
              {banners.map((x, i) => (
                <a onClick={() => goTo(i)} key={i}>
                  <span className={bannerIdx == i ? 'number is-active' : 'number'}>{i + 1}</span>
                  <span className={bannerIdx == i ? 'circle bg-orange' : 'circle circle-empty'}></span>
                </a>))}
            </div>
            <a onClick={() => doNext()}><span className='next'>&#8963;</span></a>
          </div>
        </>
      }
    </div>

  );
};

export default HandVBanner;

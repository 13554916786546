import React, { useState } from 'react';
import './PricelistSearch.scss';

type Props = {
  term?: string;
  placeholder?:string;
  onChange?: (arg: string) => void;
  onEnter?: () => void;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
};
const PricelistSearch = (props: Props) => {
  const [value, setValue] = useState(props.term || '');
  const placeholder = props.placeholder || '';
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    props.onChange?.(e.target.value);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      props.onEnter?.();
    }
  };

  let text = "";

  let sp = placeholder.split(' ');
  let outT : any = []; 
  sp.forEach( x => {
    outT.push(x.charAt(0).toUpperCase()
    + x.slice(1) );
  });

  text = outT.join(' ');

  return (
    <div className='pricelist-search'>
      <div className="search-bar" onMouseEnter={props.onMouseOver} onMouseLeave={props.onMouseOut}>
        <div className="search-bar__left">
          <input
            onChange={onChange}
            onKeyUp={onKeyDown}
            value={props.term}
            type="text"
            autoFocus
            className="search-bar__input"
            placeholder={ text.length > 0 ? "Search " + text + " Pricelist": "Search Pricelist"}
          />
        </div>
      </div>
    </div>
  );
};

export default PricelistSearch;

import React, { useEffect } from 'react';
import '../styles/AccountSummary.scss';
import View from '../components/View';
import MobileMenuBottom from '../components/MobileBottomMenu';
import MyAccountHeader from '../components/MyAccountHeader';
// import CardSmall from '../components/CardSmall';
import AccountNav from '../components/AccountNav';
import { useDispatch } from 'react-redux';
import { fetchCustomer } from '../actions/Customer';
import AccountSummary from '../components/AccountSummary';
import { config } from '../config/config';

const AccountSummaryPage = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchCustomer());
    }, [dispatch]);
    return (
        <View>
            <div className="grey-container account-summary">
                <div className="myaccount">
                    <MyAccountHeader title={'My Account'} />
                    <div className='myaccount-container container'>
                        <div className="row ">
                            <div className="col-lg-3  pb-5 myaccount__content__nav">
                                <AccountNav />
                            </div>
                            <div className="col-lg-9">
                                <div className='row'>
                                    <div className='col-lg-7'>
                                        {config.showSubTitlesOnAccount &&
                                            <h3 className='account-information-title'>Account Summary</h3>
                                        }
                                        <AccountSummary />
                                    </div>
                                    <div className='col-lg-5'>
                                        {/* <div className='card'>
                                       <Invoices/>
                                    </div>*/}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <MobileMenuBottom />
        </View>
    );
};

export default AccountSummaryPage;

import React, { useEffect } from 'react';
import '../styles/CurrentBalance.scss';
import View from '../components/View';
import Button from '../components/Button';
import MobileMenuBottom from '../components/MobileBottomMenu';
import MyAccountHeader from '../components/MyAccountHeader';
import AccountNav from '../components/AccountNav';
import { useSelector, useDispatch } from 'react-redux';
import { fetchStatements } from '../actions/Statement';
import {
  isFetchingStatements,
  getStatements,
  didFetchingStatementsFailed,
} from '../selectors/Statement';

const Statements = () => {
  const fetchingStatements = useSelector(isFetchingStatements);
  const statements = useSelector(getStatements);
  const fetchingFailed = useSelector(didFetchingStatementsFailed);
  console.log(fetchingFailed, fetchingStatements);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchStatements());
  }, [dispatch]);
  return (
    <View>
      <div className="grey-container pb-5">
        <div className="container myaccount">
          <MyAccountHeader title={'My Account'} />
          <div className="row pb-5">
            <div className="col-lg-3 col-md-3 col-sm-12 pb-5 myaccount__content__nav">
              <AccountNav />
            </div>
            <div className="col-lg-9 col-md-9 col-sm-12 ">
              <p className="font-bold">Statement</p>
              <div className="row mt-5">
                <div className="col-lg-6 col-md-5 col-sm-5">
                  <p className="font-bold text-grey-tiny">Item</p>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3">
                  <p className="font-bold text-grey-tiny">Total</p>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-4">
                  <p className="font-bold text-grey-tiny">Action</p>
                </div>
              </div>
              <hr className="mb-3" />
              {statements?.map((p) => (
                <div key={p.invoice_number} className="row my-4 balance-row">
                  <div className="col-lg-6 col-md-5 col-sm-5">
                    <p className="font-bold text-grey-tiny">
                      {p.transaction_date}
                    </p>
                  </div>

                  <div className="col-lg-3 col-md-5 col-sm-5">
                    <p className="font-bold text-grey-tiny">
                      {p.total_amount}
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-4">
                    <Button text="Download" />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="contact-bottom">
        <div className="contact-bottom__title">
          <h3>Can&apos;t find what you&apos;re looking for?</h3>
        </div>
        <div className="contact-bottom__desc">
          <p>
            Our support team would be happy to help, simply call or email us.
          </p>
        </div>
        <div className="contact-bottom__cta">
          <div className="contact-bottom__cta__left">
            <Button text="Call Us" />
          </div>
          <div className="contact-bottom__cta__left">
            <Button text="Email Us" />
          </div>
        </div>
      </div>
      <MobileMenuBottom />
    </View>
  );
};

export default Statements;

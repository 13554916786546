import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
    FETCH_PRICELIST_REQUEST,
    FETCH_PRICELIST_FAILURE,
    FETCH_PRICELIST_RESPONSE,
    PricelistActionTypes,
    Pricelist,
} from '../types/Pricelist';

import { RootState } from '../store/configureStore';
import { getAxiosToken, getCustomerID, isAuthenticated, setAxiosToken } from '../helpers/functions';
import { getPricelist } from '../api/PricelistApi';
import history from '../helpers/history';
import c from '../helpers/constants';

export function fetchPricelistRequest(): PricelistActionTypes {
    return {
        type: FETCH_PRICELIST_REQUEST,
        payload: null,
    };
}

export function fetchPricelistResponse(
    pricelist: Pricelist[],
): PricelistActionTypes {
    return {
        type: FETCH_PRICELIST_RESPONSE,
        payload: pricelist,
    };
}

export function fetchPricelistFailure(): PricelistActionTypes {
    return {
        type: FETCH_PRICELIST_FAILURE,
        payload: null,
    };
}

export const fetchPricelist = (type: string):
    ThunkAction<void, RootState, unknown, Action<string>> =>
    async (dispatch) => {

        dispatch(fetchPricelistRequest());


        if (!isAuthenticated()) {
            history.push(c.APP_ROUTES.LOGIN);
        }

        let token = getAxiosToken();

        if (token) {
            token.cancel("Todos Component got unmounted");
            setAxiosToken();
        }

        const finalCustomerId = getCustomerID() || '';
        const asyncResp: any = await getPricelist(finalCustomerId, type);


        if (asyncResp?.status === 200) {
            let dt = asyncResp.data != null && asyncResp.data.hasOwnProperty('ds-price-list') ? asyncResp.data['ds-price-list']['tt-Spec'] : [];
            //let dt = asyncResp.data; 
            await dispatch(fetchPricelistResponse(dt));

        } else {
            await dispatch(fetchPricelistFailure());
        }
    };

import React, { useEffect } from 'react';
import '../styles/PrivacyPolicy.scss';
import View from '../components/View';
import PageHeader from '../components/PageHeader';
import carpet from './../assets/images/tile-term.png';
import { config } from './../config/config';
const PrivacyPolicy = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <View>
            <PageHeader text="Privacy Policy" imageUrl={carpet} />
            <div className="container p-5">
                <p className="font-bold pb-3">Privacy Policy</p>
                {
                    <div dangerouslySetInnerHTML={{ __html: config.privacyText }} />
                }
            </div>
        </View>
    );
};

export default PrivacyPolicy;

import {
    FETCH_PRICELIST_RESPONSE,
    FETCH_PRICELIST_REQUEST,
    FETCH_PRICELIST_FAILURE,
    PricelistState,
    PricelistActionTypes,
  } from '../types/Pricelist';
  
  const initialState: PricelistState = {
    prices: null,
    isFetchingPrices: false,
    fetchingPricesFailed: false,
  };
  
  const pricelistReducer = (
    state = initialState,
    action: PricelistActionTypes,
  ): PricelistState => {
    switch (action.type) {
      case FETCH_PRICELIST_REQUEST:
        return {
          ...state,
          prices: null,
          isFetchingPrices: true,
          fetchingPricesFailed: false,
        };
      case FETCH_PRICELIST_RESPONSE:
        return {
          ...state,
          prices: action.payload || [],
          isFetchingPrices: false,
          fetchingPricesFailed: false,
        };
      case FETCH_PRICELIST_FAILURE:
        return {
          ...state,
          prices: null,
          isFetchingPrices: false,
          fetchingPricesFailed: true,
        };
      default:
        return state;
    }
  };
  
  export default pricelistReducer;
  
import { RootState } from '../store/configureStore';

export const getOrders = (state: RootState) => state?.order?.orders;

export const getDeliveryDates = (state: RootState) => state?.order?.deliveryDates || [];
export const getDeliveryCharge = (state: RootState) => state?.order?.deliveryCharge || 0.00;

export const getReturnCode = (state: RootState) => state?.order?.return_code || '';
export const getReturnDescription = (state: RootState) => state?.order?.return_desc || '';
export const getReturnLines = (state: RootState) => state?.order?.return_lines || [];

export const getOrganisedOrders = (state: RootState) => {
  const obj = {};
  state?.order?.orders.map(o => {
    // @ts-ignore
    if (!obj[o.order_number]) {
      // @ts-ignore
      obj[o.order_number] = [];
    }
    // @ts-ignore
    obj[o.order_number].push(o);
  } );
  return obj;
};

export const getBasket = (state: RootState) => state?.order?.basket;


export const isFetchingOrders = (state: RootState) =>
  state?.order?.isFetchingOrders;

export const didFetchingOrdersFailed = (state: RootState) =>
  state?.order?.fetchingOrdersFailed;

import axios from 'axios';
import c from '../helpers/constants';
import { getApiKey, objectToQueryString } from '../helpers/functions';

export function getProduct(customerId: string, sku: string, clearance?:string ) {
  const obj = {
    customerid: encodeURIComponent(customerId),
    searchsku: sku,
  } as any;

  if( clearance ){
    obj.clearance = clearance;
  };

  const queryString = objectToQueryString(obj);
  return (
    axios({
      url: `${c.API_ENDPOINTS.PRODUCT_DETAILS}?${queryString}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'x-api-key': getApiKey()
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getProducts(customerId: string, productTypeCode?: string, searchSelection?: string, searchQuality?: string, searchColor?: string, clearance?: string ) {
  let obj : any = {
    customerid: encodeURIComponent(customerId),
    searchcolor: searchColor,
    searchselection: searchSelection ? encodeURIComponent(searchSelection) : '',
  };

  if( productTypeCode ){
    obj.producttypecode = productTypeCode; 
  }


  if( searchQuality ){
    obj.searchquality = searchQuality != null ? encodeURIComponent(searchQuality as string) : '';
  }
  
  if( clearance ){
    obj.clearance = clearance;
  }
  


  const queryString = objectToQueryString(obj);
  return (
    axios({
      url: `${c.API_ENDPOINTS.PRODUCT_DETAILS}?${queryString}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'x-api-key': getApiKey()
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        console.log(error);
        return error.response?.data;
      })
  );
}

import React from 'react';
import './PageHeader.scss';
import { config } from '../../config/config';
import CONSTANTS from '../../helpers/constants';
import { useHistory } from 'react-router-dom';

type Props = {
  text: string;
  imageUrl: string;
};

const PageHeader = (props: Props) => {

  const history = useHistory(); 
  
  const goBack = () => {
    if (document.referrer == '') {
      history.push(CONSTANTS.APP_ROUTES.DASHBOARD);
    } else { 
      history.goBack();
    }
  };

  const {
    text = '',
    imageUrl = '',
  } = props;
  return (
    <div className="page-header">
      
      <img src={config.back} className="back-icon" alt="back-icon" onClick={goBack} />        

      <div className="page-header__text"> 
        <h1>{text}</h1>
      </div>
      <div className="page-header__img">
        <img src={imageUrl} alt="Product" />
      </div>

    </div>
  );
};

export default PageHeader;

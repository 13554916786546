export const FETCH_PRODUCT_AVAILABLE_TYPES_REQUEST = 'FETCH_PRODUCT_AVAILABLE_TYPES_REQUEST';
export const FETCH_PRODUCT_AVAILABLE_TYPES_RESPONSE = 'FETCH_PRODUCT_AVAILABLE_TYPES_RESPONSE';
export const FETCH_PRODUCT_AVAILABLE_TYPES_FAILURE = 'FETCH_PRODUCT_AVAILABLE_TYPES_FAILURE';

export interface AvailableType {
  product_type_code: string;
  product_type_desc: string;
  product_Template: string;
}

export interface ProductAvailableTypeState {
  productAvailableTypes: AvailableType[],
  isFetchingProductAvailableTypes: boolean;
  fetchingProductAvailableTypesFailed: boolean;
}

interface FetchProductAvailableTypesRequestAction {
  type: typeof FETCH_PRODUCT_AVAILABLE_TYPES_REQUEST;
  payload: null;
}

interface FetchProductAvailableTypesResponseAction {
  type: typeof FETCH_PRODUCT_AVAILABLE_TYPES_RESPONSE;
  payload: AvailableType[];
}

interface FetchProductAvailableTypesFailureAction {
  type: typeof FETCH_PRODUCT_AVAILABLE_TYPES_FAILURE;
  payload: null;
}

export type ProductAvailableTypeAction =
  | FetchProductAvailableTypesRequestAction
  | FetchProductAvailableTypesResponseAction
  | FetchProductAvailableTypesFailureAction;

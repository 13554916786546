import React, { useState } from 'react';
import './SearchBar.scss';
import { BsSearch } from 'react-icons/bs';
import { MdClose } from 'react-icons/md';

type Props = {
  term?: string;
  onChange?: (arg: string) => void;
  onEnter?: () => void;
  onMouseOver?: () => void; 
  onMouseOut?: () => void; 
};
const SearchBar = (props: Props) => {
  const [value, setValue] = useState(props.term || '');
  
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    props.onChange?.(e.target.value);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      props.onEnter?.();
    }
  };

  const onClear = () => {
    props.onChange?.("");
  };

  return (
    <div className="search-bar" onMouseEnter={ props.onMouseOver } onMouseLeave={ props.onMouseOut }>
      <div className="search-bar__left">
        <BsSearch className="search-bar__left__icon" />
        <input
            onChange={onChange}
            onKeyUp={onKeyDown}
            value={props.term}
            type="text"
            autoFocus
            className="search-bar__input"
            placeholder="Search products"
        />
      </div>
      <div className="search-bar__right">
        <MdClose onClick={onClear} style={{ color: '#333333', fontSize: '28px' }} className="search-bar__right__icon" />
      </div>
    </div>
  );
};

export default SearchBar;

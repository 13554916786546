import React, { useEffect, useState } from 'react';
import './HeaderTicker.scss';
import { config } from '../../config/config';
import chevronLeft from '../../assets/svgs/chevron_left.svg';
import chevronRight from '../../assets/svgs/chevron_right.svg';
import { useHistory } from 'react-router-dom';

const HeaderTicker = () => {

  const [tickerIndex, setTickerIndex] = useState(0);

  const history = useHistory();

  const navigate = (link: string) => {
    history.push({ pathname: link });
  };

  const previousTicker = () => {
    let newIndex = tickerIndex - 1 >= 0 ? tickerIndex - 1 : config.headerBanner.length - 1;
    setTickerIndex(newIndex);
  };

  const nextTicker = () => {
    let newIndex = tickerIndex + 1 >= config.headerBanner.length ? 0 : tickerIndex + 1;
    setTickerIndex(newIndex);
  };

  return (
    <>
      <div className='header-ticker'>
        <div className='header-ticker-container'>
          <div>
            {
              config.headerBanner.length > 1 &&
              <a className='header-ticker-left' onClick={() => previousTicker()}>
                <img src={chevronLeft} alt="Left" />
              </a>
            }
            <div className='header-ticker-content'>
              <div>
                {
                   config.headerBanner.length > 0 && config.headerBanner.map((x:any, idx) => <>
                    <div className={'header-ticker-text ' + (tickerIndex == idx ? 'active' : '')} onClick={() => navigate(x.link)} dangerouslySetInnerHTML={{ __html: x.text }}></div>
                  </>)
                }
              </div>
            </div>
            {
              config.headerBanner.length > 1 &&
              <a className='header-ticker-right' onClick={() => nextTicker()}>
                <img src={chevronRight} alt="Right" />
              </a>
            }
          </div>

        </div>
      </div>
    </>
  );
};

export default HeaderTicker;

import React, { useEffect } from 'react';
import '../styles/PrivacyPolicy.scss';
import PageHeader from '../components/PageHeader';
import carpet from './../assets/images/tile-term.png';
import { config } from './../config/config';
import Footer from '../components/Footer';

const CookiePolicy = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <PageHeader text="Privacy Policy" imageUrl={carpet} />
      <div className="container p-5">
        <p className="font-bold pb-3">COOKIE POLICY AND MANAGING COOKIES</p>

        {
          <div dangerouslySetInnerHTML={{ __html: config.cookiesText }} />
        }

      </div>
      <Footer />  
    </div>
  );
};

export default CookiePolicy;

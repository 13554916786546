import React, { useEffect, useState } from 'react';
import './ProductImage.scss';
import missingSvg from '../../assets/svgs/missing.svg';
import c from '../../helpers/constants';
import loadingSvg from '../../assets/svgs/loading.svg';
import { config } from '../../config/config';

type Props = {
  imageCode?: string;
  imageAlt?: string;
  className?: string;
  skuCode?: string;
  size?: 'small' | 'medium' | 'large';
};

const ProductImage = (props: Props) => {
  const {
    imageCode = '',
    skuCode = '',
    imageAlt = '',
    className = '',
    size = 'small'
  } = props;

  const [imgSource, setImageSource] = useState(loadingSvg);

  const getImgSource = async () => {

    let finalSrc = missingSvg;
    let directory = 'thumbs/';

    switch (size) {
      case 'small':
        directory = 'thumbs/';
        break;
      case 'medium':
        directory = 'medium/';
        break;
      case 'large':
        directory = '';
        break;
    }

    //Old image solution 
    if (config.newImageSolution == false) {
      let finalSrc = missingSvg;

      if (imageCode) {
        try {
          const result = await fetch(`${config.IMAGE_BASE_OLD}/img/${imageCode.toUpperCase()}.jpg`, { method: 'HEAD' });
          if (result.ok) {
            finalSrc = `${config.IMAGE_BASE_OLD}/img/${imageCode.toUpperCase()}.jpg`;
          } else if (skuCode) {
            const result2 = await fetch(`${config.IMAGE_BASE_OLD}/img/${skuCode.toUpperCase()}.jpg`, { method: 'HEAD' });
            if (result2.ok) {
              finalSrc = `${config.IMAGE_BASE_OLD}/img/${skuCode.toUpperCase()}.jpg`;
            }
          }
        } catch (e) {
          updateSource(finalSrc);
          return;
        }
      }
      updateSource(finalSrc);
      return;
    }

    if (imageCode) {
      let resOk = false; 

      try {
        const result = await fetch(`${config.IMAGE_BASE_URL}/${directory}${imageCode.toUpperCase()}.jpg`, { method: 'OPTIONS' });
        if (result.ok) {
          resOk = true; 
          finalSrc = `${config.IMAGE_BASE_URL}/${directory}${imageCode.toUpperCase()}.jpg`;
        }
       } catch (e) {        
      }        

      try{
       if ( !resOk && skuCode) {
          const result2 = await fetch(`${c.IMAGE_BASE_URL}/${directory}${skuCode.toUpperCase()}.jpg`, { method: 'OPTIONS' });
          if (result2.ok) {
            finalSrc = `${config.IMAGE_BASE_URL}/${directory}${skuCode.toUpperCase()}.jpg`;
            resOk = true; 
          }
        }
      }catch (e) {        
      }        


      if( !resOk ){
        updateSource(finalSrc);
      }
    }

    updateSource(finalSrc);
  };

  const updateSource = ( finalSrc: string ) => {
    if( finalSrc.indexOf( imageCode.toUpperCase() ) > -1 ||  finalSrc.indexOf( skuCode.toUpperCase() ) > -1 ){
      setImageSource( finalSrc );
    }else{
      setImageSource( missingSvg );
    }   
  };

  useEffect(() => {
    getImgSource();
  }, [imageCode, skuCode]);


  return (
    <>
      <img src={imgSource} className={`${className} lw-image`} alt={imageAlt} />
    </>
  );
};

export default ProductImage;

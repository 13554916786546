export const FETCH_PRICE_REQUEST = 'FETCH_PRICE_REQUEST';
export const FETCH_PRICE_RESPONSE = 'FETCH_PRICE_RESPONSE';
export const FETCH_PRICE_FAILURE = 'FETCH_PRICE_FAILURE';

export interface QuantityEntry {
  qty_quantity: number;
  qty_price: number;
  qty_price_discount: number;
}

export interface Price {
  roll_price: number;
  roll_price_discount: number;
  cut_price: number;
  cut_price_discount: number;
  unit_price: number;
  unit_price_discount: number;
  pallet_price: number;
  pallet_price_discount: number;
  unit_price_per_sqm: number;
  pallet_price_per_sqm: number;
  carriage_charge_linit: number;
  carriage_charge_units: number;
  carriage_charge_coir: number;
  quantity_price: QuantityEntry[];
}

export interface PriceState {
  price: Price | null,
  isFetchingPrice: boolean;
  fetchingPriceFailed: boolean;
}

interface FetchPricesRequestAction {
  type: typeof FETCH_PRICE_REQUEST;
  payload: null;
}

interface FetchPricesResponseAction {
  type: typeof FETCH_PRICE_RESPONSE;
  payload: Price;
}

interface FetchPricesFailureAction {
  type: typeof FETCH_PRICE_FAILURE;
  payload: null;
}

export type PriceActionTypes =
  | FetchPricesRequestAction
  | FetchPricesResponseAction
  | FetchPricesFailureAction;

import React from 'react';
import './CardWide.scss';
import { IoIosArrowForward } from 'react-icons/io';
import { config } from '../../config/config';

type Props = {
  orderNumber: string;
  date: string;
  checkedAlt: string;
};

const CardWide = (props: Props) => {
  const {
    orderNumber = '',
    date = '',
    checkedAlt = '',
  } = props;
  return (
  <div className="card-wide">
    <div className="card-wide__content">
      <div className="card-wide__header">
        <div className="card-wide__header__check">
          <img src={config.checked} alt={checkedAlt} className="roundcheck"/>
        </div>
        <div className="card-wide__header__title">
          <h1>Order Number: {orderNumber}</h1>
        </div>
      </div>
      <div className="card-wide__footer">
        <div className="card-wide__footer__date">
          <p>Date Ordered: {date}</p>
        </div>
        <div className="card-wide__footer__status">
          <p>&nbsp;</p>
        </div>
        <IoIosArrowForward style={{ color: config.fillColour }} onClick={()=> ('')}/>
      </div>
    </div>
  </div>
  );
};

export default CardWide;

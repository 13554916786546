import { createBrowserHistory, createHashHistory } from 'history';
import c from './constants';
declare let window: any;

const history = (!window.cordova) ? createBrowserHistory() : createHashHistory();


history.listen((location) => {

   if (location.pathname == c.APP_ROUTES.CONTACT ){
      window.scrollTo(0, 0);
      return;
   }

});

   
export default history;


import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_STATEMENTS_FAILURE,
  FETCH_STATEMENTS_RESPONSE,
  FETCH_STATEMENTS_REQUEST,
  Statement,
  StatementActionTypes,
} from '../types/Statement';

import { RootState } from '../store/configureStore';
import { isAuthenticated } from '../helpers/functions';
import history from '../helpers/history';
import c from '../helpers/constants';

export function fetchStatementsRequest(): StatementActionTypes {
  return {
    type: FETCH_STATEMENTS_REQUEST,
    payload: null,
  };
}

export function fetchStatementsResponse(
  statements: Statement[],
): StatementActionTypes {
  return {
    type: FETCH_STATEMENTS_RESPONSE,
    payload: statements,
  };
}

export function fetchStatementsFailure(): StatementActionTypes {
  return {
    type: FETCH_STATEMENTS_FAILURE,
    payload: null,
  };
}

export const fetchStatements = ():
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchStatementsRequest());
    const statements = [
      {
        transaction_type: 'INV',
        transaction_date: '2020-01-17',
        own_reference: '00442990',
        customer_reference: 'RICH',
        invoice_number: 442990,
        goods_amount: 46.45,
        vat_amount: 9.29,
        total_amount: 55.74,
        balance_oustanding: 0.0,
        due_date: '2020-02-20',
        transaction_status: 'Paid',
      },
    ];
    if (!isAuthenticated()) {
      history.push(c.APP_ROUTES.LOGIN);
    }
    const asyncResp: any = { status_code: 200, data: statements }; // await login(username, password);
    if (asyncResp.status_code === 200) {
      await dispatch(fetchStatementsResponse(asyncResp.data));
    } else {
      await dispatch(fetchStatementsFailure());
    }
  };
